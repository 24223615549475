import React, { FC } from 'react';
import { Route } from "react-router-dom";
import UserFingerprintPage from "../../modules/users/user-fingerprint-admin/components/UserFingerprintPage";
import { breadcrumbs } from "../../breadcrumbs";
import AppBreadcrumb from "./AppBreadcrumb";
import { USER_DETAILS_PAGE_ROUTE, USER_FINGERPRINT_PAGE_ROUTE, USER_LIST_PAGE_ROUTE } from "../../modules/users/routes";
import AppFooter from "./AppFooter";
import Dashboard from "../../modules/dashboard/components/DashboardPage";
import UserListPage from "../../modules/users/user-admin/components/users-list/UserListPage";
import UserDetailsPage from "../../modules/users/user-admin/components/users-details/UserDetailsPage";
import { TMS_MANAGEMENT_PAGE_ROUTE, TMS_TICKETS_LIST_PAGE_ROUTE, TMS_USER_PROFILE_PAGE_ROUTE, TMS_USERS_PAGE_ROUTE } from "../../modules/tms/routes";
import TmsTicketsListPage from "../../modules/tms/tms-management/components/tms-tickets-list/TmsTicketsListPage";
import TmsUsersListPage from "../../modules/tms/tms-management/components/tms-users/TmsUsersListPage";
import TmsTicketsCreatingPage from "../../modules/tms/tms-management/components/tms-create-tickets/TmsTicketsCreatingPage";
import { TmsUserPage } from "../../modules/tms/tms-management/components/tms-user/TmsUserPage";

interface AppRouterProps {
    colorMode: string;
}

const AppRouter: FC<AppRouterProps> = ({ colorMode }) => {
    return (
        <div className="layout-main">
            <AppBreadcrumb routes={breadcrumbs}/>

            <div className="layout-content">
                <Route exact path="/">
                    <Dashboard/>
                </Route>
                <Route path={USER_FINGERPRINT_PAGE_ROUTE}>
                    <UserFingerprintPage/>
                </Route>
                <Route path={USER_LIST_PAGE_ROUTE}>
                    <UserListPage/>
                </Route>
                <Route path={`${USER_DETAILS_PAGE_ROUTE}-:id`}>
                    <UserDetailsPage/>
                </Route>
                <Route path={TMS_TICKETS_LIST_PAGE_ROUTE}>
                    <TmsTicketsListPage/>
                </Route>
                <Route path={TMS_USERS_PAGE_ROUTE}>
                    <TmsUsersListPage/>
                </Route>
                <Route path={TMS_MANAGEMENT_PAGE_ROUTE}>
                    <TmsTicketsCreatingPage/>
                </Route>
                <Route path={`${TMS_USER_PROFILE_PAGE_ROUTE}-:id`}>
                    <TmsUserPage/>
                </Route>
            </div>
            <AppFooter colorMode={colorMode}/>
        </div>
    );
};

export default AppRouter;
