import React, { FC, useState } from 'react';
import "../../users.scss";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { bodyTemplate } from "../../../../../common/components/tables";
import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyOnlineAdmin } from "../../../../../common/rights.consts";
import { getUserViolationOptions, getUserViolationReasonOptions, UserViolationModel, userViolationReason, UserViolationsApiModel, violationsByEventToArray } from "../../models/user-violations.models";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";

const INPUT_EVENT_ID = "input_eventId";
const INPUT_REASON_ID = "input_reason";
const INPUT_COMMENT = "input_comment";
const INPUT_FROM = "input_from";
const INPUT_TO = "input_to";

interface UserViolationsInfoProps {
    data?: UserViolationsApiModel | null;
    addViolation?: (name: string,
                    eventId?: number,
                    reasonId?: number,
                    comment?: string,
                    fromDate?: Date,
                    toDate?: Date) => void;
    removeViolation?: (name: string, eventId?: number) => void;
}

const eventIdTemplate = (data: any, props: any) => {
    const eventId = data[props.field];
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span>{eventId ?? "GLOBAL"}</span>
        </>
    )
};

const reasonTemplate = (data: any, props: any) => {
    const reasonId = data[props.field];
    const name = data.name;
    const text = !name ? "" : userViolationReason(name)[reasonId] ?? "";
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span>{text}</span>
        </>
    )
};

const dtTemplate = (data: UserViolationModel) => {
    const from = new Date(data.from ?? "").toLocaleDateString("ru-ru", {
        year: "numeric",
        month: "short",
        day: "numeric"
    });
    const to = new Date(data.to ?? "").toLocaleDateString("ru-ru", {
        year: "numeric",
        month: "short",
        day: "numeric"
    });
    return (
        <div className="p-d-flex p-flex-column p-text-left">
            <span>FROM: {from}</span>
            <span>TO: {to}</span>
        </div>
    )
}

const UserViolationsInfo: FC<UserViolationsInfoProps> = ({
                                                             data,
                                                             addViolation, removeViolation
                                                         }) => {
    const [ selectedViolationName, setSelectedViolationName ] = useState<string>("discussion");
    const [ eventId, setEventId ] = useState<number | undefined>(undefined);
    const [ reasonId, setReasonId ] = useState<number | undefined>(undefined);
    const [ comment, setComment ] = useState<string | undefined>(undefined);
    const [ fromDate, setFromDate ] = useState<Date | Date[] | undefined>(undefined);
    const [ toDate, setToDate ] = useState<Date | Date[] | undefined>(undefined);

    const viewData = [ ...data?.globalViolations || [], ...violationsByEventToArray(data?.violationsByEvent) ];

    const confirmRemove = (name: string, eventId?: number) => {
        confirmDialog({
            message: `Are you sure you want to remove violation?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                removeViolation && removeViolation(name, eventId);
            },
            reject: () => {
            }
        });
    };

    const confirmAdd = () => {
        confirmDialog({
            message: `Are you sure you want to add violation ${selectedViolationName}?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                const from = Array.isArray(fromDate) ? fromDate[0] : fromDate;
                const to = Array.isArray(toDate) ? toDate[0] : toDate;

                selectedViolationName && addViolation &&
                addViolation(selectedViolationName, eventId, reasonId, comment, from, to);
            },
        });
    }

    const confirmClear = () => {
        confirmDialog({
            message: `Are you sure you want to clear form?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                setSelectedViolationName("discussion");
                setEventId(undefined);
                setComment(undefined);
                setReasonId(undefined);
                setFromDate(undefined);
                setToDate(undefined);
            }
        });
    }

    const actionTemplate = (violation: UserViolationModel) => {
        if (!violation.name) {
            return null;
        }
        return (
            <RightsWrapper rights={onlyOnlineAdmin} hideWrapper>
                <div className="p-d-flex">
                    <Button
                        label="Remove violation"
                        icon="pi pi-trash"
                        className="p-button-primary p-mr-2"
                        onClick={() => confirmRemove(violation.name!, violation.eventId)}
                    />
                </div>
            </RightsWrapper>
        )
    }

    const renderViolationsEditBlock = () =>
        (
            <div className="p-ml-2 p-d-flex p-flex-column">
                <div className="p-mb-2"><b>Add new violation to user</b></div>
                <div className="p-fluid">
                    <div className="p-field">
                        <Dropdown value={selectedViolationName}
                                  options={getUserViolationOptions}
                                  onChange={e => setSelectedViolationName(e.value)}
                                  placeholder="Select a Violation Type"/>
                    </div>
                    <div className="p-inputgroup p-mb-2">
                        <label aria-required={'true'} htmlFor={INPUT_EVENT_ID} className="p-inputgroup-addon">
                            EventId (optional)
                        </label>
                        <InputNumber
                            id={INPUT_EVENT_ID}
                            useGrouping={false}
                            value={eventId} onChange={e => setEventId(e.value)}/>
                    </div>
                    <div className="p-inputgroup p-mb-2">
                        <label aria-required={'true'} htmlFor={INPUT_REASON_ID} className="p-inputgroup-addon">
                            Reason Id (optional)
                        </label>
                        <Dropdown value={reasonId}
                                  options={getUserViolationReasonOptions(selectedViolationName)}
                                  onChange={e => setReasonId(e.value)}
                                  placeholder="Select a reasons type"/>
                    </div>
                    <div className="p-inputgroup p-mb-2">
                        <label aria-required={'true'} htmlFor={INPUT_COMMENT} className="p-inputgroup-addon">
                            Comment (optional)
                        </label>
                        <InputTextarea
                            id={INPUT_COMMENT}
                            rows={2}
                            value={comment ?? ""}
                            onChange={e => setComment((e.target as any).value)}/>
                    </div>
                    <div className="p-inputgroup p-mb-2">
                        <label aria-required={'true'} htmlFor={INPUT_FROM} className="p-inputgroup-addon">
                            From date
                        </label>
                        <Calendar id={INPUT_FROM} value={fromDate} onChange={e => setFromDate(e.value)} showTime/>
                    </div>
                    <div className="p-inputgroup p-mb-2">
                        <label aria-required={'true'} htmlFor={INPUT_TO} className="p-inputgroup-addon">
                            To date
                        </label>
                        <Calendar id={INPUT_TO} value={toDate} onChange={e => setToDate(e.value)} showTime/>
                    </div>
                    <div className="p-field">
                        <Button
                            label="Add violation to user"
                            icon="pi pi-plus-circle"
                            className="p-button-primary p-mr-2"
                            onClick={confirmAdd}
                            disabled={selectedViolationName === undefined}
                        />
                    </div>
                    <div className="p-field">
                        <Button
                            label="Clear form"
                            icon="pi pi-delete-left"
                            className="p-button-secondary p-mr-2"
                            onClick={confirmClear}
                        />
                    </div>
                </div>
            </div>
        );

    return (
        <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
            <div className="card overview-box p-d-flex p-flex-column p-py-3">
                <div className="p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className="pi pi-lock"
                           style={{ fontSize: "2rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>Violations</h5>
                        <p>Info about user' violations</p>
                    </div>
                </div>
                <Divider/>
                <div className="p-d-flex">
                    <div className="p-col-8 datatable-responsive">
                        {
                            data &&
                            <DataTable
                                className="p-datatable-responsive-roles"
                                value={viewData}
                                dataKey="id"
                            >
                                <Column field="eventId" header="EventId" body={eventIdTemplate}/>
                                <Column field="name" header="Name" body={bodyTemplate}/>
                                <Column field="violationReasonNumber" header="Reason" body={reasonTemplate}/>
                                <Column field="comment" header="Comment" body={bodyTemplate}/>
                                <Column headerStyle={{ width: '12rem' }}
                                        header="Dates"
                                        bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                                        body={dtTemplate}
                                />
                                <Column headerStyle={{ width: '11rem' }}
                                        header="Actions"
                                        bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                                        body={actionTemplate}
                                />
                            </DataTable>
                        }
                    </div>
                    <div className="p-col-4">
                        <RightsWrapper rights={onlyOnlineAdmin} hideWrapper>
                            {
                                renderViolationsEditBlock()
                            }
                        </RightsWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default UserViolationsInfo;
