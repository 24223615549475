import React from "react";
import NumberFormat from 'react-number-format';

export const bodyTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            {data[props.field]}
        </>
    )
};

export const moneyTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <NumberFormat value={data[props.field]} displayType={'text'} thousandSeparator={true} prefix={'RUB '}/>
        </>
    )
};

export const dateTemplate = (data: any, props: any) => {
    const d = new Date(data[props.field])
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            {d.toLocaleDateString("ru-RU") + " at " + d.toLocaleTimeString("ru-RU")}
        </>
    );
};

export const booleanTemplate = (data: any, props: any) => {
    const status = data ? "approved" : "renewal";
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span className={`request-badge status-${status}`}>{data ? "YES" : "NO"}</span>
        </>
    )
};
