const STAGE = process.env.REACT_APP_STAGE;

export const isDevelopment = STAGE === 'dev';
export const isTesting = STAGE === 'test';
export const isProduction = STAGE === 'prod';
export const isLocal = STAGE === 'local';

export const release = `Dashboard UI v.${(process.env.REACT_APP_RELEASE ?? "0.0.1").replace("/", "_")}`;

const environments = {
    local: 'local',
    dev: 'development',
    test: 'testing',
    prod: 'production',
}

export const getEnvironment = () => {
    if (isLocal) {
        return environments.local;
    }

    return environments[STAGE] ?? environments.dev;
}
