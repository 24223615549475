import { makeAutoObservable, observable } from "mobx";
import { AuthStore } from "../../modules/auth/auth.store";
import { NotificationsStore } from "../../modules/notifications/notifications.store";
import { CoreStore } from "../../modules/core-api/core.store";

export class AppStore {
    @observable
    authStore: AuthStore;

    @observable
    notificationStore: NotificationsStore;

    @observable
    coreStore: CoreStore;

    constructor() {
        makeAutoObservable(this);

        this.authStore = new AuthStore();
        this.notificationStore = new NotificationsStore(this.authStore);
        this.coreStore = new CoreStore();
    }
}
