export enum HttpCodes {
    OK = 200,
    CREATED = 201,

    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,

    I_AM_TEAPOT = 418,

    SERVER_ERROR = 500
}

export const fetchToGet = (input: RequestInfo, token?: string): Promise<Response> => {
    if (token) {
        return fetchToWithTokenCors(input, token);
    }
    return fetchToCors(input);
};

export const fetchToGetNoCors = (input: RequestInfo, token?: string): Promise<Response> => {
    if (token) {
        return fetchToWithTokenNoCors(input, token);
    }
    return fetchNoCors(input);
};

export const fetchToPost = (input: RequestInfo, body: any, token?: string): Promise<Response> => {
    if (token) {
        return fetchToWithTokenCors(input, token, {
            method: "POST",
            body: JSON.stringify(body)
        });
    }
    return fetchToCors(input, {
        method: "POST",
        body: JSON.stringify(body)
    });
};

export const fetchToPut = (input: RequestInfo, body: any, token?: string): Promise<Response> => {
    if (token) {
        return fetchToWithTokenCors(input, token, {
            method: "PUT",
            body: JSON.stringify(body)
        });
    }
    return fetchToCors(input, {
        method: "PUT",
        body: JSON.stringify(body)
    });
};

export const fetchToDelete = (input: RequestInfo, body: any, token?: string): Promise<Response> => {
    if (token) {
        return fetchToWithTokenCors(input, token, {
            method: "DELETE",
            body: JSON.stringify(body)
        });
    }
    return fetchToCors(input, {
        method: "DELETE",
        body: JSON.stringify(body)
    });
};

const fetchToWithTokenCors = (input: RequestInfo, token: string, init?: RequestInit,) => {
    const _init: RequestInit = {
        ...init,
        headers:
            {
                "Authorization": `Bearer ${token}`
            }
    }

    return fetchToCors(input, _init);
}

const fetchToWithTokenNoCors = (input: RequestInfo, token: string, init?: RequestInit,) => {
    const _init: RequestInit = {
        ...init,
        headers:
            {
                "Authorization": `Bearer ${token}`
            }
    }

    return fetchNoCors(input, _init);
}

const fetchToCors = (input: RequestInfo, init?: RequestInit,) => fetchTo(input, "cors", init);
const fetchNoCors = (input: RequestInfo, init?: RequestInit,) => fetchTo(input, "no-cors", init);

const fetchTo = (input: RequestInfo, cors: "cors" | "no-cors", init?: RequestInit,) => {
    const _init: RequestInit = {
        ...init,
        mode: cors,
        headers:
            {
                ...init?.headers,
                "Content-Type": "application/json",
            }
    }

    return fetch(input, _init);
}

export const handleNetworkError = (response: Response) => {
    if (response.status === HttpCodes.BAD_REQUEST) {
        throw Error(`Failed fetch because the parameters are incorrect in the request`);
    } else if (response.status > HttpCodes.BAD_REQUEST) {
        throw Error(`Failed fetch with ${response.status}`);
    }

    return response;
}
