import { AuthStore } from "../../auth/auth.store";

import { fetchToGet, fetchToPost, fetchToPut, handleNetworkError } from "../../../common/lib/network";
import { createTicketsPackUrl, createTicketUrl, eventsWithTicketsUrl, getUsersListUrl, ticketsListByUserIdUrl, ticketsListUrl, updateProfileByMagicCodeUrl, updateTicketByMagicCodeUrl, validateMagicCodeUrl } from "./tms.api.routes";

import { TicketModel } from "./models/ticket.model";
import { TmsUserModel } from "./models/user.model";


export interface CreateTicketsPackData {
    tariffOptions: string[];
    permanentTariffOptions?: boolean;
    count: number;
    landingPage: string;
    comment?: string;
    additionalParams?: {
        key: string,
        value: string
    }[];
}

export interface CreateTicketData {
    tariffOptions: string[];
    permanentTariffOptions?: boolean;
    landingPage: string;
    comment?: string;
    additionalParams?: {
        key: string,
        value: string
    }[];
    profile: {
        surname?: string;
        phone?: string;
        linkedLkUserId?: number;
        name: string;
        email: string;
    }
}

export type TMSUpdateTicketType = Partial<Pick<TicketModel, 'tariffOptions' | 'landingPage' | 'comment' | 'additionalParams' | 'enabled'>>;

export interface TMSUpdateProfileType {
    name?: string;
    surname?: string;
    email?: string;
    phone?: string;
    linkedLkUserId?: number;
}

export const getTickets = (authStore: AuthStore, eventId: number, enabled?: boolean, skip: number = 0, limit: number = 100, sortBy?: 'created', sortDirection?: 'desc' | 'asc') => fetchToGet(ticketsListUrl(eventId, enabled, skip, limit, sortBy, sortDirection), authStore.token)
    .then(handleNetworkError)
    .then(response => response.json())
    .then(response => response ? ({ tickets: response.tickets as TicketModel[], total: response.total }) : ({ tickets: [], total: 0 }));

export const updateTicket = (
    authStore: AuthStore,
    magicCode: string,
    data: TMSUpdateTicketType,
) => fetchToPut(updateTicketByMagicCodeUrl(magicCode), data, authStore.token)
    .then(handleNetworkError)
    .then(response => response.json())
    .then(result => result as TicketModel);

export const getEventsWithTicketsIds = (authStore: AuthStore) => fetchToGet(eventsWithTicketsUrl(), authStore.token)
    .then(handleNetworkError)
    .then(response => response.json())
    .then(result => result?.events_ids as number[] ?? [])

export const createTicketsPack = (authStore: AuthStore, eventId: number, data: CreateTicketsPackData) => fetchToPost(createTicketsPackUrl(eventId), data, authStore.token)
    .then(handleNetworkError)
    .then(response => response.json())
    .then(result => result);

export const createTicket = (authStore: AuthStore, eventId: number, data: CreateTicketData) => fetchToPost(createTicketUrl(eventId), data, authStore.token)
    .then(handleNetworkError)
    .then(response => response.json())
    .then(result => result);

export const validateMagicCode = async (authStore: AuthStore, magicCode: string) => (
    await fetchToPost(validateMagicCodeUrl(),
        {
            grant_type: 'client_credentials',
            client_id: 'embedded',
            client_secret: magicCode,
            audience: null
        },
        authStore.token,
    )
).status === 200;

export const getUsers = (authStore: AuthStore, search: { searchUserId?: number; searchEmail?: string; searchFio?: string; } = {}, skip: number = 0, limit: number = 100) =>
    fetchToGet(getUsersListUrl(search, skip, limit), authStore.token)
        .then(handleNetworkError)
        .then(response => response.json())
        .then(result => result ? { users: result.users as TmsUserModel[], total: result.total } : { users: [], total: 0 })

export const getTicketsByUserId = (authStore: AuthStore, searchUserId: number) =>
    fetchToGet(ticketsListByUserIdUrl(searchUserId), authStore.token)
        .then(handleNetworkError)
        .then(response => response.json())
        .then(response => response ? ({ tickets: response.tickets as TicketModel[], total: response.total }) : ({ tickets: [], total: 0 }));

export const updateProfileByMagicCode = (authStore: AuthStore, magicCode: string, profile: TMSUpdateProfileType) =>
        fetchToPut(updateProfileByMagicCodeUrl(magicCode), profile, authStore.token)
            .then(handleNetworkError)
            .then(response => response.json())
            .then(result => result as TicketModel);
