import React, { FC, ReactElement } from 'react';
import classNames from "classnames";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from "primereact/progressspinner";
import { PolarChartModel } from "../charts/charts.model";

interface CardProps {
    title: string;
    isLoading?: boolean;
    icon?: string;
    counters:
        {
            counter?: string;
            title: string;
        }[];
    mainActionCallback?: () => void;
    mainActionIcon?: string;
    menu?: string[];
    className?: string;
    sizeClass?: string;
    chartLineData?: any;
    chartLineOptions?: any;
    polarPieCharts?: [ {
        data?: PolarChartModel;
        options: any;
    } ];
    component?: ReactElement;
}

const AppCard: FC<CardProps> = ({
                                    title,
                                    isLoading,
                                    icon,
                                    counters,
                                    mainActionCallback,
                                    mainActionIcon,
                                    menu,
                                    className,
                                    sizeClass,
                                    chartLineData,
                                    chartLineOptions,
                                    polarPieCharts,
                                    component
                                }) => {
    const renderHeader = () => {
        return (
            <div className="p-d-flex p-ai-center muted-text solid-surface-text-color">
                {
                    <i className={
                        classNames("pi", icon, { "pi-shopping-cart": icon === undefined })
                    }/>
                }
                <h6 className={classNames('p-m-0', 'p-pl-2')}>{title}</h6>
                {
                    mainActionCallback &&
                    <div className={classNames('p-ml-auto')}>
                        <Button
                            type="button"
                            icon={classNames("pi", mainActionIcon, { "pi-refresh": mainActionIcon === undefined })}
                            className="p-button-rounded p-button-text p-button-plain solid-surface-text-color"
                            onClick={mainActionCallback}
                        />
                    </div>
                }
                {
                    menu &&
                    <div className={classNames('p-ml-2')}>
                        <Button
                            type="button"
                            icon="pi pi-ellipsis-h"
                            className="p-button-rounded p-button-text p-button-plain"
                            onClick={mainActionCallback}
                        />
                        {/*    /!*<Menu ref={menu1} popup model={[ { label: 'Update', icon: 'pi pi-fw pi-refresh' }, { label: 'Edit', icon: 'pi pi-fw pi-pencil' } ]}></Menu>*!/*/}
                    </div>
                }
            </div>
        );
    }

    const renderPieCharts = () => {
        if (!polarPieCharts) {
            return null;
        }

        return (
            <div className="p-d-flex p-ai-end">
                {
                    polarPieCharts.map((c, i) => {
                        if (c.data) {
                            return (<Chart key={`pia_chart_index_${i}`} type="polarArea" data={c.data} options={c.options}
                                    // height="80px" width="160px"
                                />
                            )
                        }
                        return null;
                    })
                }
            </div>
        );
    }

    const renderCounters = () => {
        if (!counters) {
            return null;
        }
        return counters.map((c, i) => {
            return (
                <div key={`div_counters_${i}`}
                     className="p-d-flex p-flex-column"
                     style={{ width: '80px', marginLeft: i > 0 ? "10px" : 0 }}>
                    <span className="p-mb-1 fs-xlarge">{c.counter || "-"}</span>
                    {
                        c.title && <span className="overview-status p-p-1 teal-bgcolor fs-small">{c.title}</span>
                    }
                </div>
            );
        });
    };

    return (
        <div className={classNames("p-col-12", sizeClass ?? "p-md-6 p-lg-3")}>
            <div className={classNames("card overview-box p-d-flex p-flex-column p-pt-2", className)}>
                {renderHeader()}
                <div className="p-d-flex p-jc-between p-mt-3 p-flex-wrap">
                    {
                        isLoading && <ProgressSpinner style={{ width: '53px', height: '53px' }}/>
                    }
                    <div className="p-d-flex p-flex-wrap">
                        {
                            !isLoading && renderCounters()
                        }
                    </div>
                    {
                        chartLineData && chartLineOptions &&
                        <div className="p-d-flex p-ai-end">
                            <Chart type="line" data={chartLineData} options={chartLineOptions} height="80px" width="160px"/>
                        </div>
                    }
                    {
                        renderPieCharts()
                    }
                    {
                        component && component
                    }
                </div>
            </div>
        </div>
    );
};

export default AppCard;
