import React, { FC } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../common/hooks/useQuery";
import { authUrls } from "../../modules/auth/const";

const LoginPage: FC = () => {
    const history = useHistory();
    const params = useQuery();
    const returnUrl = params.get("returnUrl");

    const goDashboard = () => {
        history.push("/");
    }

    return (
        <div className="pages-body login-page p-d-flex p-flex-column">
            <div className="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
                <div className="topbar-left p-ml-3 p-d-flex">
                    <div className="logo">
                        <img src={"assets/images/logo.svg"} alt="" style={{ height: "5rem" }}/>
                    </div>
                </div>
                <div className="topbar-right p-mr-3 p-d-flex">
                    <Button onClick={goDashboard} type="button" label="DASHBOARD"
                            className="p-button-text p-button-plain topbar-button"/>
                </div>
            </div>

            <div className="p-as-center p-mt-auto p-mb-auto">
                <div className="pages-panel card p-d-flex p-flex-column">
                    <div className="pages-header p-px-3 p-py-1">
                        <h2>LOGIN</h2>
                    </div>

                    <h4>Welcome</h4>

                    <div className="pages-detail p-mb-6 p-px-6">Please use login from Jug Personal Area</div>

                    <div className="input-panel p-d-flex p-flex-column p-px-3">
                        <a href={authUrls.login(returnUrl)}>
                            <Button className="login-button p-mb-6 p-px-3 p-ml-2">
                                <span className="p-px-3">Login</span>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;
