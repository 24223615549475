import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { isDesktop, isIE, isRTL } from "../common/lib/browser";
import ToastHandler from "../modules/notifications/components/ToastHandler";
import AppRouter from "./components/AppRouter";
import { AppMenuWrapper } from "./components/AppMenu";
import { withProfiler } from "@sentry/react";
import AppTopBar from "./components/AppTopBar";

const App = () => {
    const [ menuMode ] = useState("static");
    const [ inlineMenuPosition ] = useState("bottom");
    const [ desktopMenuActive, setDesktopMenuActive ] = useState(true);
    const [ mobileMenuActive, setMobileMenuActive ] = useState(false);
    const [ activeTopbarItem, setActiveTopbarItem ] = useState(null);
    const [ colorMode, setColorMode ] = useState("light");
    const [ rightMenuActive ] = useState(false);
    const [ menuActive, setMenuActive ] = useState(false);
    const [ inputStyle, setInputStyle ] = useState("filled");
    const [ ripple ] = useState(true);
    const [ mobileTopbarActive, setMobileTopbarActive ] = useState(false);
    const [ menuTheme, setMenuTheme ] = useState("light");
    const [ topbarTheme, setTopbarTheme ] = useState("blue");
    // const [ theme, setTheme ] = useState("indigo");
    const [ isInputBackgroundChanged, setIsInputBackgroundChanged ] = useState(false);
    const [ inlineMenuActive, setInlineMenuActive ] = useState({});
    // const [ newThemeLoaded, setNewThemeLoaded ] = useState(false);
    // const [ searchActive, setSearchActive ] = useState(false);
    let currentInlineMenuKey = useRef(null);

    let searchClick: boolean;
    let topbarItemClick: boolean;
    let menuClick: boolean;
    let inlineMenuClick: boolean;

    useEffect(() => {
        if (menuMode === "overlay") {
            hideOverlayMenu()
        }
        if (menuMode === "static") {
            setDesktopMenuActive(true)
        }
    }, [ menuMode ])

    useEffect(() => {
        onColorModeChange(colorMode)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // const onMenuThemeChange = (theme: string) => {
    //     setMenuTheme(theme)
    // }
    //
    // const onTopbarThemeChange = (theme: string) => {
    //     setTopbarTheme(theme);
    // }

    useEffect(() => {
        const appLogoLink: any = document.getElementById("app-logo");

        if (appLogoLink) {
            if (topbarTheme === "white" || topbarTheme === "yellow" || topbarTheme === "amber" || topbarTheme === "orange" || topbarTheme === "lime") {
                appLogoLink.src = "assets/images/logo.svg";
            } else {
                appLogoLink.src = "assets/images/logo.svg";
            }
        }
    }, [ topbarTheme ])

    // const onThemeChange = (theme: string) => {
    //     setTheme(theme);
    //     const themeLink = document.getElementById("theme-css");
    //     const themeHref = "assets/theme/" + theme + "/theme-" + colorMode + ".css";
    //     replaceLink(themeLink, themeHref);
    // }

    const onColorModeChange = (mode: string) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === "dark") {
                setInputStyle("filled");
            } else {
                setInputStyle("outlined")
            }
        }

        if (mode === "dark") {
            setMenuTheme("dark");
            setTopbarTheme("dark");
        } else {
            setMenuTheme("light");
            setTopbarTheme("blue");

        }

        const layoutLink = document.getElementById("layout-css");
        const layoutHref = "assets/layout/css/layout-" + mode + ".css";
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById("theme-css");
        if (themeLink) {
            const urlTokens = themeLink.getAttribute("href")?.split("/");
            if (urlTokens) {
                urlTokens[urlTokens.length - 1] = "theme-" + mode + ".css";
                const newURL = urlTokens.join("/");

                replaceLink(themeLink, newURL, () => {
                    // setNewThemeLoaded(true);
                });
            }
        }
    }

    const replaceLink = (linkElement: any, href: string, callback?: any) => {
        if (isIE()) {
            linkElement?.setAttribute("href", href);

            if (callback) {
                callback();
            }
        } else if (linkElement) {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            if (cloneLinkElement) {
                cloneLinkElement.setAttribute("href", href);
                cloneLinkElement.setAttribute("id", id + "-clone");

                linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

                cloneLinkElement.addEventListener("load", () => {
                    linkElement.remove();
                    cloneLinkElement.setAttribute("id", id);

                    if (callback) {
                        callback();
                    }
                });
            }
        }
    }

    // const onInputStyleChange = (inputStyle: string) => {
    //     setInputStyle(inputStyle);
    // }
    //
    // const onInlineMenuPositionChange = (mode: string) => {
    //     setInlineMenuPosition(mode)
    // }
    //
    // const onMenuModeChange = (mode: string) => {
    //     setMenuMode(mode);
    // }

    const onMenuClick = () => {
        menuClick = true;
    }

    const onMenuButtonClick = (event: any) => {
        menuClick = true;

        if (isDesktop())
            setDesktopMenuActive((prevState) => !prevState);
        else
            setMobileMenuActive((prevState) => !prevState)

        event.preventDefault();

    }

    const onTopbarItemClick = (event: any) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else {
            setActiveTopbarItem(event.item)
        }

        event.originalEvent.preventDefault();
    }

    // const onSearch = (event: any) => {
    //     searchClick = true;
    //     setSearchActive(event);
    // }

    const onMenuItemClick = (event: any) => {
        if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false)
        }
    }

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    }

    // const onRightMenuButtonClick = () => {
    //     setRightMenuActive((prevState) => !prevState)
    // }

    const onMobileTopbarButtonClick = (event: any) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onDocumentClick = (event: any) => {
        if (!searchClick && event.target.localName !== "input") {
            // setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === "overlay" || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }
            hideOverlayMenu();
        }
        // @ts-ignore

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            // @ts-ignore
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    }

    const hideOverlayMenu = () => {
        setMobileMenuActive(false)
        setDesktopMenuActive(false)
    }

    const isHorizontal = () => menuMode === "horizontal";

    const isSlim = () => menuMode === "slim"

    const onInlineMenuClick = (e: any, key: any) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            // @ts-ignore
            menuKeys[currentInlineMenuKey.current] = false;
        }

        // @ts-ignore
        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    }

    const layoutContainerClassName = classNames("layout-wrapper ", "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme, {
        "layout-menu-static": menuMode === "static",
        "layout-menu-overlay": menuMode === "overlay",
        "layout-menu-slim": menuMode === 'slim',
        'layout-menu-horizontal': menuMode === 'horizontal',
        'layout-menu-active': desktopMenuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
        'layout-rtl': isRTL
    });

    return (
        <div className={layoutContainerClassName} onClick={onDocumentClick}>
            <AppTopBar horizontal={isHorizontal()}
                       activeTopBarItem={activeTopbarItem}
                       onMenuButtonClick={onMenuButtonClick}
                       onTopBarItemClick={onTopbarItemClick}
                // onRightMenuButtonClick={onRightMenuButtonClick}
                       onMobileTopBarButtonClick={onMobileTopbarButtonClick} mobileTopBarActive={mobileTopbarActive}
                // searchActive={searchActive} onSearch={onSearch}
            />
            <AppMenuWrapper
                inlineMenuActive={inlineMenuActive}
                inlineMenuPosition={inlineMenuPosition}
                isHorizontal={isHorizontal()}
                menuActive={menuActive}
                menuMode={menuMode}
                onInlineMenuClick={onInlineMenuClick}
                onMenuClick={onMenuClick}
                onMenuItemClick={onMenuItemClick}
                onRootMenuItemClick={onRootMenuItemClick}
            />
            <AppRouter colorMode={colorMode}/>
            <ToastHandler/>
            {
                mobileMenuActive && <div className="layout-mask modal-in"/>
            }
        </div>
    );
};

export default withProfiler(App);
