import Cookies from 'js-cookie'
import { parseJwt } from "./jwt";
import { countHoursBetweenDatesMoreThen } from "../../../common/lib/time";
import { isLocal } from "../../../common/lib/env";
import { getAccessTokenFixtureFromLocalStorage } from "./access-token.fixture";
import { ONLINE_ADMIN_ROLE } from "../const";

const ONLINE_SESSION_ACCESS_TOKEN_KEY = 'online.session.access_token';
const NUMBER_HOURS_BEFORE_EXPIRED = 12;

const useAdminMock = false;

export type AccessTokenValue = {
    sub: string;
    user_name: string;
    displayName: string;
    scope: string[]
    exp: number;
    lang: 'ru' | 'en';
    authorities: string[]
    jti: string;
    email: string;
    client_id: string;
    lk_user_id: number;
    lk_session_id: number;
    picture: string;
    raw: string;
    // sub: "lk-oauth2|715214"
    // user_name: "lk-demo-2@jugru.team"
    // displayName: "lk-demo-2@jugru.team"
    // scope: ["regular"]
    // exp: 1585828117
    // lang: "ru"
    // authorities: (3) ["company_admin_687478", "regular", "company_admin_687480", "online_admin"]
    // jti: "64b12183-55f1-4cc7-805d-cd468d43e023"
    // email: "lk-demo-2@jugru.team"
    // client_id: "online-frontend-backend"
    // lk_user_id: 715214
} | null;

export class AccessToken {
    public readonly value: AccessTokenValue | null;

    public get hasValue(): boolean {
        return this.value != null;
    }

    public get isExpired(): boolean {
        const exp = this.value?.exp;
        const expiredDate = new Date((exp || 0) * 1000);

        const now = new Date();

        return !countHoursBetweenDatesMoreThen(expiredDate, now, NUMBER_HOURS_BEFORE_EXPIRED);
    }

    public static readonly ONLINE_SESSION_ACCESS_TOKEN_KEY = ONLINE_SESSION_ACCESS_TOKEN_KEY;

    constructor(accessTokenString?: string) {
        this.value = AccessToken.createAccessTokenFromString(accessTokenString);
    }

    public static receive(): AccessToken {
        const accessTokenString =
            isLocal
                ? getAccessTokenFixtureFromLocalStorage(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY)
                :
                AccessToken.getAccessTokenStringFromCookie(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY);

        return new AccessToken(accessTokenString);
    }

    public static empty(): AccessToken {
        return new AccessToken();
    }

    private static createAccessTokenFromString(accessTokenString?: string): AccessTokenValue {
        let value = accessTokenString
            ? parseJwt<AccessTokenValue>(accessTokenString)
            : null;

        if (value && accessTokenString) {
            value.raw = accessTokenString;
        }

        if (value) {
            if (useAdminMock) {
                // value.displayName = 'Solyanik Alexey';
                value.authorities = [ ...value.authorities, ONLINE_ADMIN_ROLE ];
            }
        }

        return value;
    }

    private static getAccessTokenStringFromCookie = (accessTokenKey: string): string => {
        return Cookies.get(accessTokenKey) ?? '';
    };
}
