import React, { FC, useContext, useState } from 'react';
import classNames from "classnames";
import styles from "./TmsTicketsCreate.module.scss";

import { CoreApiEvent } from "../../../../core-api/models/events.models";

import { AppStoreContext } from "../../../../../application/store/context";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { TmsTicketsSmartTable } from "../TmsTicketsSmartTable/TmsTicketsSmartTable";

import { TariffOptionsMultiSelect } from "../tms-tickets-list/TariffOptionsMultiSelect";
import { AdditionalParamsEditor } from "../tms-tickets-list/AdditionalParamsEditor";

import { TicketModel, TicketModelTariffOptionsItem } from "../../models/ticket.model";

import { INPUT_COMMENT_ID, INPUT_LANDING_ID, INPUT_PERMANENT_TARIFF_OPTIONS } from "./TmsTicketsCreatePack";
import { CreateTicketData } from "../../tms.api.actions";

import { createTicketAction, getTicketsAction } from "../../tms.actions";

export const INPUT_NAME_ID = 'inputName';
export const INPUT_SURNAME_ID = 'inputSurname';
export const INPUT_EMAIL_ID = 'inputEmail';
export const INPUT_PHONE_ID = 'inputPhone';
export const INPUT_LINKED_LK_USED_ID = 'inputLinkedLkUserId';


export interface TmsTicketsCreateOneProps {
    events?: CoreApiEvent[];
}

export const TmsTicketsCreateOne: FC<TmsTicketsCreateOneProps> = (props) => {
    const {
        events,
    } = props;

    const appStore = useContext(AppStoreContext);

    const [newTicketsLoading, setNewTicketsLoading] = useState<boolean>(false);
    const [newTickets, setNewTickets] = useState<TicketModel[]>([]);
    const [selectedEventId, setSelectedEventId] = useState<number>();
    const [additionalParams, setAdditionalParams] = useState<{ key: string; value: string }[]>([]);
    const [tariffOptions, setTariffOptions] = useState<TicketModelTariffOptionsItem[]>([]);
    const [landing, setLanding] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [permanentTariffOptions, setPermanentTariffOptions] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [linkedLkUserId, setLinkedLkUserId] = useState<number>();

    const resetAll = (withEventId: boolean = true) => {
        if (withEventId) {
            setSelectedEventId(undefined);
        }

        setAdditionalParams([]);
        setTariffOptions([]);
        setLanding('');
        setComment('');

        setName('');
        setSurname('');
        setEmail('');
        setPhone('');
        setLinkedLkUserId(undefined);

        setPermanentTariffOptions(false);

        setNewTickets([]);
    }

    const createTicket = async (eventId: number | undefined, data: CreateTicketData) => {
        if (!eventId) {
            return;
        }

        setNewTicketsLoading(true);

        const creatingResult = await createTicketAction(appStore, eventId, data);

        if (creatingResult) {
            const links = creatingResult.links;

            const ticketsResult = await getTicketsAction(appStore, eventId);

            resetAll(false);

            setNewTickets(ticketsResult?.filter(ticket => links.includes(ticket.link)) ?? []);

            setNewTicketsLoading(false);
        }
    }

    return (
        <div className={classNames(styles.createOneContainer)}>
            <div className={classNames(styles.inputDataContainer)}>
                <div className={classNames(styles.commonInfo)}>
                    <Dropdown
                        className={classNames(styles.eventsDropDown, 'p-mb-5')}
                        value={selectedEventId}
                        options={
                            events?.map(event => ({
                                value: event.id,
                                label: `${event.name.en} - ${event.id}`,
                            }))
                        }
                        filter
                        filterBy={'label'}
                        onChange={(e) => {
                            setSelectedEventId(e.value);
                            setNewTickets([]);
                        }}
                        placeholder="Select event"
                    />

                    <div className={classNames(styles.inputs)}>
                        <div className="p-inputgroup p-mb-2">
                            <label aria-required={'true'} htmlFor={INPUT_LANDING_ID} className="p-inputgroup-addon">
                                Landing
                            </label>
                            <InputText
                                id={INPUT_LANDING_ID}
                                placeholder={'https://'}
                                value={landing} onChange={(e) => setLanding((e.target as any).value)} />
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_COMMENT_ID} className="p-inputgroup-addon">
                                Comment
                            </label>
                            <InputTextarea
                                id={INPUT_COMMENT_ID}
                                rows={5} value={comment} onChange={(e) => setComment((e.target as any).value)} />
                        </div>
                    </div>

                    <div className={classNames(styles.tariffOptions, 'p-mt-5')}>
                        <div className={'p-d-flex'}>
                            <label aria-required={'true'}>Tariff options:&nbsp;</label>

                            <Checkbox
                                className={'p-ml-auto p-mr-2'}
                                inputId={INPUT_PERMANENT_TARIFF_OPTIONS}
                                checked={permanentTariffOptions}
                                onChange={e => setPermanentTariffOptions(e.checked)}
                            />
                            <label
                                style={{ cursor: 'pointer' }}
                                htmlFor={INPUT_PERMANENT_TARIFF_OPTIONS}>
                                Permanent (editing forbidden)
                            </label>
                        </div>
                        <TariffOptionsMultiSelect
                            defaultValue={tariffOptions ?? []}
                            optionLabel={'name'}
                            optionValue={'value'}
                            onChange={newValue => setTariffOptions(newValue)}
                            changeImmediately={true}
                        />
                    </div>

                    <div className={classNames(styles.additionalParams, 'p-mt-5')}>
                        Additional params:&nbsp;
                        <AdditionalParamsEditor
                            additionalParams={additionalParams}
                            onChange={newParams => setAdditionalParams(newParams)}
                            changeImmediately={true}
                        />
                    </div>
                </div>

                <div className={classNames(styles.profileInfo)}>
                    <div className={classNames(styles.inputs)}>

                        <div className="p-inputgroup p-mb-2">
                            <label aria-required={'true'} htmlFor={INPUT_NAME_ID} className="p-inputgroup-addon">
                                Name
                            </label>
                            <InputText
                                id={INPUT_NAME_ID}
                                value={name} onChange={(e) => setName((e.target as any).value)} />
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_SURNAME_ID} className="p-inputgroup-addon">
                                Surname
                            </label>
                            <InputText
                                id={INPUT_SURNAME_ID}
                                value={surname} onChange={(e) => setSurname((e.target as any).value)} />
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label aria-required={'true'} htmlFor={INPUT_EMAIL_ID} className="p-inputgroup-addon">
                                Email
                            </label>
                            <InputText
                                id={INPUT_EMAIL_ID}
                                value={email} onChange={(e) => setEmail((e.target as any).value)} />
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_PHONE_ID} className="p-inputgroup-addon">
                                Phone
                            </label>
                            <InputText
                                id={INPUT_PHONE_ID}
                                type={'phone'}
                                value={phone} onChange={(e) => setPhone((e.target as any).value)} />
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_LINKED_LK_USED_ID} className="p-inputgroup-addon">
                                LinkedLkUserId
                            </label>
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                inputStyle={{
                                    borderTopLeftRadius: 0,
                                }}
                                useGrouping={false}
                                inputId={INPUT_LINKED_LK_USED_ID}
                                value={linkedLkUserId} onValueChange={(e) => setLinkedLkUserId(e.value)} />
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div className={'p-mt-5 p-d-flex'}>
                <Button
                    disabled={
                        landing === '' ||
                        name === '' ||
                        email === ''||
                        !selectedEventId ||
                        !tariffOptions?.length
                    }
                    label={'Create ticket'}
                    icon={'pi pi-check'}
                    className={classNames('p-button-success')}

                    onClick={async () => {
                        const body = {
                            tariffOptions,
                            permanentTariffOptions,
                            landingPage: landing,
                            comment,
                            additionalParams,
                            profile: {
                                surname,
                                phone,
                                linkedLkUserId,
                                name,
                                email,
                            }
                        };

                        await createTicket(selectedEventId, body);
                    }}
                />

                <Button
                    label={'Reset all'}
                    className={'p-button-secondary p-ml-2'}
                    onClick={() => resetAll()}
                />
            </div>

            {
                (newTickets.length > 0 || newTicketsLoading) &&
                <>
                    {
                        newTicketsLoading
                            ? <ProgressSpinner />
                            : (
                                <div>
                                    <TmsTicketsSmartTable
                                        defaultTickets={newTickets}
                                        defaultPage={0}
                                        defaultTotalTicketsCount={newTickets.length}
                                        useOnePage={true}
                                    />
                                </div>
                            )
                    }
                </>
            }
        </div>
    )
}
