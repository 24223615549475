import { ContactEntity } from "./user-details.models";

export interface UserModel {
    id: number,
    lang: "ru" | "en",
    status: number,
    comment: string,
    created_date: Date,
    // modified_date: Date,
    created_automatically: boolean,
    contacts: string,
    communication_email: string,
    contacts_details?: ContactEntity[];
    // registration_type: number
}

export interface UsersStatsModel {
    user_count: number;
    user_registered_count: number;
    user_active_count: number;
    user_deactived_count: number;
    user_registration_count: number;
    user_profiles_count: number;
    user_pictures_count: number;
    user_google_count: number;
    user_yandex_count: number;
}

export const UserStatsModelsStatusFields = [ "user_registered_count", "user_active_count", "user_deactived_count", "user_registration_count" ];

export const UserStatsModelsSocialFields = [ "user_google_count", "user_yandex_count" ];

export type UserStatuses = "REGISTERED" | "ACTIVE" | "DEACTIVATED" | "REGISTRATION" | "NONAME";

export const userStatusToString = (status?: number): UserStatuses => {
    if (status === 0) return "REGISTERED";
    if (status === 1) return "ACTIVE";
    if (status === 2) return "DEACTIVATED";
    if (status === 3) return "REGISTRATION";

    return "NONAME";
}

export const USER_LIST_LIMIT = 10;
