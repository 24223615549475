import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './TmsUserPage.module.scss';
import classNames from 'classnames';

import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyForAdmin } from "../../../../../common/rights.consts";

import AppPage from "../../../../../application/components/AppPage";
import NotFoundPage from "../../../../../application/pages/NotFoundPage";

import { AppStoreContext } from "../../../../../application/store/context";

import { TmsUserModel } from "../../models/user.model";
import { TicketModel } from "../../models/ticket.model";

import { useParams } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { TmsTicketsSmartTable } from "../TmsTicketsSmartTable/TmsTicketsSmartTable";

import { INPUT_EMAIL_ID, INPUT_LINKED_LK_USED_ID, INPUT_NAME_ID, INPUT_PHONE_ID, INPUT_SURNAME_ID } from "../tms-create-tickets/TmsTicketsCreateOne";

import { getTicketsByUserIdAction, getUsersAction, updateProfileAction } from "../../tms.actions";

const isNameAndEmailIsRequired = (
    isAnonymous: boolean | undefined,
    name: string | undefined,
    surname: string | undefined,
    email: string | undefined,
    phone: string | undefined,
    linkedLkUserId: number | undefined
): boolean => {
    if (!isAnonymous) {
        return true;
    }

    return (
        isAnonymous &&
        (
            (name && name !== '') ||
            (surname && surname !== '') ||
            (email && email !== '') ||
            (phone && phone !== '') ||
            (linkedLkUserId !== undefined && linkedLkUserId !== null)
        )
    );
}

export interface TmsUserPageProps {
}

export const TmsUserPage: FC<TmsUserPageProps> = () => {
    const appStore = useContext(AppStoreContext);

    const { id } = useParams<{ id: string }>();

    const [ userLoading, setUserLoading ] = useState<boolean>(true);
    const [ ticketsLoading, setTicketsLoading ] = useState<boolean>(true);
    const [ userTickets, setUserTickets ] = useState<TicketModel[]>([]);
    const [ notFound, setNotFound ] = useState<boolean>(false);
    const [ isAnonymous, setIsAnonymous ] = useState<boolean>(true);

    const [ name, setName ] = useState<string>('');
    const [ surname, setSurname ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ phone, setPhone ] = useState<string>('');
    const [ linkedLkUserId, setLinkedLkUserId ] = useState<number>();

    useEffect(() => {
        if (!appStore || !id) {
            return;
        }

        getUsersAction(
            appStore,
            undefined,
            undefined,
            { searchUserId: +id },
        )
            .then(result => {
                if (result && result.length === 1) {
                    const user = result[0] as TmsUserModel;

                    setName(user.name ?? '');
                    setSurname(user.surname ?? '');
                    setEmail(user.email ?? '');
                    setPhone(user.phone ?? '');
                    setLinkedLkUserId(user.linkedLkUserId);

                    setIsAnonymous(!!user.isAnonymous);

                    getTicketsByUserIdAction(appStore, +id)
                        .then(tickets => {
                            setUserTickets(tickets ?? []);
                            setTicketsLoading(false);
                        })
                } else {
                    setNotFound(true);
                }

                setUserLoading(false);
            });
    }, [ appStore, id ]);

    if (notFound) {
        return <NotFoundPage/>;
    }

    if (userLoading || ticketsLoading) {
        return (
            <div className="p-col-12 p-md-6 p-lg-6 p-xl-4">
                <ProgressSpinner/>
            </div>
        )
    }

    return (
        <RightsWrapper rights={onlyForAdmin}>
            <AppPage
                isCard
                header="TMS user"
                subheader="You can view and edit user, also you can manage user tickets"
                icon="pi-user-edit"
                // headerComponent={}
            >
                <div className={classNames(styles.profileInfo)}>
                    <div className={classNames(styles.inputs)}>

                        <div className="p-inputgroup p-mb-2">
                            <label
                                aria-required={isNameAndEmailIsRequired(isAnonymous, name, surname, email, phone, linkedLkUserId)}
                                htmlFor={INPUT_NAME_ID} className="p-inputgroup-addon"
                            >
                                Name
                            </label>
                            <InputText
                                id={INPUT_NAME_ID}
                                value={name} onChange={(e) => setName((e.target as any).value)}/>
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_SURNAME_ID} className="p-inputgroup-addon">
                                Surname
                            </label>
                            <InputText
                                id={INPUT_SURNAME_ID}
                                value={surname} onChange={(e) => setSurname((e.target as any).value)}/>
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label
                                aria-required={isNameAndEmailIsRequired(isAnonymous, name, surname, email, phone, linkedLkUserId)}
                                htmlFor={INPUT_EMAIL_ID} className="p-inputgroup-addon">
                                Email
                            </label>
                            <InputText
                                id={INPUT_EMAIL_ID}
                                value={email} onChange={(e) => setEmail((e.target as any).value)}/>
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_PHONE_ID} className="p-inputgroup-addon">
                                Phone
                            </label>
                            <InputText
                                id={INPUT_PHONE_ID}
                                type={'phone'}
                                value={phone} onChange={(e) => setPhone((e.target as any).value)}/>
                        </div>

                        <div className="p-inputgroup p-mb-2">
                            <label htmlFor={INPUT_LINKED_LK_USED_ID} className="p-inputgroup-addon">
                                LinkedLkUserId
                            </label>
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                inputStyle={{
                                    borderTopLeftRadius: 0,
                                }}
                                useGrouping={false}
                                inputId={INPUT_LINKED_LK_USED_ID}
                                value={linkedLkUserId} onValueChange={(e) => setLinkedLkUserId(e.value)}/>
                            <Button icon="pi pi-times" className="p-button-danger" onClick={() => setLinkedLkUserId(undefined)}/>
                        </div>
                    </div>

                    <Button
                        disabled={
                            (
                                (
                                    (!name || name === '') ||
                                    (!email || email === '')
                                ) && isNameAndEmailIsRequired(isAnonymous, name, surname, email, phone, linkedLkUserId)
                            ) ||
                            !userTickets ||
                            userTickets.length === 0
                        }
                        label="Update"
                        icon="pi pi-check"
                        className="p-button-primary p-mt-2 p-mb-2"
                        onClick={async () => {
                            const someMagicCode = userTickets[0].magicCode;

                            const body = {
                                name,
                                surname,
                                email,
                                phone,
                                linkedLkUserId,
                            }

                            await updateProfileAction(appStore, someMagicCode, body);

                            if (isNameAndEmailIsRequired(isAnonymous, name, surname, email, phone, linkedLkUserId)) {
                                setIsAnonymous(false);
                            }

                            appStore.notificationStore.addInfoNote('User profile updated successfully', '', 1);
                        }}
                    />
                </div>

                {
                    !ticketsLoading &&
                    <TmsTicketsSmartTable
                        defaultTickets={userTickets}
                        defaultPage={0}
                        defaultTotalTicketsCount={userTickets.length}
                        useOnePage={true}
                        loading={ticketsLoading}
                    />
                }
            </AppPage>
        </RightsWrapper>
    )
}
