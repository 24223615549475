import { action, computed, makeAutoObservable, observable } from "mobx";
import { CoreApiEvent } from "./models/events.models";
import { countMinutesBetweenDates } from "../../common/lib/time";

export class CoreStore {
    @observable
    eventLists: CoreApiEvent[] = [];

    @observable
    updated?: Date;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    public setEventsList(events: CoreApiEvent[]) {
        this.eventLists = events;
        this.updated = new Date();
    }

    @computed
    updatedAgeMin(): number | null {
        if (!this.updated) return null;
        return countMinutesBetweenDates(new Date(), this.updated);
    }
}
