import { AppStoreContext } from "../../../../../application/store/context";
import { userRights } from "../../../rights";
import { UsersStatsModel } from "../../models/user.models";
import { getUsersStatsAction } from "../../users.actions";
import { FC, useContext, useEffect, useState } from "react";
import RightsWrapper from "../../../../auth/components/RightsWrapper";
import AppCard from "../../../../../common/components/cards/AppCard";
import { observer } from "mobx-react-lite";
import { rightsWrapperFunction } from "../../../../auth/components/rightsWrapperFunction";

interface UsersStatsCardProps {
    title?: string;
    mainActionCallback?: () => void;
    mainActionIcon?: string;
    sizeClass?: string;
}

// const pieOptionsDefault = {
//     legend: {
//         display: false,
//         labels: {
//             fontColor: '#ffffff'
//         }
//     },
//     scale: {
//         gridLines: {
//             color: '#ebedef'
//         }
//     }
// };

const UsersStatsCard: FC<UsersStatsCardProps> = ({
                                                     mainActionCallback,
                                                     mainActionIcon,
                                                     title,
                                                     sizeClass,
                                                 }) => {
    const appStore = useContext(AppStoreContext);

    const [ stat, setStat ] = useState<UsersStatsModel | undefined>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    // const [ socialChartData, setSocialChartData ] = useState<PolarChartModel>();

    const refreshData = () => {
        setStat(undefined);
        setIsLoading(true);
        getUsersStatsAction(appStore, undefined)
            .then(response => {
                if (response) {
                    setTimeout(() => {
                        setStat(response);
                        // setSocialChartData(getPieChartDataFromStats(UserStatsModelsSocialFields, response));
                    }, 300);
                }
            })
            .finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 300);
            });
    };

    useEffect(() => {
            if (rightsWrapperFunction(appStore.authStore, userRights, {}) === null) return;

            if (stat === undefined) {
                refreshData();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ appStore ]
    );

    return (
        <RightsWrapper hideWrapper rights={userRights}>
            <AppCard title={title ?? "Users stats"}
                     isLoading={isLoading}
                     icon="pi-users"
                     counters={
                         [
                             {
                                 counter: stat?.user_active_count.toString(),
                                 title: "Active users"
                             },
                             {
                                 counter: stat?.user_profiles_count.toString(),
                                 title: "Profiles users"
                             },
                             {
                                 counter: stat?.user_deactived_count.toString(),
                                 title: "Deactived users"
                             },
                             {
                                 counter: ((stat?.user_count || 0) - (stat?.user_profiles_count || 0)).toString(),
                                 title: "No profiles users"
                             },
                             {
                                 counter: stat?.user_pictures_count.toString(),
                                 title: "Users with picture"
                             }
                         ]
                     }
                     mainActionCallback={mainActionCallback ?? refreshData}
                     mainActionIcon={mainActionIcon ?? "pi-replay"}
                     className="deeppurple-bgcolor solid-surface-text-color"
                     sizeClass={sizeClass ?? "p-md-6 p-lg-6"}
            />
        </RightsWrapper>
    );
}

export default observer(UsersStatsCard);
