/* eslint-disable react-hooks/exhaustive-deps */
import React, { KeyboardEvent, useContext, useEffect, useState } from "react";
import { AppStoreContext } from "../../../../../application/store/context";
import { userRights } from "../../../rights";
import RightsWrapper from "../../../../auth/components/RightsWrapper";
import AppPage from "../../../../../application/components/AppPage";
import { USER_LIST_LIMIT, UserModel, UserStatuses } from "../../models/user.models";
import { getUserListAction } from "../../users.actions";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import UsersTable from "./UsersTable";
import UsersStatsCard from "./UsersStatsCard";
import { useQuery } from "../../../../../common/hooks/useQuery";

const allUsersStatusesArray: StatusesSelect[] = [
    { name: "REGISTERED", code: "0" },
    { name: "ACTIVE", code: "01" },
    { name: "DEACTIVATED", code: "02" },
    { name: "REGISTRATION", code: "03" },
];

const initUserStatusesArray: StatusesSelect[] = [
    { name: "ACTIVE", code: "01" },
];

interface StatusesSelect {
    name: UserStatuses;
    code: string;
}

const UserListPage = () => {
    const query = useQuery();
    const searchString = query.get("s") ?? query.get("search") ?? "";

    const appStore = useContext(AppStoreContext);
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ globalFilter, setGlobalFilter ] = useState<string>(searchString);
    const [ statusSelectValue, setStatusSelectValue ] = useState<StatusesSelect[]>(initUserStatusesArray);
    const [ page, setPage ] = useState<number>(0);
    const [ firstStart, setFirstStart ] = useState<boolean>(true);

    const [ filteredUserList, setFilteredUserList ] = useState<UserModel[]>([]);

    // events
    const handleStatusSelectChange = (e: any) => {
        setStatusSelectValue(e.value);
    }

    const searchClickHandler = () => {
        setPage(0);
        updateData(0).then();
    };

    const clearClickHandler = () => {
        setGlobalFilter("");
        setPage(0);
        setStatusSelectValue(initUserStatusesArray);
    };

    // actions
    const updateData = (p?: number) => {
        if (globalFilter) {
            return refreshRequest(globalFilter, (p ?? page) * USER_LIST_LIMIT);
        } else {
            return refreshRequest(undefined, (p ?? page) * USER_LIST_LIMIT);
        }
    };

    const refreshRequest = (globalFilter?: string, offset: number = 0, limit: number = USER_LIST_LIMIT) => {
        const statuses = (statusSelectValue).map(s => +s.code);

        return getUserListAction(appStore, setLoading, statuses, globalFilter, offset, limit)
            .then(response => {
                if (response) {
                    setFilteredUserList(response)
                }
            });
    };

    // effects
    useEffect(() => {
        if (firstStart) {
            refreshRequest()?.then(() => setFirstStart(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // onChange selector
    useEffect(() => {
        if (firstStart) {
            return;
        }
        updateData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ statusSelectValue ]);

    return (
        <RightsWrapper rights={userRights}>
            <AppPage
                isCard
                header="User list"
                subheader="You can view, find users there."
                icon="pi-sort-alt"
                headerComponent={
                    <UsersStatsCard/>
                }
            >
                <div className="p-col-12 p-md-6 p-lg-6 p-xl-4">
                    <span className="p-input-icon-left width-100">
                        <i className="pi pi-search"/>
                        <InputText
                            type="text"
                            className="width-100"
                            value={globalFilter}
                            onChange={(event: any) => setGlobalFilter(event.target.value)}
                            placeholder="email lkid"
                            onKeyDown={(e: KeyboardEvent) => {
                                if (e.key === "Enter") {
                                    searchClickHandler()
                                }
                            }}
                        />
                    </span>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4 p-xl-2">
                    <Button icon="pi pi-search" className="p-mt-1" label="Search" onClick={searchClickHandler}/>
                    <Button icon="pi pi-trash" className="p-button-warning p-ml-2" label="Clear" onClick={clearClickHandler}/>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-6">
                    <SelectButton
                        style={{ float: "right" }}
                        value={statusSelectValue}
                        onChange={handleStatusSelectChange}
                        options={allUsersStatusesArray}
                        optionLabel="name"
                        multiple
                    />
                </div>
                <div className="p-col-12">
                    <UsersTable
                        users={filteredUserList}
                        page={page}
                        setPage={(p) => {
                            setPage(p);
                            updateData(p).then();
                        }}
                        loading={loading}
                    />
                </div>
            </AppPage>
        </RightsWrapper>
    );
};

export default UserListPage;
