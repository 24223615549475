import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import styles from './TmsTicketsList.module.scss';

import { observer } from "mobx-react-lite";

import { AppStoreContext } from "../../../../../application/store/context";

import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyForAdmin } from "../../../../../common/rights.consts";

import AppPage from "../../../../../application/components/AppPage";

import { getEventsAction } from "../../../../core-api/core.actions";
import { getEventsWithTicketsIdsAction } from "../../tms.actions";

import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from "primereact/selectbutton";
import { TmsTicketsSmartTable } from "../TmsTicketsSmartTable/TmsTicketsSmartTable";


const TmsTicketsListPage = () => {
    const appStore = useContext(AppStoreContext);

    const [page, setPage] = useState<number>(0);
    const [eventsLoading, setEventsLoading] = useState<boolean>(true);
    const [selectedEventId, setSelectedEventId] = useState<number>();
    const [eventsWithTicketsIds, setEventsWithTicketsIds] = useState<number[]>([]);
    const [filterEnabledDisabled, setFilterEnabledDisabled] = useState<'enabled' | 'disabled' | 'all'>('all');

    useEffect(() => {
        if (!appStore) {
            return;
        }

        getEventsWithTicketsIdsAction(appStore)
            .then(eventsIds => eventsIds && setEventsWithTicketsIds(eventsIds))
            .then(() => getEventsAction(appStore, setEventsLoading));
    }, [appStore]);

    return (
        <RightsWrapper rights={onlyForAdmin}>
            <AppPage
                isCard
                header="TMS tickets per event"
                subheader="You can view, edit and validate tickets."
                icon="pi-ticket"
                // headerComponent={}
            >
                {
                    eventsLoading &&
                    <div className={classNames(styles.spinnerContainer)}>
                        <ProgressSpinner />
                    </div>
                }

                {
                    !eventsLoading &&
                    <div
                        style={{ width: '100%' }}
                    >
                        <div
                            className={'p-d-flex p-flex-row'}
                            style={{ width: '100%' }}
                        >
                            <Dropdown
                                className={classNames(styles.tmsEventsDropDown)}
                                value={selectedEventId}
                                options={
                                    appStore?.coreStore?.eventLists
                                        ?.filter(event => eventsWithTicketsIds.includes(event.id))
                                        .map(event => ({
                                            value: event.id,
                                            label: `${event.name.en} - ${event.id}`,
                                        }))
                                }
                                filter
                                filterBy={'label'}
                                onChange={(e) => setSelectedEventId(e.value)}
                                placeholder="Select event"
                            />

                            <div
                                className={'p-ml-auto p-inputgroup'}
                                style={{ width: 'unset' }}
                            >
                                <SelectButton
                                    disabled={!selectedEventId}
                                    optionLabel={'label'}
                                    value={filterEnabledDisabled}
                                    options={[
                                        { label: 'All tickets', value: 'all' },
                                        { label: 'Enabled tickets', value: 'enabled' },
                                        { label: 'Disabled tickets', value: 'disabled' },
                                    ]}
                                    onChange={(e) => {
                                        setPage(0);
                                        setFilterEnabledDisabled(e.value);
                                    }}
                                />
                            </div>
                        </div>

                        {
                            selectedEventId === undefined
                                ? (
                                    <div className={classNames(styles.unselectedEventContainer)}>
                                        <i className="pi pi-exclamation-circle" />
                                        Please, select event
                                    </div>
                                )
                                : (
                                    <TmsTicketsSmartTable
                                        eventId={selectedEventId}
                                        defaultPage={page}
                                        filterEnabledDisabled={filterEnabledDisabled}
                                    />
                                )
                        }
                    </div>
                }
            </AppPage>
        </RightsWrapper>
    );
};

export default observer(TmsTicketsListPage);
