export const TICKETS_LIST_LIMIT = 10;

export type TicketModelTariffOptionsItem = 'LIVE_BROADCAST' | 'VIDEO_ARCHIVE' | 'DISCUSSION_ZONE' | 'GAME';

export interface TicketModel {
    additionalParams?: { key: string; value: string; }[];
    comment?: string;
    created: Date;
    creator: string;
    editable?: boolean;
    enabled: boolean;
    eventId: number;
    landingPage: string;
    lightProfile?: {
        created: string;
        creator: string;
        email: string;
        isAnonymous: boolean;
        linkedLkUserId?: number;
        name?: string;
        phone?: string;
        surname?: string;
    };
    link: string;
    magicCode: string;
    profileId: number;
    tariffOptions?: TicketModelTariffOptionsItem[];
    updated?: string;
    updater?: string;
}
