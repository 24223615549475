import React, { FC, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { USER_LIST_LIMIT, UserModel, userStatusToString } from "../../models/user.models";
import { bodyTemplate, booleanTemplate, dateTemplate } from "../../../../../common/components/tables";
import { info } from "../../../../../common/lib/logging";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { buildUserRouter } from "../../../routes";
import { ContactEntity } from "../../models/user-details.models";
import classNames from "classnames";
import { mask } from "../users-details/UserContactsInfo";
import { AppStoreContext } from "../../../../../application/store/context";

const statusBodyTemplate = (data: any, props: any) => {
    const status = userStatusToString(data[props.field]);
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span className={`user-status-badge status-${status.toLowerCase()}`}>{status}</span>
        </>
    )
};

interface UsersTableProps {
    users: UserModel[];
    page: number;
    setPage: (p: number) => void;
    loading: boolean;
}

const tableHeader = (
    <div className="table-header">
        List of users
        <div className="p-d-flex">
        </div>
    </div>
);

const UsersTable: FC<UsersTableProps> = ({
                                             users,
                                             loading,
                                             page,
                                             setPage
                                         }) => {
    const authStore = useContext(AppStoreContext).authStore;

    const onPage = (e: { first: number, rows: number, page: number, pageCount: number }) => {
        info("onPage", e);
        setPage(e.page);
    };

    const contactsTemplate = (data: any, props: any) => {
        const contacts = data[props.field] as ContactEntity[];

        return (
            <div className="p-d-flex p-flex-column">
                {contacts.map((item: ContactEntity) => {
                    const value = (item.used_by_default && item.type === 0) || authStore.isGlobalAdmin() ? item.value :
                        mask(item.value);

                    const color = item.verification_status === 2 ? 'green' : 'red';
                    const icon = item.verification_status === 2 ? 'pi-check' : 'pi-times';
                    const iconType = item.type === 0 ? 'pi-envelope' : 'pi-phone';

                    return (
                        <div key={item.id} className="p-d-flex p-m-1">
                            <i className={classNames("pi p-mr-2", iconType)}></i>
                            <span className={classNames({ "p-text-bold": item.used_by_default })}>{value}</span>
                            <i style={{ color }}
                               className={classNames("pi p-ml-2", icon)}></i>
                        </div>
                    );
                })}
            </div>
        );
    };

    const actionTemplate = (data: UserModel) => {
        return (
            <div className="p-d-flex">
                <Link to={buildUserRouter(data.id)}>
                    <Button
                        label="Open profile"
                        icon="pi pi-info"
                        className="p-button-primary p-mr-2"
                    />
                </Link>
            </div>
        );
    };

    return (
        <div className="table-demo">
            <DataTable
                className="p-datatable-users p-datatable-gridlines p-datatable-striped"
                value={users}
                paginator
                lazy
                onPage={onPage}
                first={page * USER_LIST_LIMIT}
                rows={USER_LIST_LIMIT}
                totalRecords={users.length >= USER_LIST_LIMIT ? page * USER_LIST_LIMIT + USER_LIST_LIMIT + 1 : page * USER_LIST_LIMIT + USER_LIST_LIMIT}
                dataKey="id"
                rowHover
                emptyMessage="No users found"
                loading={loading}
                header={tableHeader}
            >
                <Column field="id" header="Id" body={bodyTemplate} headerStyle={{ width: '6em' }}/>
                <Column field="contacts_details" header="Contacts" sortable body={contactsTemplate}/>
                <Column field="created_automatically" header="Created Automatically" body={booleanTemplate} headerStyle={{ width: '10em' }}/>
                <Column field="created_date" header="Created Date" sortable body={dateTemplate}
                        headerStyle={{ width: '15rem' }}
                />
                <Column field="status" header="Status" sortable
                        headerStyle={{ width: '9rem' }}
                        body={statusBodyTemplate}
                />
                <Column headerStyle={{ width: '11rem' }}
                        header="Actions"
                        bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                        body={actionTemplate}
                />
            </DataTable>
        </div>
    );
};

export default UsersTable;
