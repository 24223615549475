import { Option } from "../users.actions";

export interface UserRolesEntity {
    id: number;
    name: string;
    description: string;
}

export const StandardRoles: string[] = [ "regular", "admin", "online_admin" ];

export const getUserRolesOptions = (roles: UserRolesEntity[]): Option[] =>
    roles.filter(r =>
        r.name !== "regular"
        && r.name !== "external"
        && r.name !== "srm_user"
    ).map(r => ({
        label: r.name.toUpperCase(),
        value: r.name
    }));
