import { Option } from "../users.actions";

export interface UserViolationModel {
    name?: UserViolationName;
    from?: string;
    to?: string;
    violationReasonNumber?: number;
    comment?: string;
    auditAppendedAt?: Date;
    eventId?: number;
}

export type UserViolationName = "chat" | "tickets" | "discussion" | "reactions" | string; // "chats", "tg" или что-угодно, на момент написания этой спеки активно только "tickets"

export const getUserViolationOptions: Option[] =
    [ "chat", "tickets", "discussion", "reactions" ].map(r => ({
        label: r.toUpperCase(),
        value: r
    }));

export const userViolationReason = (name: UserViolationName) => name === "discussion" ?
    [ "нарушение норм поведения (0)" ] :
    [ "personal билет был оплачен компанией (0)",
        "предоставление доступа к трансляции третьим лицам (1)",
        "нарушение норм поведения (2)" ];

export const getUserViolationReasonOptions = (name: UserViolationName): Option[] =>
    userViolationReason(name).map((r, i) => ({
        label: r,
        value: i.toString()
    }))

export interface UserViolationPutModel {
    violation: UserViolationName;
    sub: string,
    action: "add" | "remove";
    eventId: number | null, // айдишка конкретной конфы или null для глобального ограничения по всем конфам
    violationReasonNumber: number | null, // или null, необязательный номер типовой причины наложения ограничения
    comment: string | null, // или null, комментарий, видимый пользователю, в дополнение к типовой причине
    from: string | null, // для action = add может быть конкретная дата в формате "2020-07-02T07:46:28.895Z", с которой должен заработать запрет, по дефолту устанавливается в текущее время и дату
    to: string | null // для action = add может быть конкретная дата в формате "2020-07-02T07:46:28.895Z", до которой должен работать запрет, по дефолту устанавливается на 100 лет вперед (=навсегда)
}

export type ViolationsByEvent = {
    [eventName: string]: UserViolationModel[];
};

export interface UserViolationsApiModel {
    globalViolations?: UserViolationModel[];
    violationsByEvent?: ViolationsByEvent;
}

export const violationsByEventToArray = (violationsByEvent?: ViolationsByEvent): UserViolationModel[] => {
    if (!violationsByEvent) {
        return [];
    }
    const result: UserViolationModel[] = [];
    for (const key in violationsByEvent) {
        const values = violationsByEvent[key];
        if (values) {
            for (const value of values) {
                result.push({
                    ...value,
                    eventId: +key
                });
            }
        }
    }

    return result;
}
