import { fetchToGet, fetchToGetNoCors, HttpCodes } from "../../common/lib/network";
import { authUrls } from "./const";

export const logout = (): Promise<Response> => {
    return fetchToGet(authUrls.logout);
}

export const verify = (): Promise<boolean> => {
    return fetchToGet(authUrls.verify)
        .then(response => response.status !== HttpCodes.UNAUTHORIZED);
}

export const silentLogin = (): Promise<Response> => {
    return fetchToGetNoCors(authUrls.silentLogin);
}
