import React, { FC, useEffect, useState } from 'react';

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";


export interface AdditionalParamsEditorProps {
    additionalParams?: { key: string; value: string; }[];

    changeImmediately?: boolean;

    onChange?: (newParams: { key: string; value: string; }[]) => void;
}

export const AdditionalParamsEditor: FC<AdditionalParamsEditorProps> = (props) => {
    const {
        additionalParams = [],

        changeImmediately,

        onChange,
    } = props;

    const [params, setParams] = useState<{ id: number; key: string; value: string; }[]>([]);
    // const [firstRender, setFirstRender] = useState<boolean>(true);

    useEffect(() => {
        setParams(
            additionalParams.map((param, index) => ({
                id: index,
                ...param,
            }))
        )
    }, [additionalParams]);

    return (
        <div className={'p-flex-column'}>
            {
                params.map(param => (
                    <div
                        key={param.id}
                        className={'p-d-flex p-flex-row p-mb-2'}
                        style={{ alignItems: 'center' }}
                    >
                        <InputText
                            type={'text'}
                            defaultValue={param.key}
                            onChange={e => {
                                const newParams = params.map(p => {
                                    if (p.id === param.id) {
                                        p.key = (e.target as any).value;
                                    }

                                    return p;
                                });

                                setParams(newParams);

                                if (changeImmediately) {
                                    onChange?.(newParams.map(param => ({ key: param.key, value: param.value })))
                                }
                            }}
                        />
                        &nbsp;=&nbsp;
                        <InputText
                            type={'text'}
                            defaultValue={param.value}
                            onChange={e => {
                                const newParams = params.map(p => {
                                    if (p.id === param.id) {
                                        p.value = (e.target as any).value;
                                    }

                                    return p;
                                });

                                setParams(newParams);

                                if (changeImmediately) {
                                    onChange?.(newParams.map(param => ({ key: param.key, value: param.value })))
                                }
                            }}
                        />

                        <Button
                            icon={'pi pi-minus'}
                            className={'p-ml-2'}
                            onClick={() => {
                                const newParams = params.filter(p => p.id !== param.id);

                                setParams(newParams);

                                if (changeImmediately) {
                                    onChange?.(newParams.map(param => ({ key: param.key, value: param.value })))
                                }
                            }}
                        />
                    </div>
                ))
            }

            <div className={'p-d-flex p-flex-row'}>
                <Button
                    label={'Add parameter'}
                    icon={'pi pi-plus'}
                    className={'p-mt-2'}
                    onClick={() => {
                        const newParams = [ ...params, {
                            id: Math.max(...params.map(p => p.id), -1) + 1,
                            key: 'emptyKey',
                            value: 'emptyValue',
                        } ];

                        setParams(newParams);

                        if (changeImmediately) {
                            onChange?.(newParams.map(param => ({ key: param.key, value: param.value })))
                        }
                    }}
                />

                {
                    !changeImmediately &&
                    <Button
                        label={'Update'}
                        icon={'pi pi-check'}
                        className={'p-button-success p-ml-2 p-mt-2'}
                        onClick={() => onChange?.(params.map(param => ({ key: param.key, value: param.value })))}
                    />
                }
            </div>
        </div>
    )
}
