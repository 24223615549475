import React, { FC } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

const AccessDenyPage: FC = () => {
    const history = useHistory();

    const goDashboard = () => {
        history.push("/");
    }

    return (
        <div className="pages-body accessdenied-page p-d-flex p-flex-column">
            <div className="p-as-center p-mt-auto p-mb-auto">
                <div className="pages-panel card p-d-flex p-flex-column">
                    <div className="pages-header p-px-3 p-py-1">
                        <h2>ACCESS DENIED</h2>
                    </div>
                    <div className="card p-mt-3 p-px-6">
                        <img src={"assets/layout/images/pages/error.png"} alt=""/>
                    </div>
                    <div className="pages-detail p-pb-6">Requested resource is not available.</div>
                    <Button type="button" onClick={goDashboard} label="GO BACK TO DASHBOARD" className="p-button-text"/>
                </div>
            </div>
        </div>
    )

}

export default AccessDenyPage;
