export const addInConditionally = (s: URL, cond: any[], field: string): URL => {
    s.searchParams.append(field, `in.(${cond.join(",")})`);
    return s;
}

export const addEqConditionally = (s: URL, field: string, value: string): URL => {
    s.searchParams.append(field, `eq.${value}`);
    return s;
}

export const addOrConditionally = (s: URL, condEq: { field: string, value: string }[], condLike: { field: string, value: string }[]): URL => {
    const condEqString =
        condEq.length > 0 ?
            condEq.map(c => `${c.field}.eq.${c.value}`).join(",")
            : undefined;

    const condLikeString =
        condLike.length > 0 ?
            condLike.map(c => `${c.field}.ilike.*${c.value}*`).join(",")
            : undefined;

    const searchString = `(${condEqString ?? ""}${(condEqString && condLikeString) ? "," : ""}${condLikeString})`;
    s.searchParams.append("or", searchString);
    return s;
}

export const addSkipAndLimit = (s: URL, offset: number = 0, limit: number = 30) => {
    if (!s.searchParams.has("offset")) {
        s.searchParams.append("offset", offset.toString());
    }
    if (!s.searchParams.has("limit")) {
        s.searchParams.append("limit", limit.toString());
    }
    return s;
}

export const addQuestionSign = (s: string) => {
    if (s.slice(-1) === "?") {
        return s;
    } else {
        return s + "?";
    }
}
