import { FC, useContext, useEffect } from "react";
import { AppStoreContext } from "../../../application/store/context";
import { refreshAuthData } from "../auth.actions";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import { info } from "../../../common/lib/logging";

interface AuthWrapperProps {
}

const AuthWrapper: FC<AuthWrapperProps> = (props) => {
    const appStore = useContext(AppStoreContext);
    const { authStore } = appStore;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (!authStore.isLogged) {
            info("[AuthWrapper] start auth wrapper");
            refreshAuthData(authStore)
                .then(result => {
                    if (!result) {
                        history.push(`/login?returnUrl=${window.location.href}`);
                    }
                });
        }
    }, [ location, authStore, authStore.isLogged, history ]);

    if (authStore.isLogging) {
        return (
            <div className="splash-screen">
                <div className="splash-loader-container">
                    <svg className="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="splash-path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"/>
                    </svg>
                </div>
            </div>
        )
    }

    if (authStore.isLogged) {
        return (
            <>
                {props.children}
            </>
        )
    }

    return null;
};

export default observer(AuthWrapper);
