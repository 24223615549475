import { History } from "history";
import { MenuItem } from "primereact/api";
import { AuthStore } from "./modules/auth/auth.store";
import { menuRoutes as usersMenuRoutes, topMenuRoutes as usersTopMenuRoutes } from "./modules/users/routes";
import { menuRoutes as dashboardMenuRoutes } from "./modules/dashboard/routes";
import { menuRoutes as tmsMenuRoutes, topMenuRoutes as tmsTopMenuRoutes } from "./modules/tms/routes";

export interface TopMenuItem {
    label: string;
    items: TopMenuItem[][];
}

export interface MenuLinkItem extends MenuItem {
    to?: string;
    badge?: string;
    badgeClassName?: string;
    items?: MenuLinkItem[];
}

const arrayFilterNull = (menu: (object | null | undefined)[]) => menu.filter(o => o !== null);
export const clean = (obj: any): any => {
    let o;
    if (Array.isArray(obj)) {
        o = arrayFilterNull(obj).map(item => clean(item));
    } else if (typeof obj === "object") {
        o = { ...obj };
        for (const propName in o) {
            if (o[propName] === null || o[propName] === undefined) {
                delete o[propName];
            }
            if (typeof o[propName] === "object") {
                o[propName] = clean(o[propName]);
            }
        }
    }

    return o;
};

export const topMenu = (authStore?: AuthStore, history?: History) => {
    if (!authStore || !history) {
        return undefined;
    }

    return (
        clean([
            ...usersTopMenuRoutes(authStore, history),
            ...tmsTopMenuRoutes(authStore, history)
        ])
    );
}

export const menu = (authStore: AuthStore): MenuLinkItem[] => (
    clean([
        ...dashboardMenuRoutes,
        ...usersMenuRoutes(authStore),
        ...tmsMenuRoutes(authStore),
    ])
);
