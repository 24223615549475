export const countHoursBetweenDates = (date1: Date, date2: Date): number => {
    let diff = (date2.getTime() - date1.getTime()) / (1000 * 3600);
    return Math.abs(Math.round(diff));
};

export const countDaysBetweenDates = (date1: Date, date2: Date): number => {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff = diff / 60 / 60 / 24;
    return Math.abs(Math.round(diff));
};

export const countMinutesBetweenDates = (date1: Date, date2: Date) => {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
};

export const countHoursBetweenDatesMoreThen = (date1: Date, date2: Date, delta: number): boolean => {
    return countHoursBetweenDates(date1, date2) >= delta;
};

export const getWeekNumber = (d: Date) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // @ts-ignore
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};
