export const isClientSide = typeof window !== 'undefined';

// two ways to use:
// 1 - with callback `const cookie = getSafeBrowserObject(() => document.cookie, '');`
// 2 - with return browser object ``const cookie = getSafeBrowserObject(() => document, null).cookie;``

export const getSafeBrowserObject = <T>(safeCallback: () => T, defaultValue: T) => {
    if (isClientSide) {
        return safeCallback();
    }

    return defaultValue;
};

export const isDesktop = () => {
    return window.innerWidth > 1024;
}

export const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
}

export const isRTL = false;
