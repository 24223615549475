import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import App from "./App";
import { AppStore } from "./store/app.store";
import { AppStoreContext } from './store/context';
import AuthWrapper from "../modules/auth/components/AuthWrapper";
import LoginPage from "./pages/LoginPage";
import AccessDenyPage from "./pages/AccessDenyPage";
import { withProfiler } from "@sentry/react";

const AppWrapper = () => {
    const [ appStore ] = useState(() => new AppStore());
    const location = useLocation();

    useEffect(() => {
        window && window.scrollTo(0, 0);
    }, [ location ]);

    return (
        <Switch>
            <Route path="/login" component={LoginPage}/>
            <Route path="/access" component={AccessDenyPage}/>
            <AppStoreContext.Provider value={appStore}>
                <AuthWrapper>
                    <Route path="/">
                        <App/>
                    </Route>
                </AuthWrapper>
            </AppStoreContext.Provider>
        </Switch>
    )
};

export default withProfiler(AppWrapper);
