import { AppStore } from "../../application/store/app.store";
import { handleError, turnOffGlobalSpinner, turnOnGlobalSpinner } from "../notifications/notification.actions";
import { info } from "../../common/lib/logging";
import { getEventsFromApi } from "./core.api.actions";

export const CORE_HANDLER_TITLE = "Core API service requests handler";
const CORE_EVENTS_UPDATE_PERIOD = 5;

export const getEventsAction = async (
    appStore: AppStore,
    setLoading?: (flag: boolean) => void,
    forceReload: boolean = false,
) => {
    const { notificationStore, authStore, coreStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getEventsAction] start get events`);

        const diff = coreStore.updatedAgeMin();
        if (forceReload || diff === null || diff >= CORE_EVENTS_UPDATE_PERIOD) {
            const events = await getEventsFromApi(authStore);

            coreStore.setEventsList(events);
        }
    } catch (error) {
        handleError(notificationStore, error, CORE_HANDLER_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}
