import React, { FC, useContext, useEffect, useState } from 'react';
import classNames from "classnames";
import styles from "../tms-tickets-list/TmsTicketsList.module.scss";

import { observer } from 'mobx-react-lite';

import { AppStore } from "../../../../../application/store/app.store";
import { AppStoreContext } from "../../../../../application/store/context";

import { TMSUpdateTicketType } from "../../tms.api.actions";

import { ProgressSpinner } from "primereact/progressspinner";
import { TmsTicketsTable } from "../tms-tickets-list/TmsTicketsTable";

import { TicketModel, TICKETS_LIST_LIMIT } from "../../models/ticket.model";

import { exportTicketsLinksToCSV, validateMagicCodeAndIndicate } from "../../tms.utils";
import { getTicketsAction, updateTicketAction } from "../../tms.actions";


const createExportFileName = (eventId?: number): string => {
    return `tmsTicketsLinks${eventId ? `_${eventId}` : ''}`;
}

export interface TmsTicketsSmartTableProps {
    eventId?: number;
    defaultTickets?: TicketModel[];
    defaultPage?: number;
    defaultTotalTicketsCount?: number;

    useOnePage?: boolean;
    loading?: boolean;

    filterEnabledDisabled?: 'enabled' | 'disabled' | 'all';
}

export const TmsTicketsSmartTable: FC<TmsTicketsSmartTableProps> = observer((props) => {
    const {
        eventId,
        defaultTickets,
        defaultPage,
        defaultTotalTicketsCount,

        useOnePage,
        loading,

        filterEnabledDisabled = 'all',
    } = props;

    const appStore = useContext(AppStoreContext);

    const [page, setPage] = useState<number>(0);
    const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
    const [tickets, setTickets] = useState<TicketModel[]>();
    const [totalTicketsCount, setTotalTicketsCount] = useState<number>(0);
    const [creatorSortDirection, setCreatorSortDirection] = useState<'desc' | 'asc' | undefined>(undefined);
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const refreshTicketsRequest = (
        eventId: number,
        enabled: 'enabled' | 'disabled' | 'all',
        skip: number = 0,
        limit: number = TICKETS_LIST_LIMIT,
        sortBy?: 'created',
        sortDirection?: 'desc' | 'asc' | undefined,
    ) => {
        setTicketsLoading(true);

        getTicketsAction(
            appStore,
            eventId,
            setTicketsLoading,
            setTotalTicketsCount,

            enabled === 'enabled'
                ? true
                : enabled === 'disabled'
                    ? false
                    : undefined,

            skip,
            limit,
            sortDirection === undefined ? undefined : sortBy,
            sortDirection,
        )
            .then(result => setTickets(result));
    };

    const updateTicket = async (store: AppStore, magicCode: string, data: TMSUpdateTicketType, update: boolean = true) => {
        const updatedTicket = await updateTicketAction(store, magicCode, data);

        if (updatedTicket && update) {
            setTickets(
                tickets?.map(ticket => (
                    ticket.magicCode === updatedTicket.magicCode
                        ? updatedTicket
                        : ticket
                ))
            )
        }
    }

    const exportLinks = async (thisTickets?: TicketModel[]) => {
        if (!eventId && !thisTickets) {
            return;
        }

        let exportTickets: TicketModel[] | undefined;

        if (!eventId) {
            exportTickets = thisTickets;
        } else {
            exportTickets = await getTicketsAction(
                appStore,
                eventId,
                undefined,
                undefined,
                filterEnabledDisabled === 'enabled' ? true : filterEnabledDisabled === 'disabled' ? false : undefined,
                0,
                totalTicketsCount,
            );
        }

        if (exportTickets) {
            exportTicketsLinksToCSV(exportTickets, createExportFileName(eventId));
        }

        return exportTickets?.length ?? 0;
    }

    useEffect(() => {
        if (!defaultTickets) {
            return;
        }

        setTickets(defaultTickets);
    }, [defaultTickets]);

    useEffect(() => {
        if (defaultPage === undefined) {
            return;
        }

        setPage(defaultPage);
    }, [defaultPage]);

    useEffect(() => {
        if (defaultTotalTicketsCount === undefined) {
            return;
        }

        setTotalTicketsCount(defaultTotalTicketsCount);
    }, [defaultTotalTicketsCount]);

    useEffect(() => {
        if (!loading) {
            return;
        }

        setTicketsLoading(loading);
    }, [loading]);

    useEffect(() => {
        if (!eventId) {
            return;
        }

        refreshTicketsRequest(eventId, filterEnabledDisabled);
    }, [eventId, filterEnabledDisabled]);

    return (
        <div>
            {
                ticketsLoading &&
                <div className={classNames(styles.spinnerContainer)}>
                    <ProgressSpinner />
                </div>
            }

            {
                !ticketsLoading &&
                <TmsTicketsTable
                    tickets={tickets ?? []}

                    page={page}
                    totalTicketsCount={totalTicketsCount}
                    useOnePage={useOnePage}

                    loading={ticketsLoading}

                    exportLoading={exportLoading}

                    setPage={(p) => {
                        setPage(p);
                        refreshTicketsRequest(
                            eventId!,
                            filterEnabledDisabled,
                            p * TICKETS_LIST_LIMIT,
                            TICKETS_LIST_LIMIT,
                            'created',
                            creatorSortDirection,
                        );
                    }}

                    sort={{ field: 'created', direction: creatorSortDirection }}
                    onSort={({ direction }) => {
                        setCreatorSortDirection(direction);

                        refreshTicketsRequest(
                            eventId!,
                            filterEnabledDisabled,
                            page,
                            TICKETS_LIST_LIMIT,
                            'created',
                            direction,
                        );
                    }}

                    onValidateMagicCode={async magicCode => await validateMagicCodeAndIndicate(appStore, magicCode)}

                    onTicketEnableChanged={ async (magicCode, enabled) =>
                        await updateTicket(appStore, magicCode, { enabled })
                    }

                    onTicketCommentChanged={ async (magicCode, comment) =>
                        await updateTicket(appStore, magicCode, { comment })
                    }

                    onTicketLandingChanged={ async (magicCode, landingPage) =>
                        await updateTicket(appStore, magicCode, { landingPage })
                    }

                    onTicketTariffOptionsChanged={ async (magicCode, tariffOptions) =>
                        await updateTicket(appStore, magicCode, { tariffOptions })
                    }

                    onTicketAdditionalParamsChanged={ async (magicCode, additionalParams) =>
                        await updateTicket(appStore, magicCode, { additionalParams })
                    }

                    onLinksToCSVExport={async () => {
                        setExportLoading(true);

                        const exportedCount = await exportLinks(
                            useOnePage ? tickets : undefined
                        );

                        appStore.notificationStore.addInfoNote(
                            `${exportedCount === 0 ? 'Link' : 'Links'} from ${exportedCount} ${exportedCount === 0 ? 'ticket' : 'tickets'}${eventId !== undefined ? ` of ${eventId} event` : ''} exported successfully!`,
                            'Export to scv',
                            1);

                        setExportLoading(false);
                    }}
                />
            }
        </div>
    )
})
