import { isDevelopment, isLocal } from "./env";

export const info = (msg: string, ...optionalParams: any[]) => {
    if (isDevelopment || isLocal) {
        if (optionalParams?.length !== 0) {
            console.info(msg, optionalParams);
        } else {
            console.info(msg);
        }
    }
}

export const error = (msg: string) => {
    console.error(msg);
}

export const warn = (msg: string, ...optionalParams: any[]) => {
    if (isDevelopment || isLocal) {
        if (optionalParams?.length !== 0) {
            console.warn(msg, optionalParams);
        } else {
            console.warn(msg);
        }
    }
}

export const debug = (msg: any, ...optionalParams: any[]) => {
    if (isDevelopment || isLocal) {
        if (optionalParams?.length !== 0) {
            console.debug(msg, optionalParams);
        } else {
            console.debug(msg);
        }
    }
}

export const logGroup = (msg?: any) => {
    if (isDevelopment || isLocal) {
        console.group(msg);
    }
}

export const logGroupCollapsed = (msg?: any) => {
    if (isDevelopment || isLocal) {
        console.groupCollapsed(msg);
    }
}

export const logGroupEnd = () => {
    if (isDevelopment || isLocal) {
        console.groupEnd();
    }
}

export const debugTable = (msg: any) => {
    if (isDevelopment || isLocal) {
        console.table(msg);
    }
}
