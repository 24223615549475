import { domain, jpaUrl, TENANT } from "../../config";

export const GLOBAL_ADMIN_ROLE = 'admin';
export const ONLINE_ADMIN_ROLE = 'online_admin';

export type OAuthProvider = 'google' | 'yandex';

const authPaths = {
    login: "/api/v2/auth/login",
    verify: "/api/v2/auth/verify",
    logout: "/api/v2/auth/logout"
}

export const getLoginUrl = (returnPage: string | null) => `${domain}${authPaths.login}/${TENANT}${(returnPage ? `?redirect_uri=${returnPage}` : ``)}`;

export const authUrls = {
    login: (returnPage: string | null) => getLoginUrl(returnPage),
    getOAuthUrl: (provider: OAuthProvider, returnPage: string) => `${jpaUrl}/api/v1/oauth2/authorization/${provider}?on_success=${encodeURIComponent(getLoginUrl(returnPage))}`,
    verify: `${domain}${authPaths.verify}/${TENANT}`,
    logout: `${domain}${authPaths.logout}?tenant=${TENANT}`,
    silentLogin: `${domain}${authPaths.login}/${TENANT}?prompt=none`,
}
