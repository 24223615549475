import { RightsModel } from "../modules/auth/rights.model";

export const onlyForAdmin: RightsModel = {
    rolesAvailable: [ "admin", "online_admin" ]
};

export const onlyGlobalAdmin: RightsModel = {
    globalAdminRequired: true
};

export const onlyOnlineAdmin: RightsModel = {
    onlineAdminRequired: true
};
