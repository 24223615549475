import users from "./modules/users/breadcrumbs";
import tms from "./modules/tms/breadcrumbs";

export interface BreadcrumbRoute {
    parent: string;
    subparent?: string;
    child: string;
}

export const breadcrumbs: BreadcrumbRoute[] = [
    {
        parent: "", child: ""
    },
    ...users,
    ...tms
];

