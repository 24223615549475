import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import { CSSTransition } from "react-transition-group";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { AppStoreContext } from "../store/context";
import { useHistory } from "react-router-dom";
import { tryLogout } from "../../modules/auth/auth.actions";
import { info } from "../../common/lib/logging";
import { config } from "../../config";
import { isRTL } from "../../common/lib/browser";

const AppInlineMenu = (props: any) => {
    const appStore = useContext(AppStoreContext);
    const { authStore } = appStore;

    const history = useHistory();

    const menuKey = props.menuKey || 'inline-menu';

    const inlineMenuClassName = classNames('layout-inline-menu', {
        'layout-inline-menu-active': props.inlineMenuActive[props.menuKey]
    }, props.className);

    const isSlim = () => {
        return props.menuMode === 'slim';
    }

    const logoutHandleClick = () => {
        tryLogout(appStore)
            .then(() => {
                info("sucessfully logout");
                history.push("/login")
            })
    }

    return (
        <div className={inlineMenuClassName} style={props.style}>
            {isSlim() && <Tooltip target=".avatarTooltip"/>}

            <button data-pr-tooltip="Amy Elsner" className={classNames('avatarTooltip layout-inline-menu-action p-link p-d-flex p-dir-row p-ai-center', { 'p-p-3 p-p-lg-1 p-py-3': props.horizontal, 'p-p-3': !props.horizontal })} onClick={(e) => props.onInlineMenuClick(e, menuKey)}>
                {
                    authStore.photo &&
                    <img src={authStore.photo} alt="avatar" style={{ width: '32px', height: '32px' }}/>
                }
                <span className={classNames('p-d-flex p-flex-column', { 'p-ml-2': !isRTL, 'p-mr-2': isRTL })}>
                    <span className="p-text-bold">{authStore.displayName}</span>
                    <small>User</small>
                </span>
                <i className={classNames('layout-inline-menu-icon pi pi-angle-down', { 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL })}/>
            </button>

            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.inlineMenuActive[menuKey]} unmountOnExit>
                <>
                    <ul className="layout-inline-menu-action-panel">
                        {/*<li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Settings">*/}
                        {/*    <button className="p-d-flex p-flex-row p-ai-center p-link">*/}
                        {/*        <i className="pi pi-cog pi-fw"/>*/}
                        {/*        <span>Settings</span>*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        {/*<li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Terms of Usage">*/}
                        {/*    <button className="p-d-flex p-flex-row p-ai-center p-link">*/}
                        {/*        <i className="pi pi-file-o pi-fw"/>*/}
                        {/*        <span>Terms of Usage</span>*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li className="layout-inline-menu-action-item tooltip">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={config.supportUrl} target="_blank">
                                <button className="p-d-flex p-flex-row p-ai-center p-link">
                                    <i className='pi pi-compass'/>
                                    <span>Support</span>
                                </button>
                            </a>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Logout"
                            onClick={logoutHandleClick}
                        >
                            <button className="p-d-flex p-flex-row p-ai-center p-link">
                                <i className="pi pi-power-off pi-fw"/>
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                    {
                        isSlim() && <Tooltip target=".tooltip"/>
                    }
                </>
            </CSSTransition>

        </div>
    );
}

export default observer(AppInlineMenu);
