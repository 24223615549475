import { isDevelopment, isLocal, isProduction, isTesting } from "./common/lib/env";

export const TENANT = "dashboard";

export interface AppConfig {
    productsUrls: {
        liveUrl: string;
        crmUrl: string;
        jpaUrl: string;
        jugUrl: string;
    },
    supportUrl: string;
    liveBackendBasePath: string;
    mdmServiceBasePath: string;
    jpaServiceAPIBasePath: string;
    tmsServiceBasePath: string;
    coreApiBasePath: string;
}

export const domain = isLocal ? 'http://localhost:3000' :
    isDevelopment ? 'https://dashboard-dev.jugru.team' :
        isTesting ? 'https://dashboard-test.jugru.team' :
            'https://dashboard.jugru.team';

export const liveUrl = isLocal ? "https://embedded-dev.jugru.org" :
    isTesting ? "https://embedded-test.jugru.org" :
        isProduction ? "https://embedded.jugru.org" :
            "https://embedded-dev.jugru.org";

export const servicesUrl = isLocal ? "https://services-dev.jugru.team" :
    isTesting ? "https://services-test.jugru.team" :
        isProduction ? "https://services.jugru.team" :
            "https://services-dev.jugru.team";

export const jpaUrl = isLocal ? "https://lk-dev.jugru.org" :
    isTesting ? "https://lk-test.jugru.org" :
        isProduction ? "https://my.jugru.org" :
            "https://lk-dev.jugru.org";

export const config: AppConfig = {
    productsUrls: {
        liveUrl,
        crmUrl: "https://crm.jugru.org",
        jpaUrl,
        jugUrl: "https://jugru.org"
    },
    supportUrl: "https://t.me/JUGConfSupport_bot",
    liveBackendBasePath: `${liveUrl}/api/v2`,
    mdmServiceBasePath: `${servicesUrl}/analytics-data/mdm-analytics-data-proxy/api/v1`,
    tmsServiceBasePath: `${servicesUrl}/online/online-magic-code-tms/api/v1`,
    jpaServiceAPIBasePath: `${jpaUrl}/restws/v1`,
    coreApiBasePath: `${servicesUrl}/core/api/v1`,
}
