import classNames from "classnames";
import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppProfile from "./AppProfile";
import AppProductsMenu from "./AppProductsMenu";
import NotificationRing from "../../modules/notifications/components/NotificationRing";
import AppLoader from "./AppLoader";
import { observer } from "mobx-react-lite";
import { topMenu } from "../../menu";
import { AppStoreContext } from "../store/context";
import { MegaMenu } from "primereact/megamenu";

interface AppTopBarProps {
    onMenuButtonClick: (event: any) => void;
    onMobileTopBarButtonClick: (event: any) => void;
    onTopBarItemClick: (event: any) => void;
    horizontal: boolean;
    mobileTopBarActive: boolean;
    activeTopBarItem: string | null;
}

const AppTopBar: FC<AppTopBarProps> = (props) => {
    const appStore = useContext(AppStoreContext);
    const { authStore } = appStore;

    const history = useHistory();
    const topM = topMenu(authStore, history);

    return (
        <div className="layout-topbar p-shadow-4">
            <div className="layout-topbar-left">
                <button type="button" style={{ cursor: "pointer" }} className="layout-topbar-logo p-link" onClick={() => history.push("/")}>
                    <img id="app-logo" src={"assets/images/logo.svg"} alt="jugru-layout" style={{ height: "8.25rem" }}/>
                </button>
                <button type="button" className="layout-menu-button p-shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right"/>
                </button>
                <button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopBarButtonClick}/>
                </button>
            </div>

            <div className={classNames("layout-topbar-right", { "layout-topbar-mobile-active": props.mobileTopBarActive })}>
                <div className="layout-topbar-actions-left">
                    {
                        topM && <MegaMenu model={topM} className="layout-megamenu"/>
                    }
                </div>
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        {/*<li className="layout-topbar-item layout-search-item">*/}
                        {/*    <button className="layout-topbar-action rounded-circle p-link" onClick={() => props.onSearch(true)}>*/}
                        {/*        <i className="pi pi-search fs-large"/>*/}
                        {/*    </button>*/}
                        {/*    <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.searchActive} unmountOnExit>*/}
                        {/*        <div className="layout-search-panel p-inputgroup">*/}
                        {/*            <span className="p-inputgroup-addon"><i className="pi pi-search"/></span>*/}
                        {/*            <InputText type="text" placeholder="Search" onKeyDown={onInputKeydown}/>*/}
                        {/*            <span className="p-inputgroup-addon">*/}
                        {/*                <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-text p-button-plain" onClick={() => props.onSearch(false)}/>*/}
                        {/*            </span>*/}
                        {/*        </div>*/}
                        {/*    </CSSTransition>*/}
                        {/*</li>*/}

                        <AppLoader/>
                        <NotificationRing activeTopbarItem={props.activeTopBarItem} onTopbarItemClick={props.onTopBarItemClick}/>
                        <AppProductsMenu activeTopbarItem={props.activeTopBarItem} onTopbarItemClick={props.onTopBarItemClick}/>
                        <li className="layout-topbar-item">
                            <AppProfile activeTopbarItem={props.activeTopBarItem} onTopbarItemClick={props.onTopBarItemClick}/>
                        </li>
                        {/*<li className="layout-topbar-item">*/}
                        {/*    <button type="button" className="layout-topbar-action rounded-circle p-link" onClick={props.onRightMenuButtonClick}>*/}
                        {/*        <i className="pi fs-large pi-arrow-left"></i>*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default observer(AppTopBar);
