import { AccessToken } from "./lib/accessToken";
import { AuthStore } from "./auth.store";
import { logout, silentLogin, verify } from "./auth.api.actions";
import { info } from "../../common/lib/logging";
import { AppStore } from "../../application/store/app.store";
import { handleError } from "../notifications/notification.actions";

export const refreshAuthData = (authStore: AuthStore): Promise<boolean> => {
    authStore.setIsLogging(true);
    const accessToken = AccessToken.receive();

    info("[refreshAuthData] accessToken", accessToken);

    if (!accessToken.hasValue) {
        return tryVerify(authStore);
    } else {
        if (accessToken.isExpired) {
            return tryVerify(authStore);
        } else {
            authStore.updateFromAccessToken(accessToken.value);
            return Promise.resolve(true);
        }
    }
}

export const NUMBER_OF_VERIFICATION_ATTEMPTS = 1;

export const tryVerify = (authStore: AuthStore, onAnywaySilentLogin?: () => void, onSilentLogin?: () => void): Promise<boolean> => {
    // for testing failed auth
    // if(isLocal()) {
    //     return Promise.resolve(false);
    // }

    if (authStore.currentVerificationAttemptsCount >= NUMBER_OF_VERIFICATION_ATTEMPTS) {
        return Promise.resolve(false);
    }

    authStore.incCurrentVerificationAttemptsCount();

    return verify()
        .then(ok => {
            info("tryVerify", ok);
            if (!ok) {
                return silentLogin()
                    .then(() => {
                        if (AccessToken.receive().hasValue) {
                            onAnywaySilentLogin?.();
                            return true;
                        }
                        return false;
                    }).catch(() => {
                        return false;
                    });
            }

            return Promise.resolve(true);
        })
        .then((silentLoginResult) => {
            const accessToken = AccessToken.receive();
            authStore.updateFromAccessToken(accessToken.value);

            if (silentLoginResult) {
                onSilentLogin?.();
                return true;
            }
            return false;
        });
}

export const tryLogout = (appStore: AppStore) => {
    return logout()
        .catch(error => handleError(appStore.notificationStore, error, "Auth handler"));
}
