import React, { FC, useContext } from 'react';
import { observer } from "mobx-react-lite";
import { CSSTransition } from "react-transition-group";
import { AppStoreContext } from "../../../application/store/context";
import NotificationMessage from "./NotificationMessage";

interface NotificationRingProps {
    onTopbarItemClick: (event: any) => void;
    activeTopbarItem: string | null;
}

const NotificationRing: FC<NotificationRingProps> = ({ onTopbarItemClick, activeTopbarItem }) => {
    const appStore = useContext(AppStoreContext);

    const { notificationStore } = appStore;

    return (
        <li className="layout-topbar-item notifications">
            <button className="layout-topbar-action rounded-circle p-link" onClick={(event) => onTopbarItemClick({ originalEvent: event, item: 'notifications' })}>
                                <span className="p-overlay-badge"
                                      style={{ marginTop: "6px" }}
                                >
                                    <i className="pi pi-bell fs-large"/>
                                    {
                                        notificationStore.unreadNotifications.length > 0 &&
                                        <span className="p-badge p-badge-warning p-badge-dot"/>
                                    }
                                    {
                                        notificationStore.unreadNotifications.length === 0 &&
                                        <span className="p-badge p-badge-dot"/>
                                    }
                                </span>
            </button>
            {
                notificationStore.unreadNotifications.length > 0 &&
                <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={activeTopbarItem === 'notifications'} unmountOnExit>
                    <ul className="layout-topbar-action-panel p-shadow-6 fadeInDown">
                        <li className="p-mb-3">
                            <span className="p-px-3 fs-small">You have <b>{notificationStore.unreadNotifications.length}</b> new notifications</span>
                        </li>
                        {
                            notificationStore.unreadNotifications?.map(note => {
                                return <NotificationMessage key={note.id}
                                                            notification={note}
                                                            clickCallback={() => notificationStore.markAsRead(note.id)}
                                />
                            })
                        }
                    </ul>
                </CSSTransition>
            }
        </li>
    );
};

export default observer(NotificationRing);
