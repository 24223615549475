import React, { useContext } from 'react';
import { AppStoreContext } from "../store/context";
import { observer } from "mobx-react-lite";

const AppLoader = () => {
    const notificationStore = useContext(AppStoreContext).notificationStore;

    if (!notificationStore.isLoading) return null;

    return (
        <li className="layout-topbar-item">
            <i className="layout-topbar-action pi pi-spin pi-spinner fs-large orange-color" />
        </li>
    );
};

export default observer(AppLoader);
