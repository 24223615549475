import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { USER_FINGERPRINT_PAGE_ROUTE, USER_LIST_PAGE_ROUTE } from "../../users/routes";
import OpenRequestsCard from "../../users/user-fingerprint-admin/components/OpenRequestsCard";
import UsersStatsCard from "../../users/user-admin/components/users-list/UsersStatsCard";

const DashboardPage = () => {
    const history = useHistory();
    // const appStore = useContext(AppStoreContext);
    // const { authStore, notificationStore } = appStore;
    // const [ loading, setLoading ] = useState<boolean>(false);

    return (
        <div className="p-grid dashboard">
            <OpenRequestsCard
                title="Open requests"
                mainActionCallback={() => {
                    history.push(USER_FINGERPRINT_PAGE_ROUTE)
                }}
                mainActionIcon="pi-external-link"
                sizeClass="p-md-4 p-lg-4 p-xl-4"
            />
            <UsersStatsCard
                mainActionCallback={() => {
                    history.push(USER_LIST_PAGE_ROUTE)
                }}
                mainActionIcon="pi-external-link"
                sizeClass="p-md-8 p-lg-8 p-xl-6"
            />
        </div>
    );
};

export default observer(DashboardPage);
