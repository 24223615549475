export const clearLineChartOptions = {
    legend: {
        display: false
    },
    responsive: true,
    scales: {
        yAxes: [ {
            display: false
        } ],
        xAxes: [ {
            display: false
        } ]
    },
    tooltips: {
        enabled: false
    },
    elements: {
        point: {
            radius: 0
        }
    },
};


export const getChartBackgroundColor = (index: number, opacity: number = 80, colorMode: string = 'light'): string => {
    const color = getChartBackgroundColors(colorMode)[index];
    if (color === undefined) {
        return convertHexToRGBA(getChartBackgroundColors(colorMode)[0], opacity);
    } else {
        return convertHexToRGBA(color, opacity);
    }
}

export const getChartBackgroundColors = (colorMode: string): string[] => {
    return Object.values(getColors(colorMode));
};

export const getColors = (colorMode: string) => {
    const isLight = colorMode === 'light';
    return {
        pinkColor: isLight ? '#EC407A' : '#F48FB1',
        greenColor: isLight ? '#66BB6A' : '#A5D6A7',
        deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
        blueColor: isLight ? '#42A5F5' : '#90CAF9',
        orangeColor: isLight ? '#FFA726' : '#FFCC80',
        purpleColor: isLight ? '#AB47BC' : '#CE93D8',
        deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
        indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
        yellowColor: isLight ? '#FFEE58' : '#FFF59D',
        lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
        amberColor: isLight ? '#FFCA28' : '#FFE082',
        cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
        tealColor: isLight ? '#26A69A' : '#80CBC4',
        lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
        limeColor: isLight ? '#D4E157' : '#E6EE9C',
        brownColor: isLight ? '#8D6E63' : '#BCAAA4'
    }
};

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
};
