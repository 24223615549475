import React, { FC } from 'react';
import classNames from "classnames";
import { NotificationsModels } from "../models/notifications.models";
import { countHoursBetweenDates, countMinutesBetweenDates } from "../../../common/lib/time";

interface NotificationMessageProps {
    notification: NotificationsModels;
    clickCallback?: () => void;
}

const NotificationMessage: FC<NotificationMessageProps> = ({ notification, clickCallback }) => {
    const minutes = countMinutesBetweenDates(notification.created, new Date());
    const hours = countHoursBetweenDates(notification.created, new Date());

    const renderIcon = () => {
        if (notification.picture) return null;

        switch (notification.importance) {
            case "ERROR":
                return <i className="pi pi-exclamation-triangle fs-large pink-color"/>
            case "WARNING":
                return <i className="pi pi-exclamation-circle fs-large orange-color"/>
            case "INFO":
                return <i className="pi pi-info-circle fs-large green-color"/>
            default:
                return <i className="pi pi-info-circle fs-large green-color"/>
        }
    }

    return (
        <li className="layout-topbar-action-item" onClick={clickCallback}>
            <div className="p-d-flex p-flex-row p-ai-center">
                {
                    notification.picture && <img src={notification.picture} alt=""/>
                }
                {
                    renderIcon()
                }
                <div className={classNames('p-d-flex p-flex-column', 'p-ml-3')} style={{ flexGrow: 1 }}>
                    <div className="p-d-flex p-ai-center p-jc-between p-mb-1">
                        <span className="fs-small p-text-bold">{notification.title}</span>
                        {
                            hours > 0 &&
                            <small>{hours} hours ago</small>
                        }
                        {
                            hours === 0 && minutes <= 60 &&
                            <small>{minutes} min ago</small>
                        }
                    </div>
                    <span className="fs-small">{notification.message}</span>
                </div>
            </div>
        </li>
    );
};

export default NotificationMessage;
