import { action, computed, makeAutoObservable, observable } from "mobx";
import { NotificationSeverenity, NotificationsModels } from "./models/notifications.models";
import { uuidv4 } from "../../common/lib/uuid";
import { countMinutesBetweenDates } from "../../common/lib/time";
import { AuthStore } from "../auth/auth.store";

export class NotificationsStore {
    @observable
    private notifications: NotificationsModels[] = [];

    @observable
    isLoading: boolean = false;

    constructor(public authStore: AuthStore) {
        makeAutoObservable(this);
        setInterval(() => this.clearNotes(), 10000);
    }

    @action
    clearNotes() {
        for (const notification of this.notifications.filter(n => !n.isRead && n.timeToLiveInMin !== undefined)) {
            const diff = countMinutesBetweenDates(notification.created, new Date());
            // info("[clearNotes] ", diff);
            if (notification.timeToLiveInMin && (diff >= notification.timeToLiveInMin)) {
                notification.isRead = true;
            }
        }
    }

    @action
    addError(message: string, title?: string, timeToLiveInMin?: number) {
        const errorNote: NotificationsModels = {
            id: uuidv4(),
            title: title ?? "Some Error",
            message,
            created: new Date(),
            importance: "ERROR",
            timeToLiveInMin
        };
        this.notifications.push(errorNote);
    }

    @action
    addNote(message: string, title: string, importance: NotificationSeverenity, timeToLiveInMin?: number) {
        const note: NotificationsModels = {
            id: uuidv4(),
            title,
            message,
            created: new Date(),
            importance,
            timeToLiveInMin
        };
        this.notifications.push(note);
    }

    @action
    addInfoNote(message: string, title: string, timeToLiveInMin?: number) {
        this.addNote(message, title, "INFO", timeToLiveInMin);
    }

    @action
    addWarnNote(message: string, title: string, timeToLiveInMin?: number) {
        this.addNote(message, title, "WARNING", timeToLiveInMin);
    }

    @action
    markAsRead(id: string) {
        const note = this.notifications.find(note => note.id === id);
        if (note) {
            note.isRead = true;
        }
    }

    @action
    markAsShowed(id: string) {
        const note = this.notifications.find(note => note.id === id);
        if (note) {
            note.isShowed = true;
        }
    }

    @action
    turnOnGlobalIsLoading() {
        this.isLoading = true;
    }

    @action
    turnOffGlobalIsLoading() {
        this.isLoading = false;
    }

    @computed
    get unreadNotifications() {
        return this.notifications.filter(note => note.isRead === false || note.isRead === undefined);
    }

    getLastElement() {
        if (this.unreadNotifications.length > 0) {
            return this.unreadNotifications[this.unreadNotifications.length - 1];
        }
        return null;
    }

    // @action
    // getLastError() {
    //     if (this.errors.length > 0) {
    //         return this.errors.pop();
    //     }
    // }
}
