import * as React from "react";
import { FC, useContext } from "react";
import { AppStoreContext } from "../../../application/store/context";
import { observer } from "mobx-react-lite";
import { RightsModel } from "../rights.model";
import { Redirect } from "react-router-dom";

interface RightsWrapperWrapperProps {
    hideWrapper?: boolean;
    rights: RightsModel;
}

const denyWrapperComponent = (hideWrapper?: boolean) => {
    if (hideWrapper) {
        return null;
    }
    return <Redirect to="/access"/>
}

const RightsWrapper: FC<RightsWrapperWrapperProps> = ({
                                                          children,
                                                          hideWrapper,
                                                          rights
                                                      }) => {
    const authStore = useContext(AppStoreContext).authStore;

    if (!authStore.isLogged && !authStore.isLogging) {
        return denyWrapperComponent(hideWrapper);
    }

    if (rights.globalAdminRequired && !authStore.isGlobalAdmin()) {
        return denyWrapperComponent(hideWrapper);
    }

    if (rights.onlineAdminRequired && !authStore.isOnlineAdmin()) {
        return denyWrapperComponent(hideWrapper);
    }

    if (rights.rolesRequired && rights.rolesRequired.length > 0 && !authStore.isInRolesAND(rights.rolesRequired)) {
        return denyWrapperComponent(hideWrapper);
    }

    if (rights.rolesAvailable && rights.rolesAvailable.length > 0 && !authStore.isInRolesOR(rights.rolesAvailable)) {
        return denyWrapperComponent(hideWrapper);
    }

    return (
        <>
            {
                children
            }
        </>
    );
};

export default observer(RightsWrapper);
