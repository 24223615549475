export const USERS_LIST_LIMIT = 10;

export interface TmsUserModel {
    id: number;
    creator: string;
    created: Date;
    updater?: string,
    updated?: Date;
    name: string;
    surname?: string;
    email: string;
    phone?: string;
    linkedLkUserId?: number;
    isAnonymous?: boolean;
}
