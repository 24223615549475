import { AppStore } from "../../../application/store/app.store";

import { validateMagicCodeAction } from "./tms.actions";

import { TicketModel } from "./models/ticket.model";


export const validateMagicCodeAndIndicate = async (store: AppStore, magicCode: string) => {
    const buttonElement = document.getElementById(`btn${magicCode}`) as HTMLButtonElement;

    if (buttonElement) {

        const label = buttonElement.getElementsByClassName('p-button-label p-c')?.item(0);
        const icon = buttonElement.getElementsByClassName('p-c p-button-icon-left')?.item(0);

        if (!label || !icon) {
            return;
        }

        label.innerHTML = 'Validate';
        buttonElement.classList.remove('p-button-success');
        buttonElement.classList.remove('p-button-danger');
        buttonElement.classList.add('p-button-primary');
        icon.classList.remove('pi-check-circle');
        icon.classList.remove('pi-times-circle');
        icon.classList.add('pi-spin');
        icon.classList.add('pi-spinner');

        const result = await validateMagicCodeAction(store, magicCode);

        if (result) {
            label.innerHTML = 'Valid';
            buttonElement.classList.remove('p-button-primary');
            buttonElement.classList.add('p-button-success');
            icon.classList.remove('pi-shield');
            icon.classList.remove('pi-spin');
            icon.classList.remove('pi-spinner');
            icon.classList.add('pi-check-circle');
        } else {
            label.innerHTML = 'Invalid';
            buttonElement.classList.remove('p-button-primary');
            buttonElement.classList.add('p-button-danger');
            icon.classList.remove('pi-shield');
            icon.classList.remove('pi-spin');
            icon.classList.remove('pi-spinner');
            icon.classList.add('pi-times-circle');
        }
    }
}

export const exportTicketsLinksToCSV = (tickets: TicketModel[] | undefined, exportFilename: string) => {
    if (!tickets) {
        return false;
    }

    let csv = "\uFEFF" + tickets.map(ticket => ticket.link).join('\n');

    const blob = new Blob([csv], {
        type: 'text/csv;charset=utf-8;'
    });

    if (window.navigator['msSaveOrOpenBlob'] !== undefined) {
        navigator.msSaveOrOpenBlob(blob, exportFilename + '.csv');
    } else {
        const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);

        if (link.download !== undefined) {
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', exportFilename + '.csv');
            link.click();
        } else {
            csv = 'data:text/csv;charset=utf-8,' + csv;
            window.open(encodeURI(csv));
        }

        document.body.removeChild(link);
    }

    return true;
}
