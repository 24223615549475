import { CSSTransition } from "react-transition-group";
import React, { FC, useContext } from "react";
import { AppStoreContext } from "../store/context";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { tryLogout } from "../../modules/auth/auth.actions";
import { info } from "../../common/lib/logging";
import { config } from "../../config";
import classNames from "classnames";
import { isRTL } from "../../common/lib/browser";

interface AppProfileProps {
    activeTopbarItem: string | null;
    onTopbarItemClick: (event: any) => void;
}

export const UserMenuActions = (logoutHandleClick: () => void) => (
    <>
        {/*<li className="layout-topbar-action-item">*/}
        {/*    <button className="p-d-flex p-flex-row p-ai-center p-link">*/}
        {/*        <i className={classNames('pi pi-cog', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>*/}
        {/*        <span>Settings</span>*/}
        {/*    </button>*/}
        {/*</li>*/}
        {/*<li className="layout-topbar-action-item">*/}
        {/*    <button className="p-d-flex p-flex-row p-ai-center p-link">*/}
        {/*        <i className={classNames('pi pi-file-o', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>*/}
        {/*        <span>Terms of Usage</span>*/}
        {/*    </button>*/}
        {/*</li>*/}
        <li className="layout-topbar-action-item ">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={config.supportUrl} target="_blank">
                <button className="p-d-flex p-flex-row p-ai-center p-link">
                    <i className={classNames('pi pi-compass', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>
                    <span>Support</span>
                </button>
            </a>
        </li>
        <li className="layout-topbar-action-item"
            onClick={logoutHandleClick}
        >
            <button className="p-d-flex p-flex-row p-ai-center p-link">
                <i className={classNames('pi pi-power-off', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>
                <span>Logout</span>
            </button>
        </li>
    </>
);

const AppProfile: FC<AppProfileProps> = (props) => {
    const appStore = useContext(AppStoreContext);
    const { authStore } = appStore;

    const history = useHistory();

    const logoutHandleClick = () => {
        tryLogout(appStore)
            .then(() => {
                info("[AppProfile] logout forces succesffuly");
                history.push("/login")
            })
    }

    return (
        <>
            <button className="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle p-link" onClick={(event) => props.onTopbarItemClick({ originalEvent: event, item: 'profile' })}>
                <img src={authStore.photo} alt="avatar" style={{ width: '32px', height: '32px' }}/>
            </button>

            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                <ul className="layout-topbar-action-panel p-shadow-6">
                    {UserMenuActions(logoutHandleClick)}
                </ul>
            </CSSTransition>
        </>
    );
};

export default observer(AppProfile);
