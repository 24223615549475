import React, { FC, useEffect, useState } from 'react';

import { TicketModelTariffOptionsItem } from "../../models/ticket.model";

import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";


const TARIFF_OPTIONS = [
    { name: 'LIVE_BROADCAST', value: 'LIVE_BROADCAST' },
    { name: 'VIDEO_ARCHIVE', value: 'VIDEO_ARCHIVE' },
    { name: 'DISCUSSION_ZONE', value: 'DISCUSSION_ZONE' },
    { name: 'GAME', value: 'GAME' },
];

export interface TariffOptionsMultiSelectProps {
    defaultValue: any[];
    optionLabel: string;
    optionValue: string;

    disabled?: boolean;

    changeImmediately?: boolean;

    onChange?: (newValue: TicketModelTariffOptionsItem[]) => void;
}

export const TariffOptionsMultiSelect: FC<TariffOptionsMultiSelectProps> = (props) => {
    const {
        defaultValue,
        optionLabel,
        optionValue,

        disabled,

        changeImmediately,

        onChange,
    } = props;

    const [value, setValue] = useState<any[]>([]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <>
            <MultiSelect
                disabled={disabled}

                display={'chip'}
                optionLabel={optionLabel}
                optionValue={optionValue}
                value={value}

                options={TARIFF_OPTIONS}
                onChange={(e) => {
                    setValue(e.value);

                    if (changeImmediately) {
                        onChange?.(e.value);
                    }
                }}
            />

            {
                !changeImmediately &&
                <Button
                    disabled={!value?.length || disabled}
                    label={'Update'}
                    icon={'pi pi-check'}
                    className={'p-button-success p-ml-2 p-mt-2'}
                    onClick={() => onChange?.(value)}
                />
            }

            {
                disabled &&
                <span>&nbsp; Set permanently</span>
            }
        </>
    )
}
