import React, { FC, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { UserDeviceRemovalRequestModel } from "../models/user-device.models";
import { bodyTemplate, booleanTemplate, dateTemplate } from "../../../../common/components/tables";

const nameTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            {data[props.field][0]?.userInfo.displayName}
        </>
    );
};

const emailTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            {data[props.field][0]?.userInfo.email}
        </>
    );
};

const cityTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <div className="p-d-flex p-ai-center">
            <span className="p-mr-2">
                {
                    data[props.field].name
                }
            </span>
                {
                    data.latitude && data.longitude &&
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={`https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`} target="_blank">
                        <Button icon="pi pi-map"/>
                    </a>
                }
            </div>
        </>
    )
};

const ipTemplate = (data: any, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <div className="p-d-flex p-ai-center">
            <span className="p-mr-2">
                {
                    data[props.field]
                }
            </span>
                {
                    data.ip &&
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a href={`https://ip-api.com/#${data.ip}`} target="_blank">
                        <Button icon="pi pi-question"/>
                    </a>
                }
            </div>
        </>
    )
};

const browserDetails = (data: any, props: any) => {
    const browser = data[props.field];
    return (
        <div className="p-flex-column">
            <div>{browser.browserName}</div>
            <div>{browser.browserFullVersion}</div>
            <div>{browser.device}</div>
        </div>
    )

};
const statusBodyTemplate = (data: UserDeviceRemovalRequestModel, props: any) => {
    return (
        <>
            <span className="p-column-title">{props.header ?? ""}</span>
            <span className={`request-badge status-${data.status.toLowerCase()}`}>{data.status}</span>
        </>
    )
};

interface RequestsTableProps {
    requests: UserDeviceRemovalRequestModel[];
    loading: boolean;
    approveCallback: (requestId: string) => void;
    approveAllCallback: (requests: UserDeviceRemovalRequestModel[]) => void;
    rejectCallback: (requestId: string) => void;
    rejectAllCallback: (requests: UserDeviceRemovalRequestModel[]) => void;
    rowExpandCallback: (userDeviceId: string) => void;
    setSearchStringCallback: (s: string) => void;
}

const RequestsTable: FC<RequestsTableProps> = ({
                                                   requests,
                                                   loading,
                                                   approveCallback,
                                                   rejectCallback,
                                                   approveAllCallback,
                                                   rejectAllCallback,
                                                   rowExpandCallback,
                                                   setSearchStringCallback,
                                               }) => {
    const [ selectedRows, setSelectedRows ] = useState<UserDeviceRemovalRequestModel[]>([]);
    const [ expandedRows, setExpandedRows ] = useState<any[]>();

    const deviceIdTemplate = (data: any, props: any) => {
        return (
            <>
                <span className="p-column-title">{props.header ?? ""}</span>
                <div className="p-d-flex p-ai-center">
                    <span>{data[props.field].deviceId}</span>
                    <Button className="p-ml-auto" icon="pi pi-search" onClick={() => setSearchStringCallback(data[props.field].deviceId)}/>
                </div>
            </>
        )
    }

    const removedDeviceIdTemplate = (data: any, props: any) => {
        return (
            <>
                <span className="p-column-title">{props.header ?? ""}</span>
                <div className="p-d-flex p-ai-center">
                <span>
                    {
                        data[props.field]?.deviceInfo?.deviceId
                    }
                </span>
                    <Button className="p-ml-auto" icon="pi pi-search" onClick={() => setSearchStringCallback(data[props.field]?.deviceInfo?.deviceId)}/>
                </div>
            </>
        )
    }

    const rowExpansionTemplate = (data: UserDeviceRemovalRequestModel) => {
        return (
            <div className="orders-subtable">
                <h5>Info for <span className="deeporange-color">{data.deviceInfo?.deviceId}</span> deviceId</h5>
                {
                    data.deviceInfo && <DataTable
                        value={[ data.deviceInfo ]}
                        dataKey="deviceId"
                        className="p-datatable-users-details"
                    >
                        <Column field="deviceId" header="DeviceId" body={bodyTemplate}/>
                        <Column field="browserDetails" header="Browser" body={browserDetails}/>
                        <Column field="incognito" header="Incognito" body={booleanTemplate}/>
                        <Column field="auditedAt" header="Date" body={bodyTemplate}/>
                        <Column field="ip" header="IP" body={ipTemplate}/>
                        <Column field="city" header="City" body={cityTemplate}/>
                    </DataTable>
                }
                <h5>Info for active devices of <span className="deeporange-color">{data.userSub}</span> user</h5>
                {
                    data.activeDevices && <DataTable
                        value={data.activeDevices}
                        dataKey="deviceInfo"
                        className="p-datatable-users-details"
                    >
                        <Column field="deviceInfo" header="DeviceId" body={deviceIdTemplate}/>
                        <Column field="registeredAt" header="Date" body={dateTemplate}/>
                    </DataTable>
                }
                <h5>Info for removed devices of <span className="deeporange-color">{data.userSub}</span> user</h5>
                {
                    data.removedDevices && <DataTable
                        value={data.removedDevices}
                        dataKey="removedUserDevice"
                        className="p-datatable-users-details"
                    >
                        <Column field="removedUserDevice" header="DeviceId" body={removedDeviceIdTemplate}/>
                        <Column field="removedAt" header="Removed Date" body={dateTemplate}/>
                        <Column field="removedByEmail" header="Removed Email" body={bodyTemplate}/>
                        <Column field="removalResolution" header="Removed Resolution" body={bodyTemplate}/>
                    </DataTable>
                }
            </div>
        );
    };

    const actionTemplate = (data: UserDeviceRemovalRequestModel) => {
        return (
            <div className="p-d-flex">
                {
                    data.status === "OPEN" && <Button type="button" icon="pi pi-check" className="p-button-primary p-mr-2" onClick={() => approveCallback(data.requestId)}/>
                }
                {
                    data.status === "OPEN" && <Button type="button" icon="pi pi-minus-circle" className="p-button-secondary p-mr-2" onClick={() => rejectCallback(data.requestId)}/>
                }
            </div>
        );
    };

    const requestsTableHeader = (
        <div className="table-header">
            List of filtered requests
            <div className="p-d-flex">
                <Button
                    type="button"
                    disabled={selectedRows.length === 0}
                    label="Approve all"
                    icon="pi pi-check"
                    className="p-button-primary p-mr-2"
                    onClick={() => approveAllCallback(selectedRows)}
                />
                <Button
                    type="button"
                    disabled={selectedRows.length === 0}
                    label="Reject all"
                    icon="pi pi-minus-circle"
                    className="p-button-secondary p-mr-2"
                    onClick={() => rejectAllCallback(selectedRows)}
                />
            </div>
        </div>
    );

    return (
        <div className="table-demo">
            <DataTable
                value={requests}
                paginator
                className="p-datatable-users p-datatable-gridlines p-datatable-striped"
                rows={50}
                dataKey="requestId"
                rowHover
                selection={selectedRows}
                onSelectionChange={(e) => setSelectedRows(e.value)}
                emptyMessage="No requests found"
                loading={loading}
                header={requestsTableHeader}
                onRowToggle={({ data }) => setExpandedRows(data)}
                onRowExpand={({ data }) => rowExpandCallback(data.userDeviceId)}
                expandedRows={expandedRows}
                rowExpansionTemplate={rowExpansionTemplate}
            >
                <Column expander headerStyle={{ width: '3rem' }}/>
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}/>
                <Column field="userDeviceId" header="UserDeviceId" body={bodyTemplate}/>
                <Column field="activeDevices" header="Email" sortable body={emailTemplate}/>
                <Column field="activeDevices" header="Name" sortable body={nameTemplate}/>
                <Column field="createdAt" header="Created at" sortable body={dateTemplate}/>
                <Column field="status" header="Status" sortable
                        headerStyle={{ width: '7rem', textAlign: 'center' }}
                        body={statusBodyTemplate}
                />
                <Column
                    header="Actions"
                    headerStyle={{ width: '8rem', textAlign: 'center' }}
                    bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                    body={actionTemplate}
                />
            </DataTable>
        </div>
    );
};

export default RequestsTable;
