import { DeviceRemovalRequestsStatuses, UserDeviceRemovalRequestModel } from "./models/user-device.models";
import { info } from "../../../common/lib/logging";
import { getDeviceRemovalRequests, getDevicesInfo, putDeviceRemovalRequests } from "./devices.api.actions";
import { handleError, turnOffGlobalSpinner, turnOnGlobalSpinner } from "../../notifications/notification.actions";
import { AppStore } from "../../../application/store/app.store";
import { findInString } from "../../../common/lib/misc";

export const REMOVE_DEVICE_NOTE_TITLE = "User devices requests handler";

export const getRemovalRequestsAction = async (
    appStore: AppStore,
    setLoading?: (flag: boolean) => void,
    startPageNumber: number = 0,
    statuses: DeviceRemovalRequestsStatuses[] = [ "OPEN" ],
    pageSize = 100,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    let pageNumber = startPageNumber;
    let result: UserDeviceRemovalRequestModel[] = [];

    try {
        info(`[getRemovalRequestsAction] start get page ${pageNumber}`);

        let data = await getDeviceRemovalRequests(authStore, pageNumber, pageSize, statuses);
        while (data.length > 0) {
            result.push(...data);
            pageNumber++;

            info(`[getRemovalRequestsAction] start get page ${pageNumber}`);
            data = await getDeviceRemovalRequests(authStore, pageNumber, 100, statuses);
        }
    } catch (error) {
        handleError(notificationStore, error, REMOVE_DEVICE_NOTE_TITLE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }

    return result;
}

export const searchRemovalRequestsAction = async (
    appStore: AppStore,
    searchString: string,
    setLoading?: (flag: boolean) => void,
    statuses: DeviceRemovalRequestsStatuses[] = [ "OPEN" ]
) => {
    info("[searchRemovalRequestsAction] start");

    let data: UserDeviceRemovalRequestModel[];

    data = await getRemovalRequestsAction(appStore, setLoading, 0, statuses);
    const normSearchString = searchString.toLowerCase();

    return data.filter(r =>
        findInString(r.userDeviceId, normSearchString) ||
        r.activeDevices?.some(d =>
            findInString(d.userInfo.sub, normSearchString) ||
            findInString(d.userInfo.lkUserId.toString(), normSearchString) ||
            findInString(d.userInfo.displayName, normSearchString) ||
            findInString(d.userInfo.user_name, normSearchString) ||
            findInString(d.userInfo.email, normSearchString)
        )
    );
}

export const approveRequestDeleteAction = async (
    appStore: AppStore,
    requestId: string,
    requests: UserDeviceRemovalRequestModel[],
) => {
    info("[approveRequestDeleteAction] start");

    //filter all request by device id
    const approveRequest = requests.find(r => r.requestId === requestId);

    if (approveRequest && approveRequest.status === "OPEN") {
        const { notificationStore, authStore } = appStore;

        turnOnGlobalSpinner(notificationStore);

        const otherRequests = requests
            .filter(r => r.userDeviceId === approveRequest.userDeviceId && r.status === "OPEN")
            .filter(r => r.requestId !== requestId);

        try {
            // approve first request
            const result = await putDeviceRemovalRequests(authStore, approveRequest.requestId, "approve", `close by ${authStore.sub}`);

            if (result) {
                // reject others
                for (const r of otherRequests) {
                    await putDeviceRemovalRequests(authStore, r.requestId, "reject", `close auto by ${authStore.sub}`);
                }
                return true;
            }
            return false;
        } catch (error) {
            handleError(notificationStore, error, REMOVE_DEVICE_NOTE_TITLE, 1);
            return false;
        } finally {
            turnOffGlobalSpinner(notificationStore);
        }
    }
    return false;
}

export const approveAllRequestDeleteAction = async (
    appStore: AppStore,
    selectedRequests: UserDeviceRemovalRequestModel[],
    requests: UserDeviceRemovalRequestModel[],
) => {
    info("[approveAllRequestDeleteAction] start");

    //filter all request by status and remove doubles
    const openRequestToApprove = selectedRequests.filter(r => r.status === "OPEN");
    const uniqDeviceIds = Array.from(new Set(openRequestToApprove.map(r => r.userDeviceId)));

    const openUniqRequestToApprove = uniqDeviceIds.map(id => openRequestToApprove.find(r => r.userDeviceId === id));

    debugger;

    for (const requestToApproveElement of openUniqRequestToApprove) {
        if (requestToApproveElement) {
            const result = await approveRequestDeleteAction(appStore, requestToApproveElement.requestId, requests);

            if (!result) return false;
        }
    }

    return true;
}

export const rejectRequestDeleteAction = async (
    appStore: AppStore,
    requestId: string,
    requests: UserDeviceRemovalRequestModel[],
) => {
    info("[rejectRequestDeleteAction] start");

    //filter all request by device id
    const rejectRequest = requests.find(r => r.requestId === requestId);

    if (rejectRequest && rejectRequest.status === "OPEN") {
        const { notificationStore, authStore } = appStore;

        turnOnGlobalSpinner(notificationStore);

        const allRequests = requests.filter(r => r.userDeviceId === rejectRequest.userDeviceId && r.status === "OPEN");

        try {
            // reject all requests
            for (const r of allRequests) {
                await putDeviceRemovalRequests(authStore, r.requestId, "reject", `close by ${authStore.sub}`);
            }
            return true;
        } catch (error) {
            handleError(notificationStore, error, REMOVE_DEVICE_NOTE_TITLE, 1);
            return false;
        } finally {
            turnOffGlobalSpinner(notificationStore);
        }
    }

    return false;
}

export const rejectAllRequestDeleteAction = async (
    appStore: AppStore,
    selectedRequests: UserDeviceRemovalRequestModel[],
    requests: UserDeviceRemovalRequestModel[],
) => {
    info("[rejectAllRequestDeleteAction] start");

    //filter all request by status and remove doubles
    const openRequestToReject = selectedRequests.filter(r => r.status === "OPEN");

    debugger;

    for (const requestToApproveElement of openRequestToReject) {
        if (requestToApproveElement) {
            const result = await rejectRequestDeleteAction(appStore, requestToApproveElement.requestId, requests);
            if (!result) {
                return false;
            }
        }
    }

    return true;
}

export const getDevicesInfoAction = async (
    appStore: AppStore,
    userDeviceId: string,
) => {
    info("[getDevicesInfoAction] start");
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        const data = await getDevicesInfo(authStore, [ userDeviceId ]);
        if (data && data.length > 0) {
            return data[0];
        } else {
            return false;
        }
    } catch (error) {
        handleError(notificationStore, error, REMOVE_DEVICE_NOTE_TITLE, 1);
        return false;
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }
}
