import React, { FC, useContext, useState } from 'react';
import styles from './TmsTicketsCreate.module.scss';
import classNames from "classnames";

import { CoreApiEvent } from "../../../../core-api/models/events.models";

import { AppStoreContext } from "../../../../../application/store/context";

import { TicketModel, TicketModelTariffOptionsItem } from "../../models/ticket.model";
import { CreateTicketsPackData } from "../../tms.api.actions";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Checkbox } from "primereact/checkbox";
import { TmsTicketsSmartTable } from "../TmsTicketsSmartTable/TmsTicketsSmartTable";

import { TariffOptionsMultiSelect } from "../tms-tickets-list/TariffOptionsMultiSelect";
import { AdditionalParamsEditor } from "../tms-tickets-list/AdditionalParamsEditor";

import { createTicketsPackAction, getTicketsAction } from "../../tms.actions";


export const INPUT_COUNT_TICKETS_ID = 'inputCountTickets';
export const INPUT_LANDING_ID = 'inputLanding';
export const INPUT_COMMENT_ID = 'inputComment';
export const INPUT_PERMANENT_TARIFF_OPTIONS = 'cbPermanent';

export interface TmsTicketsCreatePackProps {
    events?: CoreApiEvent[];
}

export const TmsTicketsCreatePack: FC<TmsTicketsCreatePackProps> = (props) => {
    const {
        events,
    } = props;

    const appStore = useContext(AppStoreContext);

    const [newTicketsLoading, setNewTicketsLoading] = useState<boolean>(false);
    const [newTickets, setNewTickets] = useState<TicketModel[]>([]);
    const [selectedEventId, setSelectedEventId] = useState<number>();
    const [additionalParams, setAdditionalParams] = useState<{ key: string; value: string }[]>([]);
    const [tariffOptions, setTariffOptions] = useState<TicketModelTariffOptionsItem[]>([]);
    const [newTicketsCount, setNewTicketsCount] = useState<number>(0);
    const [landing, setLanding] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [permanentTariffOptions, setPermanentTariffOptions] = useState<boolean>(false);

    const resetAll = (withEventId: boolean = true) => {
        if (withEventId) {
            setSelectedEventId(undefined);
        }

        setAdditionalParams([]);
        setTariffOptions([]);
        setNewTicketsCount(0);
        setLanding('');
        setComment('');

        setPermanentTariffOptions(false);

        setNewTickets([]);
    }

    const createPack = async (eventId: number | undefined, data: CreateTicketsPackData) => {
        if (!eventId) {
            return;
        }

        setNewTicketsLoading(true);

        const creatingResult = await createTicketsPackAction(appStore, eventId, data);

        if (creatingResult) {
            const links = creatingResult.links;

            const ticketsResult = await getTicketsAction(appStore, eventId);

            resetAll(false);

            setNewTickets(ticketsResult?.filter(ticket => links.includes(ticket.link)) ?? []);

            setNewTicketsLoading(false);
        }
    }

    return (
        <div className={classNames(styles.createPackContainer)}>
            <Dropdown
                className={classNames(styles.eventsDropDown, 'p-mb-5')}
                value={selectedEventId}
                options={
                    events?.map(event => ({
                        value: event.id,
                        label: `${event.name.en} - ${event.id}`,
                    }))
                }
                filter
                filterBy={'label'}
                onChange={(e) => {
                    setSelectedEventId(e.value);
                    setNewTickets([]);
                }}
                placeholder="Select event"
            />

            <div className={classNames(styles.inputs)}>
                <div className="p-inputgroup p-mb-2">
                    <label aria-required={'true'} htmlFor={INPUT_COUNT_TICKETS_ID} className="p-inputgroup-addon">
                        Tickets count
                    </label>
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                        inputStyle={{
                            borderTopLeftRadius: 0,
                        }}
                        useGrouping={false}
                        inputId={INPUT_COUNT_TICKETS_ID}
                        value={newTicketsCount} onValueChange={(e) => setNewTicketsCount(e.value)} />
                </div>

                <div className="p-inputgroup p-mb-2">
                    <label aria-required={'true'} htmlFor={INPUT_LANDING_ID} className="p-inputgroup-addon">
                        Landing
                    </label>
                    <InputText
                        id={INPUT_LANDING_ID}
                        placeholder={'https://'}
                        value={landing} onChange={(e) => setLanding((e.target as any).value)} />
                </div>

                <div className="p-inputgroup p-mb-2">
                    <label htmlFor={INPUT_COMMENT_ID} className="p-inputgroup-addon">
                        Comment
                    </label>
                    <InputTextarea
                        id={INPUT_COMMENT_ID}
                        rows={5} value={comment} onChange={(e) => setComment((e.target as any).value)} />
                </div>
            </div>

            <div className={classNames(styles.tariffOptions, 'p-mt-5')}>
                <div className={'p-d-flex'}>
                    <label aria-required={'true'}>Tariff options:&nbsp;</label>

                    <Checkbox
                        className={'p-ml-auto p-mr-2'}
                        inputId={INPUT_PERMANENT_TARIFF_OPTIONS}
                        checked={permanentTariffOptions}
                        onChange={e => setPermanentTariffOptions(e.checked)}
                    />
                    <label
                        style={{ cursor: 'pointer' }}
                        htmlFor={INPUT_PERMANENT_TARIFF_OPTIONS}>
                        Permanent (editing forbidden)
                    </label>
                </div>
                <TariffOptionsMultiSelect
                    defaultValue={tariffOptions ?? []}
                    optionLabel={'name'}
                    optionValue={'value'}
                    onChange={newValue => setTariffOptions(newValue)}
                    changeImmediately={true}
                />
            </div>

            <div className={classNames(styles.additionalParams, 'p-mt-5')}>
                Additional params:&nbsp;
                <AdditionalParamsEditor
                    additionalParams={additionalParams}
                    onChange={newParams => setAdditionalParams(newParams)}
                    changeImmediately={true}
                />
            </div>

            <hr />

            <div className={'p-mt-5 p-d-flex'}>
                <Button
                    disabled={landing === '' || newTicketsCount <= 0 || !selectedEventId || !tariffOptions?.length}
                    label={'Create tickets pack'}
                    icon={'pi pi-check'}
                    className={classNames('p-button-success')}

                    onClick={async () => {
                        const body = {
                            tariffOptions,
                            permanentTariffOptions,
                            count: newTicketsCount,
                            landingPage: landing,
                            comment,
                            additionalParams,
                        };

                        await createPack(selectedEventId, body);
                    }}
                />

                <Button
                    label={'Reset all'}
                    className={'p-button-secondary p-ml-2'}
                    onClick={() => resetAll()}
                />
            </div>

            {
                (newTickets.length > 0 || newTicketsLoading) &&
                <>
                    {
                        newTicketsLoading
                            ? <ProgressSpinner />
                            : (
                                <div>
                                    <TmsTicketsSmartTable
                                        defaultTickets={newTickets}
                                        defaultPage={0}
                                        defaultTotalTicketsCount={newTickets.length}
                                        useOnePage={true}
                                    />
                                </div>
                            )
                    }
                </>
            }
        </div>
    )
}
