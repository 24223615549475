import { config } from "../../../config";

export const sendDeviceRemovingRequestUrl = (deviceId: string, comment?: string) => `${config.liveBackendBasePath}/drm/user_devices/${deviceId}/removal_request${comment ? `?comment=${comment}` : ''}`;

export const getDeviceRemovalRequestsUrl = (pageNum: number, pageSize: number, statuses: string[]) => {
    let statusesString = '';

    if (statuses.length > 0) {
        statuses.forEach(status => {
            statusesString += `&status=${status}`;
        });
    }

    return `${config.liveBackendBasePath}/admin/drm/user_device_removal_requests?pageNum=${pageNum}&pageSize=${pageSize}${statusesString}`;
};

export const putDeviceRemovalRequestsUrl = (requestId: string, action: 'approve' | 'reject', comment?: string) => (
    `${config.liveBackendBasePath}/admin/drm/user_device_removal_requests/${requestId}/${action}${comment ? `?resolutionComment=${comment}` : ''}`
);

export const getDevicesInfoUrl = (ids: string[]) => `${config.liveBackendBasePath}/visitors/devices?visitorId=${ids.join(',')}`;

export const devicesUrl = `${config.liveBackendBasePath}/drm/user_devices`;
