const breadcrumbs =
    (
        [
            {
                parent: "Users", child: "List"
            },
            {
                parent: "Users", child: "Fingerprints"
            },
            {
                parent: "Users", child: "Details"
            }
        ]
    );

export default breadcrumbs;
