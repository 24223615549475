import { History } from "history";
import { MenuLinkItem } from "../../menu";
import { AuthStore } from "../auth/auth.store";
import { tmsRights } from "./rights";
import { rightsWrapperFunction } from "../auth/components/rightsWrapperFunction";
import { onlyForAdmin } from "../../common/rights.consts";
import { EVENT_PAGE_ROUTE, EVENTS_MENU_NAME } from "../events/routes";

export const TMS_MENU_LABEL = "TMS Management";
export const TMS_MENU = "tms";
export const TMS_MANAGEMENT_PAGE_ROUTE = `${EVENT_PAGE_ROUTE}-${TMS_MENU}-management`;
export const TMS_TICKETS_LIST_PAGE_ROUTE = `${EVENT_PAGE_ROUTE}-${TMS_MENU}-tickets`;
export const TMS_USERS_PAGE_ROUTE = `${EVENT_PAGE_ROUTE}-${TMS_MENU}-users`;
export const TMS_USER_PROFILE_PAGE_ROUTE = `${EVENT_PAGE_ROUTE}-${TMS_MENU}-user`;

export const topMenuRoutes = (authStore: AuthStore, history: History) => {
    const data = (
        [
            rightsWrapperFunction(authStore, onlyForAdmin, {
                label: EVENTS_MENU_NAME,
                items: [
                    [
                        rightsWrapperFunction(authStore, onlyForAdmin, {
                            label: TMS_MENU_LABEL,
                            items: [
                                rightsWrapperFunction(authStore, onlyForAdmin, {
                                    label: 'TMS create tickets', icon: 'pi pi-fw pi-ticket', command: () => {
                                        history.push(TMS_MANAGEMENT_PAGE_ROUTE)
                                    }
                                }),
                                rightsWrapperFunction(authStore, onlyForAdmin, {
                                    label: 'TMS tickets list', icon: 'pi pi-fw pi-ticket', command: () => {
                                        history.push(TMS_TICKETS_LIST_PAGE_ROUTE)
                                    }
                                }),
                                rightsWrapperFunction(authStore, onlyForAdmin, {
                                    label: 'TMS users', icon: 'pi pi-fw pi-users', command: () => {
                                        history.push(TMS_USERS_PAGE_ROUTE)
                                    }
                                }),
                            ]
                        }),
                    ],
                ],
            }),
        ]
    );

    return rightsWrapperFunction(authStore, tmsRights, data);
}

export const menuRoutes = (authStore: AuthStore): MenuLinkItem[] => {
    const data = (
        [
            {
                label: EVENTS_MENU_NAME,
                icon: 'pi pi-fw pi-users',
                items:
                    [
                        rightsWrapperFunction(authStore, onlyForAdmin, {
                            label: 'TMS create tickets', icon: 'pi pi-fw pi-ticket', to: TMS_MANAGEMENT_PAGE_ROUTE
                        }),
                        rightsWrapperFunction(authStore, onlyForAdmin, {
                            label: 'TMS tickets list', icon: 'pi pi-fw pi-ticket', to: TMS_TICKETS_LIST_PAGE_ROUTE

                        }),
                        rightsWrapperFunction(authStore, onlyForAdmin, {
                            label: 'TMS users', icon: 'pi pi-fw pi-users', to: TMS_USERS_PAGE_ROUTE
                        }),
                    ]
            },
        ]
    );

    return rightsWrapperFunction(authStore, tmsRights, data);
}

export const buildTmsUserProfileRouter = (id: number) => `${TMS_USER_PROFILE_PAGE_ROUTE}-${id}`;
