import React, { FC, useState } from 'react';
import styles from './TmsUsersList.module.scss';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import { AppStore } from "../../../../../application/store/app.store";

import { TmsUserModel, USERS_LIST_LIMIT } from "../../models/user.model";
import { TicketModel } from "../../models/ticket.model";

import { info } from "../../../../../common/lib/logging";

import { bodyTemplate, dateTemplate } from "../../../../../common/components/tables";

import { buildTmsUserProfileRouter } from "../../../routes";
import { getTicketsByUserIdAction } from "../../tms.actions";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { TmsTicketsSmartTable } from "../TmsTicketsSmartTable/TmsTicketsSmartTable";


const tableHeader = (
    <div className="table-header">
        List of tms-users
        <div className="p-d-flex">
        </div>
    </div>
);

const anonymousTemplate = (data: TmsUserModel) => {
    return (
        <span className={`request-badge anonymous-${data.isAnonymous ? 'true' : 'false'}`}>{data.isAnonymous ? "YES" : "NO"}</span>
    )
};

export interface TmsUsersTableProps {
    users: TmsUserModel[];
    page: number;
    totalUsersCount: number;
    setPage: (p: number) => void;
    loading: boolean;
    appStore: AppStore;
}

export const TmsUsersTable: FC<TmsUsersTableProps> = (props) => {
    const {
        users,
        totalUsersCount,
        setPage,
        page,
        loading,
        appStore,
    } = props;

    const [expandedRows, setExpandedRows] = useState<any[]>();

    const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
    const [userTickets, setUserTickets] = useState<TicketModel[]>([]);

    const onPage = (e: { first: number, rows: number, page: number, pageCount: number }) => {
        info("onPage", e);
        setPage(e.page);
    };

    const actionTemplate = (data: TmsUserModel) => {
        return (
            <div className="p-d-flex p-flex-column">
                <Link to={buildTmsUserProfileRouter(data.id)} target={'_blank'}>
                    <Button
                        label="Edit"
                        icon="pi pi-pencil"
                        className="p-button-primary"
                    />
                </Link>
            </div>
        );
    }

    const rowExpansionTemplate = (data: TmsUserModel) => {
        return (
            <div>
                {
                    ticketsLoading &&
                    <ProgressSpinner />
                }

                {
                    !ticketsLoading &&
                    <TmsTicketsSmartTable
                        defaultTickets={userTickets}
                        defaultPage={0}
                        defaultTotalTicketsCount={userTickets.length}
                        useOnePage={true}
                        loading={ticketsLoading}
                    />
                }
            </div>
        )
    }

    return (
        <div className={classNames(
            'table-demo',
            styles.tmsUsersTableContainer,
        )}>
            <DataTable
                className={classNames(
                    'p-datatable-users',
                    'p-datatable-gridlines',
                    'p-datatable-striped',
                )}
                value={users}
                paginator
                lazy
                onPage={onPage}
                first={page * USERS_LIST_LIMIT}
                rows={USERS_LIST_LIMIT}
                totalRecords={totalUsersCount}
                rowHover
                emptyMessage="No users found"
                loading={loading}
                header={tableHeader}
                expandedRows={expandedRows}
                onRowToggle={async (e) => {
                    const data = e.data.length === 0
                        ? []
                        : [e.data[e.data.length - 1]];

                    setExpandedRows(data);

                    setTicketsLoading(true);

                    if (data.length > 0) {
                        setUserTickets(
                            await getTicketsByUserIdAction(appStore, data[0].id) ?? []
                        );

                        setTicketsLoading(false);
                    } else {
                        setUserTickets([]);
                        setTicketsLoading(false);
                    }
                }}
                rowExpansionTemplate={rowExpansionTemplate}
            >
                <Column expander headerStyle={{ width: '3.5rem' }}/>

                <Column field={'id'} header={'Profile Id'} body={bodyTemplate} headerStyle={{ width: '7rem' }} />
                <Column field={'created'} header={'Created Date'} body={dateTemplate} headerStyle={{ width: '12rem' }} />

                <Column field={'name'} header={'Name'} body={bodyTemplate} />
                <Column field={'surname'} header={'Surname'} body={bodyTemplate} />
                <Column field={'email'} header={'Email'} body={bodyTemplate} headerStyle={{ width: '20rem' }} />
                <Column field={'phone'} header={'Phone'} body={bodyTemplate} headerStyle={{ width: '12rem' }} />
                <Column field={'linkedLkUserId'} header={'Linked LkUserId'} body={bodyTemplate} headerStyle={{ width: '10rem' }} />
                <Column field={'isAnonymous'} header={'IsAnonymous'} body={anonymousTemplate} headerStyle={{ width: '8rem' }} />

                <Column body={actionTemplate} headerStyle={{ width: '6.4rem' }} />
            </DataTable>
        </div>
    )
}
