import { EVENTS_MENU_NAME } from "../events/routes";
import { TMS_MENU } from "./routes";
import { BreadcrumbRoute } from "../../breadcrumbs";

const breadcrumbs: BreadcrumbRoute[] =
    (
        [
            {
                parent: EVENTS_MENU_NAME, subparent: TMS_MENU, child: "Tickets"
            },
            {
                parent: EVENTS_MENU_NAME, subparent: TMS_MENU, child: "Management"
            },
            {
                parent: EVENTS_MENU_NAME, subparent: TMS_MENU, child: "Users"
            }
        ]
    );

export default breadcrumbs;
