import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { BreadcrumbRoute } from "../../breadcrumbs";

interface AppBreadcrumbProp {
    routes: BreadcrumbRoute[];
}

const AppBreadcrumb: FC<AppBreadcrumbProp> = (props) => {
    const location = useLocation();

    const pathname = location.pathname === "/" ? [ "", "" ] : location.pathname.toLowerCase().split("-");

    const activeRoute = props.routes.filter((route) => {
        const parent = route.parent.replace(/\s/g, "").toLowerCase();
        const subparent = route.subparent?.replace(/\s/g, "").toLowerCase();
        const child = route.child.replace(/\s/g, "").toLowerCase();

        if (pathname.length === 3) {
            return parent === pathname[0].replace("/", "") && subparent === pathname[1] && child === pathname[2];
        } else if (pathname.length === 2) {
            return parent === pathname[0].replace("/", "") && child === pathname[1];
        } else {
            return parent === pathname[0].replace("/", "") && child === pathname[1];
        }
    });

    let model;

    if (!activeRoute.length) {
        model = [ { label: "" } ];
    } else {
        if (activeRoute[0].parent === "" && activeRoute[0].child === "") {
            model = [ { label: "Dashboard" } ];
        } else {
            model = [ { label: activeRoute[0].parent } ];
            if (activeRoute[0].subparent !== undefined) {
                model = [ ...model, { label: activeRoute[0].subparent.toUpperCase() } ]
            }
            model = [ ...model, { label: activeRoute[0].child } ]
        }
    }

    const home = { icon: "pi pi-home", url: "/" };

    return (
        <div className="layout-breadcrumb-container p-d-flex p-jc-between p-ai-center p-shadow-1">
            <BreadCrumb model={model} home={home} className="layout-breadcrumb p-pl-4 p-py-2"/>
        </div>
    );

}

export default AppBreadcrumb;
