import { AppStore } from "../../../application/store/app.store";
import { handleError, turnOffGlobalSpinner, turnOnGlobalSpinner } from "../../notifications/notification.actions";
import { info } from "../../../common/lib/logging";
import { createTicket, CreateTicketData, createTicketsPack, CreateTicketsPackData, getEventsWithTicketsIds, getTickets, getTicketsByUserId, getUsers, TMSUpdateProfileType, TMSUpdateTicketType, updateProfileByMagicCode, updateTicket, validateMagicCode } from "./tms.api.actions";
import { TicketModel } from "./models/ticket.model";

export const TMS_HANDLER = "TMS service requests handler";
export const TMS_UPDATE = "TMS service update request";
export const TMS_CREATE_TICKETS_PACK = "TMS service create tickets pack request";
export const TMS_CREATE_TICKET = "TMS service create ticket request";

export const getTicketsAction = async (
    appStore: AppStore,
    eventId: number,
    setLoading?: (flag: boolean) => void,
    setTotal?: (value: number) => void,
    enabled?: boolean,
    skip: number = 0,
    limit: number = 10000,
    sortBy?: 'created',
    sortDirection?: 'desc' | 'asc',
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getTickets] start get tickets`, { enabled, skip, limit, sortBy, sortDirection });

        const ticketsResult = await getTickets(authStore, eventId, enabled, skip, limit, sortBy, sortDirection);

        setTotal?.(ticketsResult.total);

        return ticketsResult.tickets.map(ticket => {
            ticket.created = new Date(ticket.created);

            return ticket;
        });
    } catch (error) {
        handleError(notificationStore, error, TMS_HANDLER, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const updateTicketAction = async (
    appStore: AppStore,
    magicCode: string,
    data: TMSUpdateTicketType,
): Promise<TicketModel | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        return await updateTicket(authStore, magicCode, data);
    } catch (error) {
        handleError(notificationStore, error, TMS_UPDATE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }
}

export const getEventsWithTicketsIdsAction = async (
    appStore: AppStore,
    setLoading?: (flag: boolean) => void,
): Promise<number[] | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getEventsWithTickets] start get events ids`);

        return getEventsWithTicketsIds(authStore);
    } catch (error) {
        handleError(notificationStore, error, TMS_HANDLER, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const createTicketsPackAction = async (
    appStore: AppStore,
    eventId: number,
    data: CreateTicketsPackData,
    setLoading?: (flag: boolean) => void,
): Promise<{ count: number; links: string[] } | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[createTicketsPackAction] start create tickets pack`);

        return await createTicketsPack(authStore, eventId, data);
    } catch (error) {
        handleError(notificationStore, error, TMS_CREATE_TICKETS_PACK, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const createTicketAction = async (
    appStore: AppStore,
    eventId: number,
    data: CreateTicketData,
    setLoading?: (flag: boolean) => void,
): Promise<{ count: number; links: string[] } | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[createTicketAction] start create ticket`);

        return await createTicket(authStore, eventId, data);
    } catch (error) {
        handleError(notificationStore, error, TMS_CREATE_TICKET, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const validateMagicCodeAction = async (
    appStore: AppStore,
    magicCode: string,
): Promise<boolean> => {
    const { notificationStore, authStore } = appStore;

    let result = false;

    try {
        result = await validateMagicCode(authStore, magicCode);
    } catch (error) {
        handleError(notificationStore, error, TMS_HANDLER, 1);
        console.error('MagicCode validate error', { magicCode, error });
    }

    return result;
}

export const getUsersAction = async (
    appStore: AppStore,
    setLoading?: (flag: boolean) => void,
    setTotal?: (value: number) => void,
    search: { searchUserId?: number; searchEmail?: string; searchFio?: string; } = {},
    skip: number = 0,
    limit: number = 10000,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getUsers] start get users`, { search, skip, limit });

        const usersResult = await getUsers(authStore, search, skip, limit);

        setTotal?.(usersResult.total);

        return usersResult.users.map(user => {
            user.created = new Date(user.created);
            user.updated = user.updated && new Date(user.updated);

            return user;
        });
    } catch (error) {
        handleError(notificationStore, error, TMS_HANDLER, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const getTicketsByUserIdAction = async (
    appStore: AppStore,
    searchUserId: number,
    setLoading?: (flag: boolean) => void,
    setTotal?: (value: number) => void,
) => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);
    setLoading && setLoading(true);

    try {
        info(`[getTicketsByUserId] start get tickets`, { searchUserId });

        const ticketsResult = await getTicketsByUserId(authStore, searchUserId);

        setTotal?.(ticketsResult.total);

        return ticketsResult.tickets.map(ticket => {
            ticket.created = new Date(ticket.created);

            return ticket;
        });
    } catch (error) {
        handleError(notificationStore, error, TMS_HANDLER, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
        setLoading && setLoading(false);
    }
}

export const updateProfileAction = async (
    appStore: AppStore,
    magicCode: string,
    data: TMSUpdateProfileType,
): Promise<TicketModel | undefined> => {
    const { notificationStore, authStore } = appStore;

    turnOnGlobalSpinner(notificationStore);

    try {
        return await updateProfileByMagicCode(authStore, magicCode, data);
    } catch (error) {
        handleError(notificationStore, error, TMS_UPDATE, 1);
    } finally {
        turnOffGlobalSpinner(notificationStore);
    }
}
