export const onlyUnique = (value: any, index: number, self: any): boolean => self.indexOf(value) === index;

export const findInString = (source: string, desc: string): boolean => {
    return source.toLowerCase().indexOf(desc) > -1;
}

export const isNumeric = (str: any) => {
    if (typeof str !== "string") {
        return false
    } // we only process strings!

    return !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
};

export type KeyValuePair<T, K> = {
    key: T,
    value: K;
}

export const groupByField = <T>(fieldName: string, data: T[]): KeyValuePair<string, T[]>[] => {
    return data.reduce((acc: KeyValuePair<string, T[]>[], current: any) => {
        let entity = acc.find(e => e.key === current[fieldName]);
        if (entity === undefined) {
            entity = {
                key: current[fieldName],
                value: []
            };
            acc.push(entity);
        }

        // @ts-ignore
        entity.value.push(current);

        return acc;
    }, []);
}

export const countByField = <T, K>(fieldName: string, data: T[], groupFn: (value: K) => any): KeyValuePair<string, number>[] => {
    // reduce array to crop data
    const cropData = data.map((d: any) => {
        return ({
            group: groupFn(d[fieldName]) as string
        });
    });

    return cropData.reduce((acc: KeyValuePair<string, number>[], current: any) => {
        let entity = acc.find(e => e.key === current.group);
        if (entity === undefined) {
            entity = {
                key: current.group,
                value: cropData.filter(c => c.group === current.group).length
            };
            acc.push(entity);
        }
        return acc;
    }, []);
}
