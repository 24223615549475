import { History } from "history";
import { MenuLinkItem } from "../../menu";
import { AuthStore } from "../auth/auth.store";
import { userRights } from "./rights";
import { rightsWrapperFunction } from "../auth/components/rightsWrapperFunction";
import { onlyOnlineAdmin } from "../../common/rights.consts";

export const NAME = "Users";
export const USER_FINGERPRINT_PAGE_ROUTE = "/users-fingerprints";
export const USER_LIST_PAGE_ROUTE = "/users-list";
export const USER_DETAILS_PAGE_ROUTE = "/users-details";

export const topMenuRoutes = (authStore: AuthStore, history: History) => {
    const data = (
        [
            {
                label: NAME,
                items: [
                    [
                        {
                            label: 'Users lists',
                            items: [
                                {
                                    label: 'Users lists', icon: 'pi pi-fw pi-users', command: () => {
                                        history.push(USER_LIST_PAGE_ROUTE)
                                    }
                                },
                            ]
                        },
                    ],
                    [
                        rightsWrapperFunction(authStore, onlyOnlineAdmin, {
                            label: 'Online users actions',
                            items: [
                                {
                                    label: 'User devices requests', icon: 'pi pi-fw pi-desktop', command: () => {
                                        history.push(USER_FINGERPRINT_PAGE_ROUTE)
                                    }
                                },
                            ]
                        }),
                    ]
                ],
            },
        ]
    );

    return rightsWrapperFunction(authStore, userRights, data);
}

export const menuRoutes = (authStore: AuthStore): MenuLinkItem[] => {
    const data = (
        [
            {
                label: NAME,
                icon: 'pi pi-fw pi-users',
                items:
                    [
                        {
                            label: 'Users list', icon: 'pi pi-fw pi-users', to: USER_LIST_PAGE_ROUTE
                        },
                        rightsWrapperFunction(authStore, onlyOnlineAdmin,
                            { label: 'User devices requests', icon: 'pi pi-fw pi-desktop', to: USER_FINGERPRINT_PAGE_ROUTE },
                        )
                    ]
            },
        ]
    );

    return rightsWrapperFunction(authStore, userRights, data);
}

export const buildUserRouter = (id: number) => `${USER_DETAILS_PAGE_ROUTE}-${id}`;
