import React, { useContext, useEffect, useState } from "react";
import styles from "../tms-tickets-list/TmsTicketsList.module.scss";
import classNames from "classnames";

import { observer } from "mobx-react-lite";

import { AppStoreContext } from "../../../../../application/store/context";

import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyForAdmin } from "../../../../../common/rights.consts";

import { getEventsAction } from "../../../../core-api/core.actions";

import { ProgressSpinner } from "primereact/progressspinner";
import { TabPanel, TabView } from "primereact/tabview";

import AppPage from "../../../../../application/components/AppPage";
import { TmsTicketsCreatePack } from "./TmsTicketsCreatePack";
import { TmsTicketsCreateOne } from "./TmsTicketsCreateOne";


const TmsTicketsCreatingPage = () => {
    const appStore = useContext(AppStoreContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    useEffect(() => {
        getEventsAction(appStore, setLoading);
    }, [appStore]);

    return (
        <RightsWrapper rights={onlyForAdmin}>
            <AppPage
                isCard
                header="Create ticket or pack"
                subheader="You can create a detailed ticket or pack of light-tickets."
                icon="pi-ticket"
                // headerComponent={}
            >
                {
                    loading &&
                    <div className={classNames(styles.spinnerContainer)}>
                        <ProgressSpinner />
                    </div>
                }

                {
                    !loading &&
                    <div style={{ width: '100%', }}>
                        <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                            <TabPanel header="Create pack">
                                <TmsTicketsCreatePack
                                    events={appStore.coreStore.eventLists}
                                />
                            </TabPanel>
                            <TabPanel header="Create one">
                                <TmsTicketsCreateOne
                                    events={appStore.coreStore.eventLists}
                                />
                            </TabPanel>
                        </TabView>
                    </div>
                }
            </AppPage>
        </RightsWrapper>
    );
};

export default observer(TmsTicketsCreatingPage);
