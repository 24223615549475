import React, { FC, useContext, useEffect, useState } from 'react';
import AppCard from "../../../../common/components/cards/AppCard";
import { AppStoreContext } from "../../../../application/store/context";
import { getRemovalRequestsAction } from "../devices.actions";
import { observer } from "mobx-react-lite";
import { UserDeviceRemovalRequestModel } from "../models/user-device.models";
import { LineChartModel } from "../../../../common/components/charts/charts.model";
import { clearLineChartOptions } from "../../../../common/components/charts/charts.options";
import { getChartDataByRequests } from "../charts.actions";
import RightsWrapper from "../../../auth/components/RightsWrapper";
import { onlyOnlineAdmin } from "../../../../common/rights.consts";
import { rightsWrapperFunction } from "../../../auth/components/rightsWrapperFunction";

interface OpenRequestsCardProps {
    requestsToShow?: UserDeviceRemovalRequestModel[];
    title?: string;
    mainActionCallback?: () => void;
    mainActionIcon?: string;
    sizeClass?: string;
}

const OpenRequestsCard: FC<OpenRequestsCardProps> = ({
                                                         requestsToShow,
                                                         mainActionCallback,
                                                         mainActionIcon,
                                                         title,
                                                         sizeClass
                                                     }) => {
    const appStore = useContext(AppStoreContext);

    const [ requests, setRequests ] = useState<UserDeviceRemovalRequestModel[] | undefined>(requestsToShow);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ chartData, setChartData ] = useState<LineChartModel>(getChartDataByRequests(requestsToShow || []));

    useEffect(() => {
            if (rightsWrapperFunction(appStore.authStore, onlyOnlineAdmin, {}) === null) return;

            if (requestsToShow === undefined) {
                setIsLoading(true);
                getRemovalRequestsAction(appStore, undefined, 0, [ "OPEN" ])
                    .then(response => {
                        if (response) {
                            setTimeout(() => {
                                setRequests(response);
                                setChartData(getChartDataByRequests(response));
                            }, 300);
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            setIsLoading(false);
                        }, 300);
                    });
            } else {
                setRequests(requestsToShow);
                setChartData(getChartDataByRequests(requestsToShow));
            }
        }, [ appStore, requestsToShow ]
    );

    return (
        <RightsWrapper hideWrapper rights={onlyOnlineAdmin}>
            <AppCard title={title ?? "Selected requests"}
                     isLoading={isLoading}
                     icon="pi-sort-alt"
                     counters={[
                         {
                             counter: requests?.length.toString(),
                             title: "Open requests"
                         }
                     ]}
                     mainActionCallback={mainActionCallback}
                     mainActionIcon={mainActionIcon}
                     className="cyan-bgcolor solid-surface-text-color"
                     chartLineData={chartData}
                     chartLineOptions={clearLineChartOptions}
                     sizeClass={sizeClass ?? "p-md-6 p-lg-6 p-xl-6"}
            />
        </RightsWrapper>
    );
}

export default observer(OpenRequestsCard);
