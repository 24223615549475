import React, { useContext, useEffect, useState } from "react";
import styles from "./TmsUsersList.module.scss";
import classNames from "classnames";

import { observer } from "mobx-react-lite";

import { AppStoreContext } from "../../../../../application/store/context";
import RightsWrapper from "../../../../auth/components/RightsWrapper";
import { onlyForAdmin } from "../../../../../common/rights.consts";

import AppPage from "../../../../../application/components/AppPage";
import { TmsUsersTable } from "./TmsUsersTable";

import { TmsUserModel, USERS_LIST_LIMIT } from "../../models/user.model";

import { getUsersAction } from "../../tms.actions";

import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";


const MIN_USER_ID_VALUE = 30_000_000;

const INPUT_USER_ID_ID = 'inputUserId';
const INPUT_EMAIL_ID = 'inputEmail';
const INPUT_FIO_ID = 'inputFio';

const TmsUsersListPage = () => {
    const appStore = useContext(AppStoreContext);

    const [page, setPage] = useState<number>(0);
    const [usersLoading, setUsersLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<TmsUserModel[]>();
    const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
    const [filterSearchUserId, setFilterSearchUserId] = useState<number>();
    const [filterSearchEmail, setFilterSearchEmail] = useState<string>('');
    const [filterSearchFio, setFilterSearchFio] = useState<string>('');

    const refreshUsersRequest = (
        searchUserId: number | undefined,
        searchEmail: string | undefined,
        searchFio: string | undefined,
        skip: number = 0,
        limit: number = USERS_LIST_LIMIT,
    ) => {
        setUsersLoading(true);

        const email = searchEmail === '' ? undefined : searchEmail ? encodeURIComponent(searchEmail) : undefined;
        const fio = searchFio === '' ? undefined : searchFio ? encodeURIComponent(searchFio) : undefined;

        getUsersAction(
            appStore,
            setUsersLoading,
            setTotalUsersCount,
            { searchUserId, searchEmail: email, searchFio: fio },
            skip,
            limit,
        )
            .then(result => setUsers(result));
    }

    useEffect(() => {
        if (!appStore) {
            return;
        }

        refreshUsersRequest(
            filterSearchUserId,
            filterSearchEmail,
            filterSearchFio,
        )
    }, [appStore]);

    return (
        <RightsWrapper rights={onlyForAdmin}>
            <AppPage
                isCard
                header="TMS users"
                subheader="You can view and edit users, also you can manage users tickets"
                icon="pi-users"
                // headerComponent={}
            >
                <div
                    style={{ width: '100%' }}
                >
                    <div
                        className={'p-d-flex p-flex-row'}
                        style={{ width: '100%' }}
                    >
                        <div className={classNames(styles.filterInputs)}>
                            <div className="p-inputgroup p-mb-2">
                                <label htmlFor={INPUT_USER_ID_ID} className="p-inputgroup-addon">
                                    Profile id
                                </label>
                                <InputNumber
                                    inputId={INPUT_USER_ID_ID}
                                    style={{
                                        width: '100%',
                                    }}
                                    inputStyle={{
                                        borderTopLeftRadius: 0,
                                    }}
                                    placeholder={`${MIN_USER_ID_VALUE}+`}
                                    useGrouping={false}
                                    value={filterSearchUserId} onValueChange={(e) => setFilterSearchUserId(e.value)}
                                    onKeyDown={(e: any) => {
                                        if (e.key !== 'Enter') {
                                            return;
                                        }

                                        refreshUsersRequest(
                                            filterSearchUserId,
                                            filterSearchEmail,
                                            filterSearchFio,
                                        );

                                        setPage(0);
                                    }}
                                />
                            </div>

                            <div className="p-inputgroup p-mb-2">
                                <label htmlFor={INPUT_EMAIL_ID} className="p-inputgroup-addon">
                                    Email
                                </label>
                                <InputText
                                    id={INPUT_EMAIL_ID}
                                    placeholder={'email@email.com'}
                                    value={filterSearchEmail} onChange={(e) => setFilterSearchEmail((e.target as any).value)}
                                    onKeyDown={(e: any) => {
                                        if (e.key !== 'Enter') {
                                            return;
                                        }

                                        refreshUsersRequest(
                                            filterSearchUserId,
                                            filterSearchEmail,
                                            filterSearchFio,
                                        );

                                        setPage(0);
                                    }}
                                />
                            </div>

                            <div className="p-inputgroup p-mb-2">
                                <label htmlFor={INPUT_FIO_ID} className="p-inputgroup-addon">
                                    Name / surname
                                </label>
                                <InputText
                                    id={INPUT_FIO_ID}
                                    placeholder={'Jon Snow'}
                                    value={filterSearchFio} onChange={(e) => setFilterSearchFio((e.target as any).value)}
                                    onKeyDown={(e: any) => {
                                        if (e.key !== 'Enter') {
                                            return;
                                        }

                                        refreshUsersRequest(
                                            filterSearchUserId,
                                            filterSearchEmail,
                                            filterSearchFio,
                                        );

                                        setPage(0);
                                    }}
                                />
                            </div>
                        </div>

                        <Button
                            label="Search"
                            icon="pi pi-search"
                            className="p-button-primary p-ml-2"
                            onClick={() => {
                                refreshUsersRequest(
                                    filterSearchUserId,
                                    filterSearchEmail,
                                    filterSearchFio,
                                );

                                setPage(0);
                            }}
                        />

                        <Button
                            label="Clear"
                            icon="pi pi-times"
                            className="p-button-secondary p-ml-2"
                            onClick={() => {
                                setFilterSearchUserId(undefined);
                                setFilterSearchEmail('');
                                setFilterSearchFio('');

                                setPage(0);

                                refreshUsersRequest(undefined, '', '');
                            }}
                        />
                    </div>

                    {
                        usersLoading &&
                        <div className={classNames(styles.spinnerContainer)}>
                            <ProgressSpinner />
                        </div>
                    }

                    {
                        !usersLoading &&
                        <div>
                            <TmsUsersTable
                                users={users ?? []}
                                totalUsersCount={totalUsersCount}
                                loading={usersLoading}
                                page={page}
                                setPage={(p) => {
                                    setPage(p);
                                    refreshUsersRequest(filterSearchUserId, filterSearchEmail, filterSearchFio, p * USERS_LIST_LIMIT);
                                }}
                                appStore={appStore}
                            />
                        </div>
                    }
                </div>
            </AppPage>
        </RightsWrapper>
    );
};

export default observer(TmsUsersListPage);
