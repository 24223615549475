import { MenuLinkItem } from "../../menu";

export const NAME = "Home";
export const DASHBOARD_ROUTE = "/";

export const menuRoutes: MenuLinkItem[] = (
    [
        {
            label: NAME,
            icon: 'pi pi-fw pi-home',
            items: [
                { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: DASHBOARD_ROUTE },
            ]
        }
    ]
);
