import { config } from "../../../config";


const createManyQueryParamsHelper = (params: { [key: string]: string | number | boolean | undefined }): string => {
    if (!params) {
        return '';
    }

    let result: string[] = [];

    Object.entries(params).forEach(([key, value]) => {
        if (key && value !== undefined) {
            result.push(`${key}=${value}`);
        }
    });

    return result.length > 0 ? `?${result.join('&')}` : '';
}

export const ticketsListUrl = (eventId: number, enabled?: boolean, skip: number = 0, limit: number = 100, sortBy?: 'created', sortDirection?: 'desc' | 'asc') => (
    `${config.tmsServiceBasePath}/admin/magic_code_tms/tickets/${eventId}${createManyQueryParamsHelper({ skip, limit, enabled, sortBy, sortDirection })}`
);
export const updateTicketByMagicCodeUrl = (magicCode: string) => `${config.tmsServiceBasePath}/admin/magic_code_tms/ticket/${magicCode}`;
export const eventsWithTicketsUrl = () => `${config.tmsServiceBasePath}/admin/magic_code_tms/events`;
export const createTicketsPackUrl = (eventId: number) => `${config.tmsServiceBasePath}/admin/magic_code_tms/tickets/${eventId}`;
export const createTicketUrl = (eventId: number) => `${config.tmsServiceBasePath}/admin/magic_code_tms/ticket/${eventId}`;
export const validateMagicCodeUrl = () => `${config.tmsServiceBasePath}/oauth/token`;
export const getUsersListUrl = (search: { searchUserId?: number; searchEmail?: string; searchFio?: string; } = {}, skip: number = 0, limit: number = 100) => (
    `${config.tmsServiceBasePath}/admin/magic_code_tms/users${createManyQueryParamsHelper({...search, skip, limit,})}`
);
export const ticketsListByUserIdUrl = (searchUserId: number) => `${config.tmsServiceBasePath}/admin/magic_code_tms/tickets/user/${searchUserId}`;
export const updateProfileByMagicCodeUrl = (magicCode: string) => `${config.tmsServiceBasePath}/admin/magic_code_tms/ticket/${magicCode}/light_profile`;
