import React, { KeyboardEvent, useContext, useEffect, useState } from "react";
import RightsWrapper from "../../../auth/components/RightsWrapper";
import { AppStoreContext } from "../../../../application/store/context";
import { observer } from "mobx-react-lite";
import AppPage from "../../../../application/components/AppPage";
import { DeviceRemovalRequestsStatuses, UserDeviceInfoModel, UserDeviceRemovalRequestModel } from "../models/user-device.models";
import RequestsTable from "./RequestsTable";
import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import { approveAllRequestDeleteAction, approveRequestDeleteAction, getDevicesInfoAction, getRemovalRequestsAction, rejectAllRequestDeleteAction, rejectRequestDeleteAction, REMOVE_DEVICE_NOTE_TITLE, searchRemovalRequestsAction } from "../devices.actions";
import OpenRequestsCard from "./OpenRequestsCard";
import { Button } from "primereact/button";
import { handleSuccessfully, handleWarning } from "../../../notifications/notification.actions";
import { onlyOnlineAdmin } from "../../../../common/rights.consts";

interface DeviceRemovalRequestsStatusesSelect {
    name: DeviceRemovalRequestsStatuses;
    code: string;
}

const allDeviceRemovalRequestsStatusesArray: DeviceRemovalRequestsStatusesSelect[] = [
    { name: "OPEN", code: "01" },
    { name: "APPROVED", code: "02" },
    { name: "REJECTED", code: "03" },
];

const initDeviceRemovalRequestsStatusesArray: DeviceRemovalRequestsStatusesSelect[] = [
    {
        name: "OPEN",
        code: "01"
    }
];

const UserFingerprintPage = () => {
    const appStore = useContext(AppStoreContext);

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ filteredRequests, setFilteredRequests ] = useState<UserDeviceRemovalRequestModel[]>([]);
    const [ devicesInfos, setDevicesInfos ] = useState<Map<string, UserDeviceInfoModel>>(new Map());

    const [ statusSelectValue, setStatusSelectValue ] = useState<DeviceRemovalRequestsStatusesSelect[]>(initDeviceRemovalRequestsStatusesArray);
    const [ globalFilter, setGlobalFilter ] = useState<string>("");

    // events
    const handleStatusSelectChange = (e: any) => {
        setStatusSelectValue(e.value);
    }

    const clearClickHandler = () => {
        setGlobalFilter("");
        setStatusSelectValue(initDeviceRemovalRequestsStatusesArray);
        refreshRequest(initDeviceRemovalRequestsStatusesArray);
    };

    // functions
    const getRemovalRequests = (pageNumber: number, statuses: ("OPEN" | "APPROVED" | "REJECTED")[]) => {
        getRemovalRequestsAction(appStore, setLoading, pageNumber, statuses)
            .then(response => {
                if (response) {
                    setFilteredRequests(response)
                }
            });
    };

    const refreshRequest = (statusesForce?: DeviceRemovalRequestsStatusesSelect[]) => {
        const statuses = (statusesForce ?? statusSelectValue).map(s => s.name);
        getRemovalRequests(0, statuses);
    }

    const searchClickHandler = () => {
        if (globalFilter) {
            const statuses = statusSelectValue.map(s => s.name);
            searchRemovalRequestsAction(appStore, globalFilter, setLoading, statuses)
                .then(response => {
                    if (response) {
                        setFilteredRequests(response)
                    }
                });
        }
    };

    const approveCallback = (requestId: string) => {
        if (requestId) {
            approveRequestDeleteAction(appStore, requestId, filteredRequests)
                .then(result => {
                    if (result) {
                        handleSuccessfully(appStore.notificationStore, "Delete request approved successfully", REMOVE_DEVICE_NOTE_TITLE, 1);
                        if (globalFilter) {
                            searchClickHandler();
                        } else {
                            refreshRequest();
                        }
                    } else {
                        handleWarning(appStore.notificationStore, "Delete request approving is not completed", REMOVE_DEVICE_NOTE_TITLE, 1);
                    }
                });
        }
    }

    const rejectCallback = (requestId: string) => {
        if (requestId) {
            rejectRequestDeleteAction(appStore, requestId, filteredRequests)
                .then(result => {
                    if (result) {
                        handleSuccessfully(appStore.notificationStore, "Delete request rejected successfully", REMOVE_DEVICE_NOTE_TITLE, 1);
                        if (globalFilter) {
                            searchClickHandler();
                        } else {
                            refreshRequest();
                        }
                    } else {
                        handleWarning(appStore.notificationStore, "Delete request rejecting is not completed", REMOVE_DEVICE_NOTE_TITLE, 1);
                    }
                });
        }
    }

    // effects
    // onChange selector
    useEffect(() => {
        if (globalFilter) {
            searchClickHandler();
        } else {
            refreshRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ statusSelectValue ]);

    const approveAllCallback = (selectedRequests: UserDeviceRemovalRequestModel[]) => {
        approveAllRequestDeleteAction(appStore, selectedRequests, filteredRequests)
            .then(result => {
                if (result) {
                    handleSuccessfully(appStore.notificationStore, "Delete request approved successfully", REMOVE_DEVICE_NOTE_TITLE, 1);
                    if (globalFilter) {
                        searchClickHandler();
                    } else {
                        refreshRequest();
                    }
                } else {
                    handleWarning(appStore.notificationStore, "Delete request approving is not completed", REMOVE_DEVICE_NOTE_TITLE, 1);
                }
            });
    };

    const rejectAllCallback = (selectedRequests: UserDeviceRemovalRequestModel[]) => {
        rejectAllRequestDeleteAction(appStore, selectedRequests, filteredRequests)
            .then(result => {
                if (result) {
                    handleSuccessfully(appStore.notificationStore, "Delete requests rejected successfully", REMOVE_DEVICE_NOTE_TITLE, 1);
                    if (globalFilter) {
                        searchClickHandler();
                    } else {
                        refreshRequest();
                    }
                } else {
                    handleWarning(appStore.notificationStore, "Delete requests rejecting is not completed", REMOVE_DEVICE_NOTE_TITLE, 1);
                }
            });
    };

    const rowExpandCallback = (userDeviceId: string) => {
        if (devicesInfos.has(userDeviceId)) {
            return;
        }
        getDevicesInfoAction(appStore, userDeviceId)
            .then(result => {
                if (result) {
                    const devicesMap = new Map(devicesInfos).set(userDeviceId, result);
                    setDevicesInfos(devicesInfos);

                    const newFilteredRequests = filteredRequests.map(r => {
                        if (r.deviceInfo === undefined) {
                            const deviceInfo = devicesInfos.get(r.userDeviceId) ?? devicesMap.get(r.userDeviceId);
                            if (deviceInfo) {
                                return { ...r, deviceInfo: deviceInfo }
                            }
                        }
                        return r;
                    });

                    setFilteredRequests(newFilteredRequests);
                }
            });
    };

    return (
        <RightsWrapper rights={onlyOnlineAdmin}>
            <AppPage
                isCard
                header="User devices remove requests"
                subheader="You can approve, reject devices remove requests there."
                icon="pi-sort-alt"
                headerComponent={
                    <OpenRequestsCard
                        requestsToShow={filteredRequests}
                        mainActionCallback={() => refreshRequest()}
                    />
                }
            >
                <div className="p-col-12 p-md-6 p-lg-6 p-xl-4">
                    <span className="p-input-icon-left width-100">
                        <i className="pi pi-search"/>
                        <InputText
                            type="text"
                            className="width-100"
                            value={globalFilter}
                            onChange={(event: any) => setGlobalFilter(event.target.value)}
                            placeholder="name email lkid etc"
                            onKeyDown={(e: KeyboardEvent) => {
                                if (e.key === "Enter") {
                                    searchClickHandler()
                                }
                            }}
                        />
                    </span>
                </div>
                <div className="p-col-12 p-md-4 p-lg-4 p-xl-4">
                    <Button icon="pi pi-search" className="p-mt-1" label="Search" onClick={searchClickHandler}/>
                    <Button icon="pi pi-trash" className="p-button-warning p-ml-2" label="Clear" onClick={clearClickHandler}/>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 p-xl-4">
                    <SelectButton
                        style={{ float: "right" }}
                        value={statusSelectValue}
                        onChange={handleStatusSelectChange}
                        options={allDeviceRemovalRequestsStatusesArray}
                        optionLabel="name"
                        multiple
                    />
                </div>
                <div className="p-col-12">
                    <RequestsTable
                        requests={filteredRequests}
                        loading={loading}
                        approveCallback={approveCallback}
                        rejectCallback={rejectCallback}
                        approveAllCallback={approveAllCallback}
                        rejectAllCallback={rejectAllCallback}
                        rowExpandCallback={rowExpandCallback}
                        setSearchStringCallback={s => {
                            setGlobalFilter(s);
                            setStatusSelectValue(allDeviceRemovalRequestsStatusesArray);
                            searchClickHandler();
                        }}
                    />
                </div>
            </AppPage>
        </RightsWrapper>
    );
};

export default observer(UserFingerprintPage);
