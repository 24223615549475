import { action, computed, makeAutoObservable, observable } from "mobx";
import { AccessTokenValue } from "./lib/accessToken";
import { GLOBAL_ADMIN_ROLE, ONLINE_ADMIN_ROLE } from "./const";

export class AuthStore {
    @observable
    isLogged: boolean = false;

    @observable
    token?: string;

    @observable
    userId?: number;

    @observable
    userEmail?: string;

    @observable
    displayName?: string;

    @observable
    photo?: string;

    @observable
    sub?: string;

    @observable
    isLogging: boolean = false;

    @observable
    roles: string[] = [];

    @observable
    lang: string = 'en';

    @observable
    currentVerificationAttemptsCount: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    public setDisplayName(displayName?: string) {
        this.displayName = displayName;
    }

    @action
    public setIsLogged(isLogged: boolean) {
        this.isLogged = isLogged;
    }

    @action
    public setIsLogging(isLogging: boolean) {
        this.isLogging = isLogging;
    }

    @action
    public setUserId(userId?: number) {
        this.userId = userId;
    }

    @action
    public setUserEmail(userEmail?: string) {
        this.userEmail = userEmail;
    }

    @action
    public setRoles(roles: string[]) {
        this.roles = roles;
    }

    @action
    public setLang(lang: string) {
        this.lang = lang.toLowerCase();
    }

    @action
    public setSub(sub?: string) {
        this.sub = sub;
    }

    @action
    setPhoto(photo?: string) {
        this.photo = photo;
    }

    @action
    updateFromAccessToken(accessToken?: AccessTokenValue) {
        if (!accessToken) {
            this.setIsLogged(false);
            this.setIsLogging(false);
            this.setUserId(undefined);
            this.setUserEmail(undefined);
            this.setDisplayName(undefined);
            this.setRoles([]);
            this.setLang('ru');
            this.setSub(undefined);
            this.setPhoto(undefined);
            this.setToken(undefined);
        } else {
            this.setIsLogged(true);
            this.setIsLogging(false);
            this.setUserId(accessToken?.lk_user_id);
            this.setUserEmail(accessToken?.email);
            this.setDisplayName(accessToken?.displayName);
            this.setRoles(accessToken?.authorities ?? []);
            this.setLang(accessToken.lang);
            this.setSub(accessToken.sub);
            this.setPhoto(accessToken.picture);
            this.setToken(accessToken.raw)
        }
    }

    @action
    incCurrentVerificationAttemptsCount() {
        this.currentVerificationAttemptsCount++;
    }

    @action
    setToken(token?: string) {
        this.token = token;
    }

    @computed
    isInRole(role: string) {
        return this.roles.find(r => r === role) !== undefined;
    }

    @computed
    isInRolesAND(roles: string[]) {
        return roles.every(this.isInRole.bind(this));
    }

    @computed
    isInRolesOR(roles: string[]) {
        return roles.some(this.isInRole.bind(this));
    }

    @computed
    public isGlobalAdmin(): boolean {
        return this.roles.find(r => r === GLOBAL_ADMIN_ROLE) !== undefined;
    }

    @computed
    public isOnlineAdmin(): boolean {
        return this.roles.find(r => r === ONLINE_ADMIN_ROLE) !== undefined;
    }
}
