import React, { FC, ReactNode } from 'react';
import classNames from "classnames";

interface AppPageProp {
    header: string;
    subheader: string;
    icon?: string;
    headerComponent?: ReactNode;
    className?: string;
    isCard?: boolean;
}

const AppPage: FC<AppPageProp> = ({
                                      header,
                                      subheader,
                                      children,
                                      headerComponent,
                                      icon,
                                      className,
                                      isCard
                                  }) => {
    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6 p-xl-6">
                <div className="card overview-box p-d-flex p-flex-row p-py-3 p-ai-center">
                    <div className="p-mx-2">
                        <i className={
                            classNames("pi", icon, { "pi-shopping-cart": icon === undefined })
                        }
                           style={{ fontSize: "3rem" }}
                        />
                    </div>
                    <div className="p-d-flex p-flex-column p-ml-4">
                        <h5>{header}</h5>
                        <p>{subheader}</p>
                    </div>
                </div>
            </div>
            {
                headerComponent && headerComponent
            }
            <div className="p-col-12">
                <div className={classNames("p-grid", className, { "card": isCard })}>
                    {
                        children
                    }
                </div>
            </div>
        </div>
    );
};

export default AppPage;
