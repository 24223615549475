import { UserDeviceRemovalRequestModel } from "./models/user-device.models";
import { LineChartModel, LineDataset } from "../../../common/components/charts/charts.model";
import { countByField, groupByField, KeyValuePair } from "../../../common/lib/misc";
import { getWeekNumber } from "../../../common/lib/time";
import { getChartBackgroundColor } from "../../../common/components/charts/charts.options";

export const countByWeek = (requests: UserDeviceRemovalRequestModel[]): KeyValuePair<string, number>[] =>
    countByField<any, Date>("createdAt", requests, d => getWeekNumber(new Date(d)));

export const groupByStatus = (requests: UserDeviceRemovalRequestModel[]) =>
    groupByField("status", requests);

export const getChartDataByRequests = (requests: UserDeviceRemovalRequestModel[]): LineChartModel => {
    const groupedByStatus = groupByStatus(requests);

    const datasets: LineDataset[] = groupedByStatus.map((kvp, index) => {
        const dataset: LineDataset = {
            data: countByWeek(kvp.value).map(kvp => kvp.value),
            borderColor: [
                getChartBackgroundColor(index, 100),
            ],
            // backgroundColor: [
            //     getChartBackgroundColor(index),
            // ],
            borderWidth: 2,
            fill: false
        }
        return dataset;
    });

    return ({
        datasets
    });
};
