import React, { FC, useState } from 'react';
import classNames from "classnames";
import styles from './TmsTicketsList.module.scss';

import { Link } from 'react-router-dom';

import { buildTmsUserProfileRouter } from "../../../routes";

import { TicketModel, TicketModelTariffOptionsItem, TICKETS_LIST_LIMIT } from "../../models/ticket.model";

import { info } from "../../../../../common/lib/logging";
import { dateTemplate } from "../../../../../common/components/tables";

import { confirmPopup } from 'primereact/confirmpopup';

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { TariffOptionsMultiSelect } from "./TariffOptionsMultiSelect";
import { AdditionalParamsEditor } from "./AdditionalParamsEditor";


export interface TmsTicketsTableProps {
    tickets: TicketModel[];
    page: number;
    totalTicketsCount: number;
    setPage: (p: number) => void;
    loading: boolean;

    useOnePage?: boolean;
    exportLoading?: boolean;

    sort?: { field: string, direction: 'desc' | 'asc' | undefined }
    onSort?: (sort: { field: string, direction: 'desc' | 'asc' | undefined }) => void;

    onTicketEnableChanged?: (magicCode: string, newEnabled: boolean) => void;
    onTicketCommentChanged?: (magicCode: string, newComment: string) => void;
    onTicketLandingChanged?: (magicCode: string, newLanding: string) => void;
    onTicketTariffOptionsChanged?: (magicCode: string, tariffOptions: TicketModelTariffOptionsItem[]) => void;
    onTicketAdditionalParamsChanged?: (magicCode: string, newParams: { key: string; value: string; }[]) => void;
    onValidateMagicCode?: (magicCode: string) => void;

    onLinksToCSVExport?: () => void;
}

const LANDING_UPDATE_INPUT_ID = 'luInput';

const tmsDataTemplate = (data: TicketModel) => {
    const { landingPage, magicCode, link, eventId } = data;

    return (
        <div className={classNames(styles.tmsData)}>
            <div className={classNames(styles.tmsDataRow)}>
                <span>eventId:</span> {eventId}
            </div>
            <div className={classNames(styles.tmsDataRow)}>
                <span>landing:</span> <a href={landingPage} target={'_blank'} rel="noreferrer">{landingPage}</a>
            </div>
            <div className={classNames(styles.tmsDataRow)}>
                <span>magicCode:</span> {magicCode}
            </div>
            <div className={classNames(styles.tmsDataRow)}>
                <span>link:</span> <a href={link} target={'_blank'} rel="noreferrer">{link}</a>
            </div>
        </div>
    );
}

const commentTemplate = (data: TicketModel) => {
    return (
        <>
            {data.comment}

            <span className={classNames(styles.clickForEditTip)}>
                Click for edit
            </span>
        </>
    )
}

export const TmsTicketsTable: FC<TmsTicketsTableProps> = (props) => {
    const {
        tickets,
        page,
        totalTicketsCount,
        loading,

        useOnePage,
        exportLoading,

        setPage,

        sort,
        onSort,

        onTicketEnableChanged,
        onTicketCommentChanged,
        onTicketLandingChanged,
        onTicketTariffOptionsChanged,
        onTicketAdditionalParamsChanged,
        onValidateMagicCode,

        onLinksToCSVExport,
    } = props;

    const [ expandedRows, setExpandedRows ] = useState<any[]>();

    const onPage = (e: { first: number, rows: number, page: number, pageCount: number }) => {
        info("onPage", e);
        setPage(e.page);
    };

    const tableHeader = (
        <div className="table-header">
            List of tms-tickets
            <div className="p-d-flex export-buttons">
                {
                    exportLoading &&
                    <ProgressSpinner
                        style={{ maxHeight: '34px' }}
                    />
                }

                <Button
                    disabled={exportLoading}
                    label={'Export links to CSV'}
                    type="button"
                    icon="pi pi-file-o"
                    className="p-mr-2"
                    onClick={onLinksToCSVExport}
                />
            </div>
        </div>
    );

    const textEditor = (props: any) => {
        const { field, rowData } = props;

        return (
            <InputTextarea
                type="text"
                defaultValue={rowData[field]}

                rows={5}
                style={{ width: '100%' }}

                onBlur={e => {
                    if (rowData[field] === (e.target as any).value) {
                        return;
                    }

                    onTicketCommentChanged?.(rowData.magicCode, (e.target as any).value)
                }}
                onKeyPress={e => {
                    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                        if (rowData[field] === (e.target as any).value) {
                            return;
                        }

                        onTicketCommentChanged?.(rowData.magicCode, (e.target as any).value)
                    }
                }}
            />
        );
    }

    const enableKeyTemplate = (data: TicketModel) => {
        const { enabled, magicCode } = data;

        return (
            <InputSwitch checked={enabled} onChange={(e) => onTicketEnableChanged?.(magicCode, e.value)}/>
        );
    }

    const actionTemplate = (data: TicketModel) => {
        const setLanding = (event: any) => {
            const onUpdate = () => {
                const newLanding = (document.getElementById(LANDING_UPDATE_INPUT_ID) as HTMLInputElement)?.value;

                if (newLanding && newLanding !== data.landingPage) {
                    onTicketLandingChanged?.(data.magicCode, newLanding);
                }

                cp.hide?.();
            }

            const cp = confirmPopup({
                target: event.currentTarget,
                message: 'Set landing page',
                icon: 'pi pi-sitemap',
                style: { minWidth: '450px' },
                accept: () => console.log('accept'),
                reject: () => console.log('reject'),
                footer: (
                    <div className={classNames(styles.landingUpdate)}>
                        <InputText
                            id={LANDING_UPDATE_INPUT_ID}
                            type={'text'}
                            defaultValue={data.landingPage}
                            style={{ width: '100%' }}
                            className={'p-mr-2'}
                            onKeyPress={e => {
                                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                    onUpdate();
                                }
                            }}
                        />
                        <Button
                            icon={'pi pi-check'}
                            className={'p-button-success'}
                            onClick={onUpdate}
                        />
                    </div>
                )
            });
        }

        return (
            <div className="p-d-flex p-flex-column">
                <Button
                    label="Set landing"
                    icon="pi pi-sitemap"
                    className="p-button-primary p-mb-2"
                    onClick={setLanding}
                />
                <Button
                    id={`btn${data.magicCode}`}
                    label="Validate"
                    icon="pi pi-shield"
                    className="p-button-primary"
                    onClick={() => onValidateMagicCode?.(data.magicCode)}
                />
            </div>
        );
    };

    const rowExpansionTemplate = (data: TicketModel) => {
        const { additionalParams, lightProfile, profileId, tariffOptions } = data;

        return (
            <div className={classNames(styles.detailsInfo)}>
                <div className={classNames(styles.lightProfile)}>
                    {
                        lightProfile
                            ? (
                                <div className={classNames(styles.profile)}>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>Profile Id:&nbsp;</span>
                                        <span>{profileId ?? '?'}</span>
                                    </div>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>E-mail:&nbsp;</span>
                                        <span>{lightProfile.email ?? '?'}</span>
                                    </div>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>Anonymous:&nbsp;</span>
                                        <span>
                                      {
                                          lightProfile.isAnonymous === undefined
                                              ? '?'
                                              : lightProfile.isAnonymous.toString()
                                      }
                                  </span>
                                    </div>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>Linked LK userId:&nbsp;</span>
                                        <span>{lightProfile.linkedLkUserId ?? '?'}</span>
                                    </div>

                                    <div className={classNames(styles.profileRow)}>
                                        <span>Name:&nbsp;</span>
                                        <span>{lightProfile.name ?? '?'}</span>
                                    </div>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>Surname:&nbsp;</span>
                                        <span>{lightProfile.surname ?? '?'}</span>
                                    </div>
                                    <div className={classNames(styles.profileRow)}>
                                        <span>Phone:&nbsp;</span>
                                        <span>{lightProfile.phone ?? '?'}</span>
                                    </div>
                                </div>
                            )
                            : (
                                <span>&nbsp;?</span>
                            )
                    }

                    <Link to={buildTmsUserProfileRouter(profileId)} target={'_blank'}>
                        <Button
                            label="Edit profile"
                            icon="pi pi-pencil"
                            className="p-button-primary p-mt-2"
                        />
                    </Link>
                </div>

                <hr/>

                <div className={classNames(styles.tariffAndParams)}>
                    <div className={classNames(styles.tariffOptions)}>
                        Tariff options:&nbsp;
                        <TariffOptionsMultiSelect
                            disabled={!data.editable}
                            defaultValue={tariffOptions ?? []}
                            optionLabel={'name'}
                            optionValue={'value'}
                            onChange={newValue => onTicketTariffOptionsChanged?.(data.magicCode, newValue)}
                        />
                    </div>

                    <hr/>

                    <div className={classNames(styles.params)}>
                        Additional params:&nbsp;
                        <AdditionalParamsEditor
                            additionalParams={additionalParams}
                            onChange={newParams => onTicketAdditionalParamsChanged?.(data.magicCode, newParams)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(
            'table-demo',
            styles.tmsTicketsTableContainer,
        )}>
            <DataTable
                className={classNames(
                    'p-datatable-users',
                    'p-datatable-gridlines',
                    'p-datatable-striped',
                )}
                value={tickets}
                paginator
                // paginatorPosition={'both'}
                lazy
                onPage={onPage}
                first={page * (useOnePage ? totalTicketsCount : TICKETS_LIST_LIMIT)}
                rows={useOnePage ? totalTicketsCount : TICKETS_LIST_LIMIT}
                totalRecords={totalTicketsCount}
                rowHover
                emptyMessage="No tickets found"
                loading={loading}
                header={tableHeader}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                editMode={'cell'}
                sortOrder={sort?.direction === undefined ? 0 : sort?.direction === 'asc' ? 1 : -1}
                sortField={sort?.direction === undefined ? undefined : sort?.field}
                onSort={() => onSort?.({
                    field: sort?.field ?? '',
                    direction:
                        sort?.direction === undefined
                            ? 'desc'
                            : sort?.direction === 'desc'
                                ? 'asc'
                                : undefined
                })}
            >
                <Column expander headerStyle={{ width: '3.5rem' }}/>
                <Column sortable={true} field={'created'} header={'Created Date'} body={dateTemplate} headerStyle={{ width: '12rem' }}/>
                <Column field={'enabled'} header={'Enabled'} body={enableKeyTemplate} headerStyle={{ width: '6em' }}/>
                <Column header={'TMS Data'} body={tmsDataTemplate}/>
                <Column
                    field={'comment'}
                    header={'Comment'}
                    body={commentTemplate}
                    editor={(props) => textEditor(props)}
                    headerStyle={{ width: '30%' }}
                    bodyStyle={{ position: 'relative' }}
                />
                <Column
                    headerStyle={{ width: '11rem' }}
                    header={''}
                    bodyStyle={{ textAlign: 'center', overflow: 'visible', justifyContent: 'center' }}
                    body={actionTemplate}
                />
            </DataTable>
        </div>
    )
}
