import { fetchToGet, fetchToPost, fetchToPut, handleNetworkError } from "../../../common/lib/network";
import { DeviceRemovalRequestsStatuses, UserDeviceInfoModel, UserDeviceRemovalRequestModel } from "./models/user-device.models";
import { AuthStore } from "../../auth/auth.store";
import { devicesUrl, getDeviceRemovalRequestsUrl, getDevicesInfoUrl, putDeviceRemovalRequestsUrl, sendDeviceRemovingRequestUrl } from "./devices.api.routes";

export interface DeviceApiModel {
    deviceId: string;
    registeredAt: string;
}

export const getDevices = (authStore: AuthStore) => fetchToGet(devicesUrl, authStore.token)
    .then(response => response.json())
    .then(response => response ? response.devices as DeviceApiModel[] : []);

export const getDevicesInfo = (authStore: AuthStore, ids: any) => fetchToGet(getDevicesInfoUrl(ids), authStore.token)
    .then(response => response.json())
    .then(response => response ? response.devices as UserDeviceInfoModel[] : []);

export const sendRequestToRemove = (authStore: AuthStore, deviceId: any) => fetchToPost(sendDeviceRemovingRequestUrl(deviceId), null, authStore.token)
    .then(response => response.json())
    .then(response => response.requestId as string);

export const getDeviceRemovalRequests = (authStore: AuthStore, pageNum: any, pageSize: number, statuses: DeviceRemovalRequestsStatuses[]): Promise<UserDeviceRemovalRequestModel[]> => {
    return fetchToGet(getDeviceRemovalRequestsUrl(pageNum, pageSize, statuses), authStore.token)
        .then(handleNetworkError)
        .then(response => response.json())
        .then(response => response.requests ?? [])
};

export const putDeviceRemovalRequests = (authStore: AuthStore, requestId: string, action: 'approve' | 'reject', comment?: string) => {
    if (comment !== undefined && comment !== '') {
        comment = encodeURI(comment);
    } else if (comment === '') {
        comment = undefined;
    }

    return fetchToPut(putDeviceRemovalRequestsUrl(requestId, action, comment), undefined, authStore.token)
        .then(response => response.json());
}
