import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './application/AppWrapper';
import reportWebVitals from './reportWebVitals';
import { Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getEnvironment, release } from "./common/lib/env";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

Sentry.init({
    dsn: "https://af35de540e9c43c09819bea8c1f5676c@vulpes-lagopus.jugru.team/7",
    integrations: [ new Integrations.BrowserTracing(
        {
            // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }
    ) ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: getEnvironment(),
    release: release
});

ReactDOM.render(
    <Router history={history}>
        <AppWrapper/>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
