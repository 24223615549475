import React, { FC } from 'react';
import { CSSTransition } from "react-transition-group";
import { config } from "../../config";

interface AppProductsMenuProps {
    onTopbarItemClick: (event: any) => void;
    activeTopbarItem: string | null;
}

const AppProductsMenu: FC<AppProductsMenuProps> = ({ onTopbarItemClick, activeTopbarItem }) => {
    return (
        <li className="layout-topbar-item app">
            <button className="layout-topbar-action rounded-circle p-link" onClick={(event) => onTopbarItemClick({ originalEvent: event, item: 'apps' })}>
                <i className="pi pi-table fs-large"/>
            </button>

            <CSSTransition classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={activeTopbarItem === 'apps'} unmountOnExit>
                <div className="layout-topbar-action-panel p-shadow-6">
                    <div className="p-grid p-nogutter">
                        <div className="layout-topbar-action-item p-col-4">
                            <a href={config.productsUrls.liveUrl}>
                                <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                                    <i className="pi pi-video action indigo-bgcolor white-color"/>
                                    <span>Live Portal</span>
                                </button>
                            </a>
                        </div>
                        <div className="layout-topbar-action-item p-col-4">
                            <a href={config.productsUrls.crmUrl}>
                                <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                                    <i className="pi pi-chart-bar action orange-bgcolor white-color"/>
                                    <span>CRM</span>
                                </button>
                            </a>
                        </div>
                        <div className="layout-topbar-action-item p-col-4">
                            <a href={config.productsUrls.jpaUrl}>
                                <button className="p-d-flex p-ai-center p-flex-column text-color p-link">
                                    <i className="pi pi-users action teal-bgcolor white-color"/>
                                    <span>JPA</span>
                                </button>
                            </a>
                        </div>
                        <div className="layout-topbar-action-item p-col-4">
                            <a href={config.productsUrls.jugUrl}>
                                <button className="p-d-flex p-ai-center p-jc-center p-flex-column text-color p-link">
                                    <i className="pi pi-sitemap action cyan-bgcolor white-color"/>
                                    <span>Site</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </li>
    );
};

export default AppProductsMenu;
