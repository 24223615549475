import { AuthStore } from "../auth.store";
import { RightsModel } from "../rights.model";

const denyWrapperFunction = (object: any): [] | null => {
    if (object instanceof Array) {
        return [];
    }
    return null;
}

export const rightsWrapperFunction = (authStore: AuthStore, rights: RightsModel, object: any): any => {
    if (!authStore.isLogged && !authStore.isLogging) {
        return denyWrapperFunction(object);
    }

    if (rights.globalAdminRequired && !authStore.isGlobalAdmin()) {
        return denyWrapperFunction(object);
    }

    if (rights.onlineAdminRequired && !authStore.isOnlineAdmin()) {
        return denyWrapperFunction(object);
    }

    if (rights.rolesRequired && rights.rolesRequired.length > 0 && !authStore.isInRolesAND(rights.rolesRequired)) {
        return denyWrapperFunction(object);
    }

    if (rights.rolesAvailable && rights.rolesAvailable.length > 0 && !authStore.isInRolesOR(rights.rolesAvailable)) {
        return denyWrapperFunction(object);
    }

    return object;
}
