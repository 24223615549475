import { History } from "history";
import { MenuLinkItem } from "../../menu";
import { AuthStore } from "../auth/auth.store";
import { rightsWrapperFunction } from "../auth/components/rightsWrapperFunction";
import { eventsRights } from "./rights";

export const EVENTS_MENU_NAME = "Events";
export const EVENT_PAGE_ROUTE = "/events";

export const topMenuRoutes = (authStore: AuthStore, history: History) => {
    const data = (
        [ {} ]
    );

    return rightsWrapperFunction(authStore, eventsRights, data);
}

export const menuRoutes = (authStore: AuthStore): MenuLinkItem[] => {
    const data = (
        [ {} ]
    );

    return rightsWrapperFunction(authStore, eventsRights, data);
}
