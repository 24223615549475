import React, { FC } from 'react';
import { release } from "../../common/lib/env";

interface AppFooterProps {
    colorMode?: string;
}

const AppFooter: FC<AppFooterProps> = () => {
    return (
        <div className="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2 appFooter_container">
            <div className="appFooter_release">{release}</div>
            <img id="footer-logo" src={"assets/images/logo.svg"} alt="jugru-footer-logo" style={{ height: "5.25rem" }}/>
            {/*<Button type="button" icon="pi pi-github fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-ml-auto p-mr-2': !isRTL, 'p-ml-2 p-mr-auto': isRTL })}/>*/}
            {/*<Button type="button" icon="pi pi-facebook fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>*/}
            {/*<Button type="button" icon="pi pi-twitter fs-large" className={classNames('p-button-rounded p-button-text p-button-plain', { 'p-mr-2': !isRTL, 'p-ml-2': isRTL })}/>*/}
        </div>
    );
};

export default AppFooter;
